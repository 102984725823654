import * as Yup from "yup";
import { ASSET_NETWORK, ASSET_TYPE } from "../typings/assets";

export const addPairSchema = Yup.object().shape({
  base: Yup.string().required(),
  quote: Yup.string().required(),
  maxTradableAmount: Yup.number().required(),
  pricePrecision: Yup.number().required(),
  amountPrecision: Yup.number().required(),
  minTotalAmount: Yup.number().required(),
  fee: Yup.number().required(),
});

export const editPairSchema = Yup.object().shape({
  maxTradableAmount: Yup.number().required(),
  pricePrecision: Yup.number().required(),
  amountPrecision: Yup.number().required(),
  minTotalAmount: Yup.number().required(),
  fee: Yup.number().required(),
});

export const editCurrencySchema = Yup.object().shape({
  _id: Yup.string().required(),
  name: Yup.string().required(),
});

export const editCurrencyNetworkSchema = Yup.object().shape({
  name: Yup.string().oneOf(Object.values(ASSET_NETWORK)).required(),
  withdrawalFee: Yup.number().required(),
  depositFee: Yup.number().required(),
  withdrawalFeePercent: Yup.number().required(),
  depositFeePercent: Yup.number().required(),
  minDeposit: Yup.number().required(),
  minWithdrawal: Yup.number().required(),
  depositEnabled: Yup.boolean().default(false),
  withdrawalEnabled: Yup.boolean().default(false),
});

export const addCurrencyNetworkSchema = editCurrencyNetworkSchema.shape({
  nativeDecimalPlaces: Yup.number().required(),
});

export const addCurrencySchema = Yup.object().shape({
  type: Yup.string().oneOf(Object.values(ASSET_TYPE)).required(),
  symbol: Yup.string().min(3).uppercase().required(),
  name: Yup.string().required(),
  decimalPlaces: Yup.number().required(),
});
