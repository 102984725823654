import React, { useCallback, useMemo, useRef, useState } from "react";
import { isAfter, format, sub, addMinutes } from "date-fns";
import { useMutation } from "@apollo/client";
import { REQUEST_TELEGRAM_CODE } from "../../graphql/account";

export const useTelegramCodeInput = () => {
  const [code, setCode] = useState("");

  const isValid = useMemo(() => /[0-9]{6}/.test(code), [code]);

  return { code, setCode, isValid };
};

const TelegramCodeInput: React.FC<{
  resource: string;
  code: string;
  setCode: (val: string) => void;
}> = ({ resource, code, setCode }) => {
  const [requestTelegramCode] = useMutation(REQUEST_TELEGRAM_CODE);

  const [countdown, setCountdown] = useState("");

  const endTime = useRef<Date>();
  const timer = useRef<NodeJS.Timeout>();

  const updateCountdown = () => {
    const date = new Date();

    setCountdown(
      format(
        sub(endTime.current, {
          minutes: date.getMinutes(),
          seconds: date.getSeconds(),
        }),
        "ss"
      )
    );
  };

  const startCountdown = useCallback(() => {
    if (!timer.current) {
      timer.current = setTimeout(() => {
        console.log("iniciando timer");
        timer.current = null;

        if (isAfter(endTime.current, new Date())) {
          updateCountdown();
          startCountdown();
        } else {
          console.log("setar null");
          setCountdown(null);
          endTime.current = null;
        }
      }, 500);
    }
  }, [endTime]);

  const requestCode = () => {
    setCode("");
    requestTelegramCode({
      variables: {
        resource,
      },
    }).then(() => {
      endTime.current = addMinutes(new Date(), 1);
      startCountdown();
    });
  };

  return (
    <div className="input-group mb-3">
      <input
        maxLength={6}
        pattern="[0-9]{6}"
        type="text"
        className="form-control"
        placeholder="Telegram code"
        aria-label="Telegram code"
        aria-describedby="button-addon2"
        value={code}
        onChange={({ target }) => setCode(target?.value)}
      />
      <button
        disabled={!!countdown}
        className="btn btn-outline-secondary"
        type="button"
        id="button-addon2"
        onClick={() => requestCode()}
      >
        Request {countdown && `(${countdown})`}
      </button>
    </div>
  );
};

export default TelegramCodeInput;
