import React from "react";
import styled from "styled-components";
import { useTrade } from "../../../hooks/useTrade";
import { RadioGroup } from "./MiddleBottom";
import OrderForm from "./OrderForm";

const RightPanelBottomContainer = styled.div`
  flex: 0 0 auto;
  margin: 5px 0 0;
`;

const TradeActionWrapper = styled.div`
  background-color: ${(props) => props.theme.backgroundColor2};
`;

const TradeHeader = styled.div`
  height: 35px;
  background-color: ${(props) => props.theme.backgroundColor2};
  border-bottom: 1px solid ${(props) => props.theme.divider};
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TradeActionBody = styled.div`
  height: 245px;
  display: flex;
`;

const RightPanelBottom: React.FC = () => {
  const { base, quote } = useTrade();

  return (
    <RightPanelBottomContainer>
      <TradeActionWrapper>
        <TradeHeader>
          <RadioGroup>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="selectTradeType"
                id="activeTradeType"
                checked
              />
              <label className="form-check-label" htmlFor="activeTradeType">
                Limit Order
              </label>
            </div>
          </RadioGroup>
        </TradeHeader>
        <TradeActionBody>
          <OrderForm side="bid" base={base} quote={quote} />
          <OrderForm side="ask" base={base} quote={quote} />
        </TradeActionBody>
      </TradeActionWrapper>
    </RightPanelBottomContainer>
  );
};

export default RightPanelBottom;
