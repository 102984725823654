import { useMutation } from "@apollo/client";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import cogoToast from "cogo-toast";
import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Alert,
  Card,
  FormText,
  Label,
  Input as BInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Template from "../../components/Common/Template";
import Input from "../../components/Form/Input";
import { REGISTER_MUTATION } from "../../graphql/auth";
import { registerSchema } from "../../schemas/auth";
import FormCheck from "../../components/Form/FormCheck";
import { useApp } from "../../hooks/appHooks";
import { useInfoModal } from "../../components/Common/InfoModal";

interface IFormData {
  name: string;
  email: string;
  password: string;
}

const Register: React.FC = () => {
  const ctx = useApp();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [authError, setAuthError] = useState("");
  const [formState, setFormState] = useState<IFormData>();

  const [register] = useMutation(REGISTER_MUTATION, {
    onCompleted: () => {
      cogoToast.success("registered with success");
      history.push("/auth/login");
    },
    onError: (err) => setAuthError(err.message),
  });

  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    setAuthError("");
    try {
      const formData = await registerSchema.validate(data, {
        abortEarly: false,
      });

      setFormState(formData);
      toggle();
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        console.log(err);
        cogoToast.error(err.message);
        /* setAuthError(err.message); */
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      } else {
        cogoToast.error("Unknown error");
      }
    }
  };

  const { isOpen, toggle } = useInfoModal();

  const handleResidentButtonClick = (isResident: boolean) => {
    if (isResident) {
      cogoToast.error(
        "Sorry! You are in a restricted area where we cannot offer our products or services."
      );
    } else {
      register({
        variables: formState,
      });
    }
    toggle();
  };

  return (
    <>
      <Template>
        <main>
          <div className="d-flex justify-content-center">
            <div className="form-access my-auto">
              <Card
                body
                className="border-0 shadow mt-3"
                style={{
                  maxWidth: "321px",
                }}
              >
                <h4 className="text-center">Create a free account</h4>
                <p
                  className="text-center"
                  style={{
                    fontSize: "13px",
                    color: "rgb(112, 122, 138)",
                  }}
                >
                  Welcome to {ctx.branding.name}
                </p>
                <Form ref={formRef} onSubmit={onSubmit}>
                  <Modal
                    isOpen={isOpen}
                    size={"md"}
                    scrollable={false}
                    backdrop={true}
                  >
                    <ModalHeader toggle={toggle}>
                      Are you a citizen of or currently residing in one of the
                      following restricted countries or regions?
                    </ModalHeader>
                    <ModalBody>
                      List of restricted countries
                      <ul>
                        <li>China</li>
                        <li>Cuba</li>
                        <li>Iran</li>
                        <li>North Korea</li>
                        <li>Sudan</li>
                        <li>Singapore</li>
                        <li>Syria</li>
                        <li>United States of America</li>
                      </ul>
                      For more information, read item c.1.1. of the{" "}
                      <a href="/information/terms" target="_blank">
                        Terms of Use
                      </a>
                    </ModalBody>
                    <ModalFooter>
                      <div className="d-grid gap-2 mt-3">
                        <Button
                          onClick={() => handleResidentButtonClick(true)}
                          color="secondary"
                          data-cy="createAccount"
                        >
                          Yes
                        </Button>
                      </div>
                      <div className="d-grid gap-2 mt-3">
                        <Button
                          onClick={() => handleResidentButtonClick(false)}
                          color="primary"
                          data-cy="createAccount"
                        >
                          No
                        </Button>
                      </div>
                    </ModalFooter>
                  </Modal>
                  <div className="form-group">
                    <Label for="name">Name</Label>
                    <Input
                      type="text"
                      name="name"
                      className="form-control fb-input-text"
                      placeholder="Full Name"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <Label for="email">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      className="form-control fb-input-text"
                      placeholder="Email Address"
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <Label for="password">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      className="form-control fb-input-text"
                      placeholder="Password"
                      required
                    />
                  </div>
                  <FormCheck>
                    <BInput
                      type="checkbox"
                      className="form-check-input"
                      id="form-checkbox"
                      required
                    />
                    <label className="form-check-label" htmlFor="form-checkbox">
                      I confirm I am over age 18 and agree to the{" "}
                      <a href="/information/terms" target="_blank">
                        Terms of Use
                      </a>{" "}
                      and{" "}
                      <a href="/information/privacy" target="_blank">
                        Privacy Policy
                      </a>
                    </label>
                  </FormCheck>

                  <div className="d-grid gap-2 mt-3">
                    <Button
                      type="submit"
                      color="primary"
                      data-cy="createAccount"
                    >
                      {"Create Account"}
                    </Button>
                  </div>

                  {authError && (
                    <Alert className="mt-3" color="danger">
                      {authError}
                    </Alert>
                  )}
                </Form>
                <FormText className="mt-3">
                  {"Already have an account?"}{" "}
                  <Link to="/auth/login">{"Sign in here"}</Link>
                </FormText>
              </Card>
            </div>
          </div>
        </main>
      </Template>
    </>
  );
};

export default Register;
