import { useQuery } from "@apollo/client";
import React from "react";
import { Table } from "reactstrap";
import Template from "../../components/Common/Template";
import { GET_FEES } from "../../graphql/trade";
import { IPair } from "../admin/Pairs";

const Fees: React.FC = () => {
  const { data } = useQuery<{
    getPairsFees: IPair[];
  }>(GET_FEES);

  return (
    <Template nonfluid>
      <h1 className="h4 mt-3">Trading Fees</h1>
      <hr />

      <Table size="sm" stripped="true" hover responsive>
        <thead>
          <tr>
            <th className="text-left">Pair</th>
            <th>Maker</th>
            <th className="text-right">Taker</th>
          </tr>
        </thead>
        <tbody>
          {data?.getPairsFees.map((e) => (
            <tr>
              <td className="text-left">
                {e.base}/{e.quote}
              </td>
              <td>{e.fee.toFixed(2)}%</td>
              <td className="text-right">{e.fee.toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* <h2>Saque</h2>
      <Table>
        <thead>
          <tr>
            <td>Moeda</td>
            <td>Taxa</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>BTC</td>
            <td>0.00001</td>
          </tr>
        </tbody>
      </Table> */}
    </Template>
  );
};

export default Fees;
