import React, { useRef } from "react";
import { FormGroup, Label, Table } from "reactstrap";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Input from "../../Form/Input";
import { BiNetworkChart } from "react-icons/bi";
import { ICurrency } from "../../../typings/App";
import { useInfoModal } from "../../Common/InfoModal";
import ModalButton from "../../Common/ModalButton";
import AddOrEditCurrencyNetworkModal from "./AddOrEditCurrencyNetworkModal";
import { useMutation } from "@apollo/client";
import { TOGGLE_CURRENCY_DEPOSIT_WITHDRAWAL_MUTATION } from "../../../graphql/admin";
import { ASSET_NETWORK } from "../../../typings/assets";
import CustomSwitch from "../../Form/CustomSwitch";

const ViewCurrencyNetworksModal: React.FC<{
  refetch: CallableFunction;
  currency: ICurrency;
}> = ({ refetch, currency }) => {
  const formRef = useRef<FormHandles>(null);
  const { isOpen, toggle } = useInfoModal();
  const [toggleCurrencyDepositWithdrawalMutation] = useMutation(
    TOGGLE_CURRENCY_DEPOSIT_WITHDRAWAL_MUTATION
  );

  const toggleCurrencyDepositWithdrawal = (
    _id: string,
    network: ASSET_NETWORK,
    isDeposit: boolean,
    value: boolean
  ) => {
    toggleCurrencyDepositWithdrawalMutation({
      variables: {
        _id,
        network,
        isDeposit,
        value,
      },
    })
      .then(() => refetch())
      .catch((e) => console.error(e));
  };

  return (
    <>
      <ModalButton
        title="Edit Currency Network"
        isOpen={isOpen}
        toggle={toggle}
        button={<BiNetworkChart onClick={() => toggle()} />}
        size="xl"
      >
        <Form ref={formRef} onSubmit={() => null} initialData={currency}>
          <FormGroup>
            <Label for="_id">Id</Label>
            <Input type="text" id="_id" name="_id" disabled />
          </FormGroup>
          <FormGroup>
            <Label for="symbol">Symbol</Label>
            <Input
              type="text"
              id="symbol"
              name="symbol"
              placeholder="Ex.: ADA"
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="Ex.: Cardano"
              disabled
            />
          </FormGroup>
          <AddOrEditCurrencyNetworkModal
            currency={currency}
            refetch={refetch}
          />
          <Table responsive className="mb-3">
            <thead>
              <tr>
                <th>Name</th>
                <th>depositEnabled</th>
                <th>withdrawalEnabled</th>
                <th>depositFee</th>
                <th>depositFeePercent</th>
                <th>withdrawalFee</th>
                <th>withdrawalFeePercent</th>
                <th>minDeposit</th>
                <th>minWithdrawal</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currency.networks.length > 0 ? (
                currency.networks.map((n) => (
                  <tr key={n.name}>
                    <td>{n.name}</td>
                    <td>
                      <CustomSwitch
                        id={`switch${n.name}deposit`}
                        onChange={() =>
                          toggleCurrencyDepositWithdrawal(
                            currency._id,
                            n.name,
                            true,
                            !n.depositEnabled
                          )
                        }
                        checked={n.depositEnabled}
                      />
                    </td>
                    <td>
                      <CustomSwitch
                        id={`switch${n.name}withdrawal`}
                        onChange={() =>
                          toggleCurrencyDepositWithdrawal(
                            currency._id,
                            n.name,
                            false,
                            !n.withdrawalEnabled
                          )
                        }
                        checked={n.withdrawalEnabled}
                      />
                    </td>
                    <td>
                      {n.depositFee} {currency.symbol}
                    </td>
                    <td>{n.depositFeePercent}%</td>
                    <td>
                      {n.withdrawalFee} {currency.symbol}
                    </td>
                    <td>{n.withdrawalFeePercent}%</td>
                    <td>
                      {n.minDeposit} {currency.symbol}
                    </td>
                    <td>
                      {n.minWithdrawal} {currency.symbol}
                    </td>
                    <td>
                      <AddOrEditCurrencyNetworkModal
                        currency={currency}
                        refetch={refetch}
                        network={n}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10000}>No networks</td>
                </tr>
              )}
            </tbody>
          </Table>
          {/* <FormGroup>
              <Label for="withdrawalFee">Withdrawal Fee</Label>
              <Input
                type="number"
                id="withdrawalFee"
                name="withdrawalFee"
                placeholder="1"
                step={0.00000001}
                min={0}
                max={10000}
              />
            </FormGroup>
            <FormGroup>
              <Label for="depositFee">Deposit Fee</Label>
              <Input
                type="number"
                id="depositFee"
                name="depositFee"
                placeholder="Ex.: 1"
                step={0.00000001}
                min={0}
                max={10000}
              />
            </FormGroup>
            <FormGroup>
              <Label for="withdrawalFeePercent">Withdrawal Fee Percent</Label>
              <Input
                type="number"
                id="withdrawalFeePercent"
                name="withdrawalFeePercent"
                placeholder="Ex.: 10"
                step={0.00000001}
                min={0}
                max={10000}
              />
            </FormGroup>
            <FormGroup>
              <Label for="depositFeePercent">Deposit Fee Percent</Label>
              <Input
                type="number"
                id="depositFeePercent"
                name="depositFeePercent"
                placeholder="Ex.: 0.1"
                step={0.00000001}
                min={0}
                max={10000}
              />
            </FormGroup>
            <FormGroup>
              <Label for="minDeposit">Minimum Deposit Amount</Label>
              <Input
                type="number"
                id="minDeposit"
                name="minDeposit"
                placeholder="Ex.: 0.1"
                step={0.00000001}
                min={0}
                max={10000}
              />
            </FormGroup>
            <FormGroup>
              <Label for="minWithdrawal">Minimum Withdrawal Amount</Label>
              <Input
                type="number"
                id="minWithdrawal"
                name="minWithdrawal"
                placeholder="Ex.: 0.1"
                step={0.00000001}
                min={0}
                max={10000}
              />
            </FormGroup> */}
        </Form>
      </ModalButton>
    </>
  );
};

export default ViewCurrencyNetworksModal;
