import { useQuery } from "@apollo/client";
import cogoToast from "cogo-toast";
import React, { useCallback } from "react";
import { useState } from "react";
import styled from "styled-components";
import { GET_OPEN_ORDERS } from "../../../graphql/trade";
import useOrder from "../../../hooks/useOrder";
import { useTrade } from "../../../hooks/useTrade";
import { useAuth } from "../../../hooks/useAuth";
import { IOrder } from "../../../typings/Trade";
import ActiveOrders from "./ActiveOrders";
import Balances from "./Balances";

const MiddleBottomContainer = styled.div`
  flex: 0 0 auto;
  margin: 5px 0 0;
`;

const OrdersHeader = styled.div`
  height: 35px;
  background-color: ${(props) => props.theme.backgroundColor2};
  border-bottom: 1px solid ${(props) => props.theme.divider};
  padding: 0 0 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RadioGroup = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* color: rgba(0, 0, 0, 0.65); */
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
`;

const OrdersRight = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CheckBoxWrapper = styled.div`
  color: #989898;
  font-size: 12px;
`;

const CandleAllButtonWrapper = styled.span`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 100%;
  // color: ${(props) => props.theme.secondary};
  padding: 0 20px;

  :hover {
    color: #df384e;
  }
`;

const MiddleBottom: React.FC = () => {
  const { base, quote } = useTrade();
  const { batchCancel } = useOrder();
  const user = useAuth();
  const [selectedTab, setSelectedTab] = useState("active");

  const [hideOtherPairs, setHideOtherPairs] = useState(true);

  const { data, refetch } = useQuery<{ getOpenOrders: IOrder[] }>(
    GET_OPEN_ORDERS,
    {
      skip: !user.user,
      pollInterval: 3000,
      fetchPolicy: "network-only",
      onError: (err) => cogoToast.error(err.message),
    }
  );

  const orders = (data?.getOpenOrders || []).filter((o) =>
    hideOtherPairs ? o.base === base && o.quote == quote : true
  );

  const closeAll = useCallback(async () => {
    const toCancel = [...orders];
    let ids = [];

    while (toCancel.length > 0) {
      ids.push(toCancel.pop()._id);

      if (ids.length === 10 || toCancel.length === 0) {
        await batchCancel({
          variables: {
            ordersIds: ids,
          },
        });
        ids = [];
      }
    }
  }, [orders]);

  return (
    <MiddleBottomContainer>
      <OrdersHeader>
        <RadioGroup>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="selectedTab"
              id="activeOrdersRadio"
              checked={selectedTab === "active"}
              onChange={() => setSelectedTab("active")}
            />
            <label className="form-check-label" htmlFor="activeOrdersRadio">
              Active Orders
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="selectedTab"
              id="orderHistoryRadio"
              disabled
              checked={selectedTab === "history"}
            />
            <label className="form-check-label" htmlFor="orderHistoryRadio">
              Order History
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="selectedTab"
              id="balancesRadio"
              checked={selectedTab === "balances"}
              onChange={() => setSelectedTab("balances")}
            />
            <label className="form-check-label" htmlFor="balancesRadio">
              Balances
            </label>
          </div>
        </RadioGroup>
        {selectedTab === "active" && (
          <>
            <OrdersRight>
              <CheckBoxWrapper>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    checked={hideOtherPairs}
                    onChange={({ target }) => setHideOtherPairs(target.checked)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Hide other pairs
                  </label>
                </div>
              </CheckBoxWrapper>
              <CandleAllButtonWrapper>
                <div
                  className="form-check"
                  style={{
                    padding: "0 8px",
                  }}
                  onClick={closeAll}
                >
                  Cancel All
                </div>
              </CandleAllButtonWrapper>
            </OrdersRight>
          </>
        )}
      </OrdersHeader>
      {selectedTab === "active" && (
        <ActiveOrders orders={orders} refetch={refetch} />
      )}
      {selectedTab === "balances" && <Balances />}
    </MiddleBottomContainer>
  );
};

export default MiddleBottom;
