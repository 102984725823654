import { useMutation } from "@apollo/client";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import cogoToast from "cogo-toast";
import React, { useRef } from "react";
import * as Yup from "yup";
import { Button, FormGroup, Label } from "reactstrap";
import { ADD_CURRENCY_MUTATION } from "../../../graphql/admin";
import { addCurrencySchema } from "../../../schemas/admin";
import CustomSelect from "../../Form/CustomSelect";
import Input from "../../Form/Input";
import ModalButton from "../../Common/ModalButton";
import { useInfoModal } from "../../Common/InfoModal";
import { ASSET_TYPE } from "../../../typings/assets";

interface IFormData {
  type: ASSET_TYPE;
  symbol: string;
  name: string;
  decimalPlaces: number;
}

const AddCurrencyModal: React.FC = () => {
  const { isOpen, toggle } = useInfoModal();
  const formRef = useRef<FormHandles>(null);

  const [register] = useMutation(ADD_CURRENCY_MUTATION, {
    onCompleted: () => {
      cogoToast.success("currency created");
      toggle();
    },
    onError: (err) => cogoToast.error(err.message),
  });

  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    try {
      const formData = await addCurrencySchema.validate(data, {
        abortEarly: false,
      });

      register({
        variables: formData,
      });
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        console.log(err);
        cogoToast.error(err.message);
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      }
    }
  };

  return (
    <ModalButton title="Add Currency" isOpen={isOpen} toggle={toggle}>
      <Form ref={formRef} onSubmit={onSubmit}>
        <FormGroup>
          <Label for="type">Type</Label>
          <CustomSelect
            options={Object.values(ASSET_TYPE).map((c) => ({
              label: c,
              value: c,
            }))}
            id="type"
            name="type"
            placeholder="Ex.: BNB"
            disabled={false}
            isMulti={false}
          />
        </FormGroup>
        <FormGroup>
          <Label for="symbol">Symbol</Label>
          <Input
            type="text"
            name="symbol"
            className="form-control fb-input-text"
            placeholder="Currency Symbol"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="symbol">Name</Label>
          <Input
            type="text"
            name="name"
            className="form-control fb-input-text"
            placeholder="Currency Name"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="decimalPlaces">Decimal Places</Label>
          <Input
            type="number"
            name="decimalPlaces"
            className="form-control fb-input-text"
            placeholder="Decimal Places"
            required
          />
        </FormGroup>
        <div className="d-grid gap-2 mt-3">
          <Button type="submit" color="primary" data-cy="addCurrency">
            Create Currency
          </Button>
        </div>
      </Form>
    </ModalButton>
  );
};

export default AddCurrencyModal;
