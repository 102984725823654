import { useMutation } from "@apollo/client";
import cogoToast from "cogo-toast";
import React from "react";
import { AiOutlineInbox } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { Card, Table } from "reactstrap";
import { DELETE_API_KEY } from "../../graphql/account";
import { IAPIKey } from "../../pages/account/API";

const ListAPIKeys: React.FC<{ keys: IAPIKey[]; refetch: CallableFunction }> = ({
  keys,
  refetch,
}) => {
  const [remove] = useMutation(DELETE_API_KEY, {
    onCompleted: () => {
      cogoToast.success("api key removed");
      refetch();
    },
    onError: (err) => cogoToast.error(err.message),
  });

  return (
    <Card body className="border-0">
      <Table className="text-center" size="sm" striped responsive>
        <thead>
          <tr>
            <th>Label</th>
            <th>Permissions</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {keys.length === 0 ? (
            <tr>
              <td className="text-center" colSpan={6}>
                <AiOutlineInbox size={25} />
                <br />
                Nothing yet, try create one
              </td>
            </tr>
          ) : (
            keys.map((e) => (
              <tr>
                <td>{e.label}</td>
                <td>{e.permissions.join(", ")}</td>
                <td>
                  <MdCancel
                    cursor="pointer"
                    color="red"
                    onClick={() =>
                      remove({
                        variables: {
                          id: e._id,
                        },
                      })
                    }
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Card>
  );
};

export default ListAPIKeys;
