import gql from "graphql-tag";

export const GET_MOVERS = gql`
  query {
    getMovers {
      base
      quote
      last
      change
      vol
    }
  }
`;

export const CANCEL_ORDER_MUTATION = gql`
  mutation CancelOrderMutation($orderId: String!) {
    cancelOrder(orderId: $orderId)
  }
`;

export const CANCEL_BATCH_ORDER_MUTATION = gql`
  mutation BatchCancelOrderMutation($ordersIds: [String!]!) {
    batchCancelOrder(ordersIds: $ordersIds)
  }
`;

export const GET_ORDER_HISTORY = gql`
  query {
    getOrderHistory {
      _id
      status
      base
      quote
      price
      side
      averagePrice
      originalAmount
      filledAmount
      createdAt
    }
  }
`;

export const GET_OPEN_ORDERS = gql`
  query {
    getOpenOrders {
      _id
      base
      quote
      price
      averagePrice
      originalAmount
      filledAmount
      remainingAmount
      side
      createdAt
    }
  }
`;

export const GET_KLINES = gql`
  query (
    $base: String!
    $quote: String!
    $frame: String!
    $from: DateTime
    $to: DateTime
  ) {
    getKlines(base: $base, quote: $quote, frame: $frame, from: $from, to: $to) {
      openTime
      open
      high
      low
      close
      vol
    }
  }
`;

export const GET_LAST_KLINE = gql`
  query getLastKline($base: String!, $quote: String!, $frame: String!) {
    getLastKline(base: $base, quote: $quote, frame: $frame) {
      openTime
      open
      high
      low
      close
      vol
    }
  }
`;

export const GET_SUMMARY = gql`
  query {
    getSummary {
      base
      quote
      last
      change
      vol
      high
      low
    }
  }
`;

// todo se tiver erro atribuir um nome para essa query
export const GET_LAST_TRADES = gql`
  query ($base: String!, $quote: String!) {
    getLastTrades(base: $base, quote: $quote) {
      _id
      price
      amount
      taker
      createdAt
    }
  }
`;

export const SUBSCRIBE_TRADES = gql`
  subscription ($base: String!, $quote: String!) {
    trades(base: $base, quote: $quote) {
      trades {
        _id
        price
        amount
        taker
        createdAt
      }
    }
  }
`;

export const GET_PAIRS = gql`
  query {
    getPairs {
      base
      quote
      vol
      last
      change
    }
  }
`;

export const GET_ORDER_BOOK = gql`
  query ($base: String!, $quote: String!) {
    getOrderbook(base: $base, quote: $quote) {
      asks {
        _id
        price
        remainingAmount
      }
      bids {
        _id
        price
        remainingAmount
      }
    }
  }
`;

export const SUBSCRIBE_ORDERBOOK = gql`
  subscription ($base: String!, $quote: String!) {
    orderbook(base: $base, quote: $quote) {
      asks {
        _id
        price
        remainingAmount
      }
      bids {
        _id
        price
        remainingAmount
      }
    }
  }
`;

export const PLACE_ORDER_MUTATION = gql`
  mutation PlaceOrderMutation(
    $base: String!
    $quote: String!
    $side: String!
    $price: Float!
    $amount: Float!
  ) {
    placeOrder(
      base: $base
      quote: $quote
      side: $side
      price: $price
      amount: $amount
    )
  }
`;

export const GET_FEES = gql`
  query {
    getPairsFees {
      base
      quote
      fee
    }
  }
`;

export const GET_TRADING_RULES = gql`
  query ($base: String!, $quote: String!) {
    getPairTradingRules(base: $base, quote: $quote) {
      enabled
      maxTradableAmount
      pricePrecision
      amountPrecision
      minTotalAmount
      basePrecision
      quotePrecision
    }
  }
`;
