import { useMutation } from "@apollo/client";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import cogoToast from "cogo-toast";
import React, { useRef } from "react";
import * as Yup from "yup";
import { Button, FormGroup, Label } from "reactstrap";
import CustomSelect from "../../Form/CustomSelect";
import Input from "../../Form/Input";
import ModalButton from "../../Common/ModalButton";
import { useInfoModal } from "../../Common/InfoModal";
import { ASSET_NETWORK } from "../../../typings/assets";
import { CREATE_TOKEN_EVENT_MUTATION } from "../../../graphql/tokenEvent";
import { createTokenEventSchema } from "../../../schemas/tokenEvent";

const AddTokenEventModal: React.FC = () => {
  const { isOpen, toggle } = useInfoModal();
  const formRef = useRef<FormHandles>(null);

  const [createTokenEvent] = useMutation(CREATE_TOKEN_EVENT_MUTATION, {
    onCompleted: () => {
      cogoToast.success("event created");
      toggle();
    },
    onError: (err) => cogoToast.error(err.message),
  });

  const onSubmit: SubmitHandler = async (data) => {
    try {
      const formData = await createTokenEventSchema.validate(data, {
        abortEarly: false,
      });

      createTokenEvent({
        variables: formData,
      });
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        console.log(err);

        err.inner.forEach((error) => {
          cogoToast.error(error.message);
          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      }
    }
  };

  return (
    <ModalButton title="Add Token Event" isOpen={isOpen} toggle={toggle}>
      <Form ref={formRef} onSubmit={onSubmit}>
        <FormGroup>
          <Label for="network">Network Name</Label>
          <CustomSelect
            options={Object.values(ASSET_NETWORK).map((network) => ({
              label: network,
              value: network,
            }))}
            id="network"
            name="network"
            placeholder="Ex.: bsc"
            disabled={false}
            isMulti={false}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="name">Event Name</Label>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder="Ex.: The Partners Podcast #1"
            required
          />
          <small>This name will be displayed in verification page</small>
        </FormGroup>
        <FormGroup>
          <Label for="when">Event date</Label>
          <Input type="date" id="when" name="when" required />
        </FormGroup>
        <FormGroup>
          <Label for="token">Token Address</Label>
          <Input
            type="text"
            id="token"
            name="token"
            placeholder="Ex.: 0xc4b3272222E7635488cD5524a8fdA01BA7970568"
            required
          />
        </FormGroup>
        <div className="d-grid gap-2 mt-3">
          <Button type="submit" color="primary" data-cy="addCurrency">
            Create Event
          </Button>
        </div>
      </Form>
    </ModalButton>
  );
};

export default AddTokenEventModal;
