import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Card, FormGroup, FormText, Label } from "reactstrap";
import { useAuth, IUser } from "../../hooks/useAuth";
import { useMutation } from "@apollo/client";
import ProvideTwoFactor, {
  useProvideTwoFactor,
} from "../../components/Common/ProvideTwoFactor";
import Template from "../../components/Common/Template";
import CorrectURL from "../../components/Auth/CorrectURL";
import { Form } from "@unform/web";
import Input from "../../components/Form/Input";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";
import cogoToast from "cogo-toast";
import { LOGIN_MUTATION } from "../../graphql/auth";
import { loginSchema } from "../../schemas/auth";

interface IFormData {
  email: string;
  password: boolean;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const user = useAuth();
  const [authError, setAuthError] = useState("");

  const tf = useProvideTwoFactor();

  const [login] = useMutation<{
    login: {
      user: IUser;
      token: string;
      refreshToken: string;
      sessionId: string;
    };
  }>(LOGIN_MUTATION, {
    onCompleted: (res) => {
      user.login(res.login.token, res.login.refreshToken, res.login.sessionId);
    },
    onError: (err) => {
      if (err.message === "two factor not filled") {
        tf.toggle();
      } else {
        setAuthError(err.message);
      }
    },
  });

  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    setAuthError("");
    try {
      const formData = await loginSchema.validate(data, {
        abortEarly: false,
      });

      login({
        variables: formData,
      });
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        console.log(err);
        cogoToast.error(err.message);
        setAuthError(err.message);
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      }
    }

    tf.setCode("");
  };

  return (
    <>
      <ProvideTwoFactor
        isOpen={tf.isOpen}
        toggle={tf.toggle}
        setCode={tf.setCode}
        code={tf.code}
        onSubmit={() =>
          login({
            variables: {
              ...formRef.current.getData(),
              code: tf.code,
              hcaptchaToken: "azsdasd",
            },
          })
        }
      />
      <Template>
        <main>
          <div className="d-flex justify-content-center h-100">
            <div className="form-access">
              <Card
                body
                className="border-0 shadow mt-3"
                style={{
                  maxWidth: "321px",
                }}
              >
                <h4 className="text-center">Log In</h4>
                <p
                  className="text-center"
                  style={{
                    fontSize: "13px",
                    color: "rgb(112, 122, 138)",
                  }}
                >
                  Please check that you are visiting the correct URL
                </p>

                <CorrectURL />
                <Form ref={formRef} onSubmit={onSubmit}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      className="form-control fb-input-text"
                      placeholder="Email Address"
                    />
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <Label for="password">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      className="form-control fb-input-text"
                      placeholder="Password"
                    />
                  </FormGroup>
                  <FormText className="text-right">
                    <Link to="/auth/lost">{"Esqueceu sua senha?"}</Link>
                  </FormText>
                  {/* <HCaptcha
                    theme="dark"
                    sitekey="9791ee7f-77c7-41e9-ba72-1f75942dee85"
                    onVerify={(token: React.SetStateAction<string>) =>
                      setHcaptcha(token)
                    }
                  /> */}
                  <div className="d-grid gap-2">
                    <Button
                      type="submit"
                      className="mt-3"
                      color="primary"
                      data-cy="login"
                    >
                      {"Sign In"}
                    </Button>
                  </div>

                  {authError && (
                    <Alert className="mt-3" color="danger">
                      {authError}
                    </Alert>
                  )}
                </Form>
                <FormText className="mt-3">
                  Don't have an account?{" "}
                  <Link to="/auth/register">Register here</Link>
                </FormText>
              </Card>
            </div>
          </div>
        </main>
      </Template>
    </>
  );
};

export default Login;
