import React from "react";
import Template from "../../components/Common/Template";
import { Card } from "reactstrap";
import { useApp } from "../../hooks/appHooks";

const RiskAndCompliance: React.FC = () => {
  const ctx = useApp();

  return (
    <Template nonfluid>
      <Card body className="border-0 mt-3">
        <article>
          <p>
            <strong>RISK AND COMPLIANCE DISCLOSURE</strong>
          </p>

          <p>
            Trading in digital assets is considered high risk. Digital assets
            are NOT backed by any government or central bank. The risk of loss
            in trading or holding digital assets can be substantial.
          </p>

          <p>
            There are many risks associated with virtual (digital) currencies
            transactions. Before trading or transacting using our Services, it
            is important to understand the risks. Please execute your
            transaction with understanding such features, mechanisms, and risks
            without objection and based on your own judgment and responsibility.
            This document is only a summary of risks and does not provide an
            exhaustive list of all the risks associated with Virtual Currency
            trading. We ask you to take time to carefully read them through and
            to consider whether such trading and transacting on{" "}
            {ctx.branding.name.toUpperCase()} is appropriate in your particular
            circumstances.
          </p>

          <p>&nbsp;</p>

          <p>
            You should seek independent professional advice if you do not fully
            understand the risks of using our Services.
          </p>

          <p>&nbsp;</p>

          <p>
            Capitalised terms used herein have the meaning set out in Terms of
            Use, unless the context requires otherwise.
          </p>

          <p>&nbsp;</p>

          <p>
            1. Trading operations risks. You acknowledge and agree that you
            shall access and use the Services at your own risk. The risk of loss
            in trading Digital Token pairs can be substantial. You should,
            therefore, carefully consider whether such trading is suitable for
            you in light of your circumstances, objectives and financial
            resources. You should be aware of the following points:
          </p>

          <p>&nbsp;</p>

          <p>
            Price fluctuation risks: You may sustain a total loss of the Funds
            transferred to, and of all Funds in your{" "}
            {ctx.branding.name.toUpperCase()} Account. The traded price of
            Digital Tokens can fluctuate greatly within a short period of time.
            The traded price of a Digital Token also may decrease due to various
            factors including discovery of wrongful conduct, market
            manipulation, changes to properties of the Digital Token, Attacks
            (as defined in paragraph 4 below), suspension or cessation of
            support for a Digital Token by {ctx.branding.name.toUpperCase()} or
            External Account providers or other service providers, and other
            factors outside the control of {ctx.branding.name.toUpperCase()}.
          </p>

          <p>
            Liquidity risk: Under certain market conditions, you may find it
            difficult or impossible to buy or sell a Digital Token. This can
            occur, for example, if there is insufficient liquidity in the
            market. We are not and shall not be responsible or liable for the
            transferability, liquidity and/or availability of any Digital
            Tokens.
          </p>

          <p>
            Operational risks: {ctx.branding.name.toUpperCase()} may suspend
            trading in or cease to offer Services in respect of any of the
            Digital Tokens at any time at {ctx.branding.name.toUpperCase()}’s
            sole discretion. You may be unable to withdraw Digital Tokens prior
            to {ctx.branding.name.toUpperCase()}’s ceasing to offer Services in
            respect of any such Digital Tokens, resulting in the loss of any
            such Digital Tokens remaining in your{" "}
            {ctx.branding.name.toUpperCase()} Account.
          </p>

          <p>
            This brief statement cannot, of course, disclose all the risks and
            other aspects associated with these trades. You are solely
            responsible for ensuring that you understand and accept all risks
            connected with transacting and trading in Digital Tokens, and are
            able and willing to accept and bear for your own account all
            possible losses that may arise from such transactions and trading in
            Digital Tokens.
          </p>

          <p>&nbsp;</p>

          <p>
            2. Internet transmission risks and error correction. You acknowledge
            that there are risks associated with utilizing an Internet-based
            trading system including, but not limited to, the failure of
            hardware, software, and Internet connections. You acknowledge that
            {ctx.branding.name.toUpperCase()} shall not be responsible for any
            communication failures, disruptions, errors, distortions or delays
            you may experience when trading via the Services, howsoever caused.
          </p>

          <p>&nbsp;</p>

          <p>
            3. Risks associated with attacks on the Services or the{" "}
            {ctx.branding.name.toUpperCase()}
            Accounts. The Services and/or the {ctx.branding.name.toUpperCase()}{" "}
            Accounts and the Funds held therein may be subject to attacks on
            their security, integrity or operation, and you acknowledge and
            agree that {ctx.branding.name.toUpperCase()} shall not be
            responsible or liable for any losses resulting therefrom.
          </p>

          <p>&nbsp;</p>

          <p>
            4. Risks arising from properties of Digital Tokens and System risks.
          </p>

          <p>
            Any Digital Token and the software, networks, protocols, systems and
            other technology (including, if applicable, any blockchain) used to
            administer, create, issue, transfer, cancel, use or transact in any
            Digital Token (the “Underlying Technology”) may be vulnerable to
            attacks on its security, integrity or operation (“Attacks”),
            including attacks using computational power sufficient to overwhelm
            the normal operation of a blockchain or other Underlying Technology.
          </p>

          <p>
            Any Digital Token or Underlying Technology may change or otherwise
            cease to operate as expected due to a change made to the Underlying
            Technology, a change made using features or functions built into the
            Underlying Technology or a change resulting from an Attack. These
            changes may include, without limitation, a “fork” or “rollback” of a
            Digital Token or blockchain.
          </p>

          <p>
            Any Digital Token may be cancelled, lost or double spent, or have
            its traded price otherwise diminished, due to forks, rollbacks,
            Attacks, changes to the functions, characteristics, operation, use
            and other properties of the Digital Token or failure of the Digital
            Token to operate as intended.
          </p>

          <p>
            {ctx.branding.name.toUpperCase()} may not support related side
            chains or other Underlying Technology of Digital Tokens that are
            based on a fork, enhancement, or derivative of a different Digital
            Token or Underlying Technology, even if such is based on a Digital
            Token that is supported by
            {ctx.branding.name.toUpperCase()}.
          </p>

          <p>
            Any Digital Token may be lost if sent to the wrong address (for
            example but without limitation, if the address is improperly
            formatted, contains errors, or is intended to be used for a
            different type of digital token).
          </p>

          <p>&nbsp;</p>

          <p>
            5. Regulatory risks. The regulatory status of Digital Tokens is
            unclear or unsettled in many jurisdictions. It is difficult to
            predict how or whether governmental authorities will regulate the
            Digital Tokens. It is likewise difficult to predict how or whether
            any governmental authority may make changes to existing laws,
            regulations and/or rules that will affect any of the Digital Tokens.
            The Digital Tokens hence may be securities or be otherwise regulated
            under the laws of certain jurisdictions.{" "}
            {ctx.branding.name.toUpperCase()} may cease offering Services in
            respect of any of the Digital Tokens or prohibit use of the Services
            in or from certain jurisdictions in the event that governmental
            actions make it unlawful or commercially undesirable to continue to
            offer such Services in respect of any Digital Token. Under Section
            1.1 of the Terms of Use, you also represent and warrant that you
            will not use our Services if any applicable laws in your country
            prohibit you from doing so in accordance with the Terms of Use.
          </p>

          <p>&nbsp;</p>

          <p>
            6. Counterparty risk. We have no control over, or liability for, the
            delivery, quality, safety, legality or any other aspect of any
            Digital Tokens that you may purchase or sell to or from a user of
            the Services. We are not responsible for ensuring that a third-party
            buyer or a seller you transact with will complete a trade or
            transaction or is authorised to do so. There is also no guarantee
            fund established or other arrangement in place to cover or
            compensate you for any pecuniary loss suffered by you as a result of
            any defaults by or the insolvency of any other users of the
            Services.
          </p>

          <p>&nbsp;</p>

          <p>
            The risks described in this Risk Disclosure Statement may result in
            loss of Digital Tokens, decrease in or loss of all value for Digital
            Tokens, inability to access or transfer Digital Tokens, inability to
            trade Digital Tokens, inability to receive any specific utility,
            access or benefits available to other Digital Token holders, and
            other financial losses to you. You hereby accept and agree that
            {ctx.branding.name.toUpperCase()} will, at all times, have no
            responsibility or liability for, such risks. You hereby irrevocably
            waive, release and discharge any and all claims, whether known or
            unknown to you, against
            {ctx.branding.name.toUpperCase()} and their respective directors,
            members, officers, employees, agents and contractors related to any
            of the risks set forth herein.
          </p>

          <p>&nbsp;</p>

          <p>
            You represent and warrant that you have: (a) the necessary technical
            expertise and ability to review and evaluate the security, integrity
            and operation of any Digital Tokens that you decide to acquire or
            trade; and (b) the knowledge, experience, understanding,
            professional advice and information to make your own evaluation of
            the merits and risks of any Digital Token or trade. You accept the
            risk of trading Digital Tokens by using the Services, and are
            responsible for conducting your own independent analysis of the
            risks specific to the Digital Tokens and the Services. You should
            not acquire or trade any Tokens unless you have sufficient financial
            resources and can afford to lose all value of the Digital Tokens, or
            suffer substantial losses.
          </p>

          <p>&nbsp;</p>

          <p>
            {ctx.branding.name.toUpperCase()}’s decision to support transfer,
            storage or trading of any particular Digital Token through the
            Services does not indicate
            {ctx.branding.name.toUpperCase()}’s approval or disapproval of the
            Digital Token or the integrity, security or operation of the Digital
            Token or its Underlying Technology and{" "}
            {ctx.branding.name.toUpperCase()} does not in any way make any
            representation or warranty as to any Digital Token supported or not
            supported by the Services. The risks associated with Digital Tokens
            and trading Digital Tokens apply notwithstanding{" "}
            {ctx.branding.name.toUpperCase()}’s decision to provide Services in
            respect of a particular Digital Token, and risks of all such trading
            will be wholly yours and yours alone to bear.
          </p>

          <p>&nbsp;</p>

          <p>
            Anti-Money Laundering/Counter the Financing of Terrorism (AML/CFT)
            Policy
          </p>

          <p>&nbsp;</p>

          <p>
            We have designed our Program to reasonably prevent money laundering
            and terrorist financing through a risk-based, multi-layer control
            system.
          </p>

          <p>&nbsp;</p>

          <p>
            The first layer includes a stringent User identification program,
            including verifying the identity of our Users, whether individuals
            or entities. In addition to obtaining identification documents, we
            obtain for non-natural persons their entities’ beneficial
            owners/natural persons consistent to international standards such as
            the Financial Action Task Force (FATF).
          </p>

          <p>&nbsp;</p>

          <p>
            The second layer includes a risk-based system to warrant additional
            User due diligence. To accomplish this, we screen our Users
            (including beneficial owners) against government-provided lists of
            sanctioned individuals and entities. We also may screen against
            other lists on a discretionary basis to protect our reputation and
            Users.
          </p>

          <p>&nbsp;</p>

          <p>
            The third layer includes ongoing monitoring for suspicious activity.
            If our Program suspects or has reason to suspect suspicious
            activities have occurred, we will file suspicious activities reports
            with local regulators. A suspicious transaction is often
            inconsistent with a User’s known and legitimate business, or
            personal activities.
          </p>

          <p>&nbsp;</p>

          <p>Counter Financing of Terrorism (CFT)</p>

          <p>
            The Company takes a risk-based approach when adopting and
            implementing counter financing of terrorism (CFT) measures and in
            conducting AML risk assessments.
          </p>

          <p>&nbsp;</p>

          <p>International Sanctions Policy (ISP)</p>

          <p>
            Our company is prohibited from transacting with individuals,
            companies and countries that are on prescribed sanctions lists.
          </p>

          <p>&nbsp;</p>

          <p>Know Your Customer Procedures (KYC)</p>

          <p>
            Individuals can be identified by passport or other identification
            document and utility bills stating their current post address.
            Companies have to be identified by extracts from the Chamber of
            Commerce or by notary deed. Copies have to be made and archived in
            files securely.
          </p>

          <p>&nbsp;</p>

          <p>
            By performing the registration on our Website the User makes
            complete and unconditional acceptance of the policy conditions,
            agrees with the following rules and undertakes to observe them:
          </p>

          <p>&nbsp;</p>

          <p>
            1. All Users of {ctx.branding.name.toUpperCase()} Exchange are
            treated equally and in the same manner, therefore the AML (Anti
            Money Laundering) requirements are the same. The only difference may
            be levels (Tiers) for requesting additional information from Users.
          </p>

          <p>
            2. If you successfully pass the KYC which is associated with
            relevant tier, you will obtain all benefits foreseen for this Tier.
            Usually we try to perform KYC procedures as soon as possible,
            however in separate circumstances it may take several days;
          </p>

          <p>
            3. Corporate and institutional Users are a subject for enhanced KYC
            measures from the beginning. If you are corporate or institutional
            User please follow the requirements to this AML&amp;KYC policy
            below;
          </p>

          <p>
            4. Upon the request of the Company the additional documents and
            information should be provided by the User. Filling of respective
            KYC questionnaire may also be an obligatory requirement under
            Company’s sole decision. The User agrees with such requirements of
            the Company and agrees to follow them;
          </p>

          <p>
            5. The withdrawal of Assets is carried out only to the account that
            belongs to a person identified as a User of the Company. The
            withdrawal of Assets to third parties is prohibited. Internal
            transfers between the Company’s Users are also forbidden.
          </p>

          <p>
            6. The Company is obliged and has the right without obtaining prior
            consent to share with financial institutions and law enforcement
            agencies any information about the User, as required by applicable
            law, and the User gives the Company its consent to these actions. In
            order to comply with this paragraph, the Company holds a records
            about User’s transactions during five years at least.
          </p>

          <p>
            7. The User undertakes to follow the legislation, including
            international one, aiming to combat illicit trafficking, financial
            fraud, money laundering and legalization of funds obtained by
            illegal means. The User undertakes to use its best effort to avoid
            direct or indirect participation in illegal financial activities and
            any illegal transactions using the Company’s Website and Services.
          </p>

          <p>
            8. The User guarantees the legal origin, legal ownership and the
            right to use the Assets transferred to its account.
          </p>

          <p>
            9. In case of the evidence of suspicious transactions at the User’s
            account, funds from untrusted sources (for example stolen funds
            reportidly enter Users account) and / or any actions with attributes
            of fraud (including any refunds or cancellation of payments), the
            Company reserves the right to conduct an internal investigation, to
            block or close the User’s Account, cancel any payment or trading
            order and to suspend operations on the Accounts before the end of
            the official investigation. When making the decision the Company is
            guided by the provisions of the applicable law, FATF recommendations
            or by common practice.
          </p>

          <p>
            10. The Company has the right to request additional information
            about the User if the method of withdrawal Assets is different from
            the depositing method. The Company also reserves the right to block
            the User’s &nbsp;Account during the investigation if the User
            refused to provide the additional information requested by the
            Company.
          </p>

          <p>
            11. In the course of investigation the Company has the right to
            request additional copies of documents confirming the identity of
            the User, as well as copies of bank cards used to replenish the
            account, payment documents and other documents confirming the lawful
            possession and legal origin of funds. The Company also has the right
            to demand the provision of original documents for review in case of
            any doubts from Company’s side.
          </p>

          <p>
            12. The Company does not provide Services to the persons who are
            residents of jurisdictions that are identified by the FATF as high
            risk and non-cooperative jurisdictions having strategic AML/CFT
            deficiencies, namely North Korea and Iran;
          </p>

          <p>
            13. The Company doesn’t provide services to citizens and residents
            of U.S.A.;
          </p>

          <p>
            14. The Company’s refuse to perform the operations that from
            Company’s point of view are considered to be a suspicious, using of
            preventive measures (such as blocking or closing of a User’s
            Account) is not the reason for civil liability of the Company for
            failure to fulfill obligations to the User.
          </p>

          <p>
            15. The Company is not obliged to inform the User or other persons
            about the measures taken to fulfil the AML/CFT legislation. Also the
            Company is not obliged to inform Users or other persons about any
            suspicions, about the reasons of refuse of execution of a User’s
            trading order, of refuse to open the Account, need for providing
            User’s documents, etc.
          </p>

          <p>
            16. This anti-money laundering and Know Your Customer policy is an
            integral part of the User agreement, located at the Website.
            Non-compliance with this policy can be a ground for termination of
            the User agreement on Company’s sole discretion.
          </p>

          <p>&nbsp;</p>

          <p>Verification measures for corporate and institutional Users</p>

          <p>
            This policy is applied for the corporate and institutional Users
            only. For this category of Users, we apply the obligatory enhanced
            verification process.
          </p>

          <p>&nbsp;</p>

          <p>
            We reserve the right to refuse You in provision of services if
            during the process of verification, it is discovered that You do not
            correspond with our KYC requirements. Also we have a right to
            terminate our existing relationships with You in any time in case if
            You are considered to be violating our KYC requirements.
          </p>

          <p>&nbsp;</p>

          <p>
            Upon our request any additional documents and information should be
            provided by You, and if You refuse to provide such documents or
            information this also may lead to our refuse or termination of
            provision of services.
          </p>

          <p>&nbsp;</p>

          <p>
            To open an account as a corporate or institutional User, please
            contact support. Please be advised that you should have necessary
            powers from your company in order to fill all the relevant
            application forms and questionnaires. Also please provide corporate
            documents listed below. Once you’ve submitted all the necessary
            documents, You should wait for the following instructions from the
            manager.
          </p>

          <p>&nbsp;</p>

          <p>
            The following supporting documentation must accompany the User’s
            completed questionnaire:
          </p>

          <p>- Memorandum and Articles of Association;</p>

          <p>- Certificate of Incorporation;</p>

          <p>- Certificate of Incumbency (dated within the last 3 months);</p>

          <p>
            - Financial statements for the last two years, or company bank
            statements for the last three months;
          </p>

          <p>- Copy of Register of Shareholders;</p>

          <p>- Copy of Register of Directors;</p>

          <p>
            - Proof of Registered Address (original utility bill or bank
            statement displaying the company’s name and registered address dated
            within the last three months);
          </p>

          <p>
            - Proof of Operating Address (original utility bill of bank
            statement displaying the company’s name and operating address dated
            within the last three months);
          </p>

          <p>
            - Individual verification of Authorized Representative (copy of
            passport verified by notary); Individual verification of all active
            Directors (copy of passport verified by notary and residential
            utility bill not older than 3 months);
          </p>

          <p>
            - Individual verification of Shareholders/Beneficial Owners with a
            holding of 25% or more:
          </p>

          <p>
            - Individual shareholders (copy of passport verified by notary and
            residential utility bill not older than 3 months);
          </p>

          <p>
            - Corporate shareholders (a complete list of supporting
            documentation for each company as listed above);
          </p>

          <p>
            - Confirmation of source of funds. For corporate Users audited bank
            accounts is a priority.
          </p>
        </article>
      </Card>
    </Template>
  );
};

export default RiskAndCompliance;
