import { useWindowSize } from "@coinsamba/react-tiny-hooks";
import React, { useEffect, useMemo } from "react";
import { AiOutlineHistory, AiOutlineRobot } from "react-icons/ai";
import { IoWalletOutline } from "react-icons/io5";
import { useHistory } from "react-router";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import { IMobileMenuProps } from "../../typings/Trade";
import { BsBoxArrowDown, BsBoxArrowUp } from "react-icons/bs";
import { GiPartyPopper } from "react-icons/gi";
import { useState } from "react";
import { IActivableComponent } from "../Trade/Pro/LeftPanel";
import { FiKey } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { ROLES } from "../../typings/Account";
import { ITemplateMenuItem } from "../../typings/App";
import { useAuth } from "../../hooks/useAuth";

const MobileMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: scroll;
  padding: 0 15px;
  margin-bottom: 1rem;
  padding-bottom: 10px;

  ::-webkit-scrollbar {
    width: 6px;
    height: 2px;
    /* border-left: 1px solid #e6ecf8; */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e6ecf8;
  }
`;

const MobileMenuItem = styled.div<IMobileMenuProps>`
  height: auto;
  padding: 5px;
  padding-bottom: 0;
  width: 100%;
  cursor: pointer;
  border: none;
  transition: all 0.4s ease;
  border-bottom: ${(props) =>
    props.active ? `2px solid ${props.theme.primary}` : "none"};

  span {
    min-width: 174px;
    white-space: nowrap;
    width: 100%;
    padding-top: 0;
  }

  :hover {
    border-bottom: 2px solid ${(props) => props.theme.primary};
    background-color: #dbdbdb;
  }
`;

const DesktopMenuWrapper = styled.div`
  width: 100%;
  padding-bottom: 0;
  /* height: calc(100% - 120px); */
  /* position: absolute; */
  /* top: 7.5rem; */
  padding-top: 0;
  /* z-index: 4; */
  /* background-color: #fff; */
  transition: all 0.2s ease;
  /* box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 2%); */
`;

const DesktopMenu = styled.ul`
  display: flex;
  flex-direction: column;
  padding-top: 15px;

  padding: 0;
  margin: 0;
  list-style: none;
`;

const DesktopMenuItem = styled.li<IActivableComponent>`
  /* padding: 0 15px; */
  height: 45px;

  span {
    transition: all 0.1s ease;
    white-space: nowrap;
    width: 100%;
    display: block;
    background: ${(props) => props.active && props.theme.secondary};
    color: ${(props) => props.active && props.theme.primary};
    font-weight: 500;
    box-shadow: none;
    font-size: 18px;
    padding: 15px 15px;
    border-radius: 1.375rem;

    :hover {
      background: ${(props) => props.theme.bg1};
    }
  }
`;

const AccountTemplate: React.FC = ({ children }) => {
  const { user } = useAuth();
  const router = useHistory();
  const location = useLocation();
  const windowSize = useWindowSize();
  const { pathname } = location;
  const [active, setActive] = useState(pathname);

  const menuClick = (path: string) => {
    setActive(path);
    router.push(path);
  };

  useEffect(() => {
    console.log(location.pathname);
    setActive(location.pathname);
  }, [location.pathname]);

  const isMobile = windowSize.width < 768;

  const menuItems = useMemo<ITemplateMenuItem[]>(
    () => [
      {
        hide: false,
        text: "Balances",
        path: "/account/balances",
        icon: IoWalletOutline,
      },
      {
        hide: false,
        text: "Deposits",
        path: "/account/deposits",
        icon: BsBoxArrowDown,
      },
      {
        hide: false,
        text: "Withdrawals",
        path: "/account/withdrawal",
        icon: BsBoxArrowUp,
      },
      {
        hide: false,
        text: "Ledger",
        path: "/account/ledger",
        icon: AiOutlineHistory,
      },
      { hide: false, text: "API", path: "/account/api", icon: AiOutlineRobot },
      {
        hide: false,
        text: "Security",
        path: "/account/security",
        alternativePaths: ["/account/two-factor"],
        icon: FiKey,
      },
      {
        hide: !user.roles.includes(ROLES.EVENT_MANAGER),
        text: "My Events",
        path: "/account/my-token-events",
        icon: GiPartyPopper,
      },
    ],
    []
  );

  return (
    <Row
      className="mt-3"
      style={{
        minHeight: !isMobile ? "720px" : "100%",
      }}
    >
      {isMobile ? (
        <MobileMenuWrapper>
          {menuItems.map(
            (m) =>
              !m.hide && (
                <MobileMenuItem
                  key={m.text}
                  onClick={() => menuClick(m.path)}
                  active={
                    active === m.path || m.alternativePaths?.includes(active)
                  }
                >
                  <span>
                    <m.icon /> {m.text}
                  </span>
                </MobileMenuItem>
              )
          )}
        </MobileMenuWrapper>
      ) : (
        <Col sm={2}>
          <DesktopMenuWrapper>
            <DesktopMenu>
              {menuItems.map(
                (m) =>
                  !m.hide && (
                    <DesktopMenuItem
                      key={m.text}
                      active={
                        active === m.path ||
                        m.alternativePaths?.includes(active)
                      }
                      onClick={() => menuClick(m.path)}
                      data-cy={m.text}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <span>
                        <m.icon /> {m.text}
                      </span>
                    </DesktopMenuItem>
                  )
              )}
            </DesktopMenu>
          </DesktopMenuWrapper>
        </Col>
      )}

      <Col sm={isMobile ? 12 : 10}> {children} </Col>
    </Row>
  );
};

export default AccountTemplate;
