import React from "react";
import { useHistory } from "react-router-dom";
import useBalances from "../../../hooks/useBalances";
import {
  ActionCell,
  OrdersContent,
  OrdersTableBody,
  OrdersTableCell,
  OrdersTableHeader,
  OrdersTableRow,
} from "./ActiveOrders";

const Balances: React.FC = () => {
  const balances = useBalances();
  const history = useHistory();

  return (
    <OrdersContent>
      <OrdersTableHeader>
        <OrdersTableCell
          style={{
            width: "30%",
            textAlign: "left",
          }}
        >
          Symbol
        </OrdersTableCell>

        <OrdersTableCell
          style={{
            width: "30%",
            textAlign: "left",
          }}
        >
          Available
        </OrdersTableCell>

        <OrdersTableCell
          style={{
            width: "25%",
            whiteSpace: "nowrap",
          }}
        >
          On Hold
        </OrdersTableCell>
        <OrdersTableCell
          style={{
            width: "15%",
            whiteSpace: "nowrap",
          }}
        >
          Action
        </OrdersTableCell>
      </OrdersTableHeader>
      <OrdersTableBody>
        {balances.balances.map((o) => (
          <OrdersTableRow>
            <OrdersTableCell
              style={{
                width: "31%",
                textAlign: "left",
                whiteSpace: "nowrap",
              }}
            >
              {o.symbol}
            </OrdersTableCell>
            <OrdersTableCell
              style={{
                width: "31%",
                textAlign: "left",
              }}
            >
              {o.available}
            </OrdersTableCell>
            <OrdersTableCell
              style={{
                width: "19%",
                whiteSpace: "nowrap",
              }}
            >
              {o.onHold}
            </OrdersTableCell>
            <ActionCell
              style={{
                width: "19%",
                whiteSpace: "nowrap",
              }}
              onClick={() => history.push("/account/balances")}
            >
              Deposit
            </ActionCell>
          </OrdersTableRow>
        ))}
      </OrdersTableBody>
    </OrdersContent>
  );
};

export default Balances;
