import React from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import Copy from "./Copy";

const InputCopy: React.FC<{ value: string | number }> = ({ value }) => {
  return (
    <InputGroup>
      <Input disabled value={value} />
      <InputGroupText>
        <Copy toCopy={value} />
      </InputGroupText>
    </InputGroup>
  );
};

export default InputCopy;
