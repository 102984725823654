import { useWindowSize } from "@coinsamba/react-tiny-hooks";
import React from "react";
import { BsArrowLeftRight } from "react-icons/bs";
import { FiSun } from "react-icons/fi";
import { IoIosStarOutline } from "react-icons/io";
import { IoMoonOutline } from "react-icons/io5";
import styled from "styled-components";
import { useApp } from "../../../hooks/appHooks";
import useSummary from "../../../hooks/useSummary";
import { useTrade } from "../../../hooks/useTrade";

const MiddleTopContainer = styled.div`
  flex: 0 0 auto;
  margin: 0 0 5px;
`;

const HeadLineWrapper = styled.div`
  height: 50px;
  background-color: ${(props) => props.theme.backgroundColor2};
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeadLineLeft = styled.div`
  display: flex;
  align-items: center;
`;

const HeadLineRight = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  margin-left: 10px;
`;

const HeadLineCurrencyFlip = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const HeadLineFavourite = styled.span`
  font-size: 18px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const HeadLinePair = styled.div`
  color: var(--main-text-1);
  display: flex;
  align-items: center;
`;

const HeadLineCoinSelect = styled.h2`
  font-size: 20px;
  margin-right: 10px;
  margin-bottom: 0 px;
`;

const HeadLineMarginLeft = styled.div`
  margin-left: 20px;
`;

const UpDownValueIndexWrapper = styled.div`
  font-size: 14px;
  margin-right: 5px;

  display: inline-flex;
  position: relative;
  pointer-events: none;
`;

const UpdownValueIndexBg = styled.div`
  position: absolute;
  left: -20px;
  right: -20px;
  top: -5px;
  bottom: -5px;
  padding-left: 5px;
  transition: all 0.3s;
  border-radius: 15px;
  display: flex;
  align-items: center;
`;

const UpdownValueIndexContent = styled.div`
  position: relative;
  z-index: 1;
`;

const PricePercentIndex = styled.div`
  color: ${(props) => props.theme.bid};
  font-weight: 500;
`;

const HeadlineItemTop = styled.div`
  // color: ${(props) => props.theme.secondary};
  margin-bottom: 5px;
  white-space: nowrap;
`;

const MiddleTop: React.FC = () => {
  const app = useApp();
  const ctx = useTrade();
  const windowSize = useWindowSize();

  const summary = useSummary();

  const smallScreen = windowSize.width < 1393;

  return (
    <MiddleTopContainer>
      <HeadLineWrapper>
        <HeadLineLeft>
          <HeadLineFavourite>
            <IoIosStarOutline color="#ced1e4" />
          </HeadLineFavourite>
          <div onClick={() => ctx.toggleLeftPanel()}>
            <HeadLinePair>
              <div>
                <HeadLineCoinSelect
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {ctx.base}/{ctx.quote} {smallScreen && <BsArrowLeftRight />}
                </HeadLineCoinSelect>
              </div>
            </HeadLinePair>
          </div>
          <HeadLineMarginLeft>
            <div
              style={{
                whiteSpace: "nowrap",
                display: "flex",
              }}
            >
              <UpDownValueIndexWrapper>
                <UpdownValueIndexBg />
                <UpdownValueIndexContent>
                  {summary.byBaseQuote(ctx.base, ctx.quote)?.last}
                </UpdownValueIndexContent>
              </UpDownValueIndexWrapper>
              <PricePercentIndex>
                {(
                  summary.byBaseQuote(ctx.base, ctx.quote)?.change || 0
                ).toFixed(2)}
                %
              </PricePercentIndex>
            </div>
            {/* <div>
              ≈ <span>$0.99</span>
            </div> */}
          </HeadLineMarginLeft>
          <HeadLineMarginLeft>
            <HeadlineItemTop>24H Max</HeadlineItemTop>
            <div
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {summary.byBaseQuote(ctx.base, ctx.quote)?.high || 0}
            </div>
          </HeadLineMarginLeft>
          <HeadLineMarginLeft>
            <HeadlineItemTop>24H Min</HeadlineItemTop>
            <div
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {summary.byBaseQuote(ctx.base, ctx.quote)?.low || 0}
            </div>
          </HeadLineMarginLeft>
          <HeadLineMarginLeft>
            <HeadlineItemTop>Vol({ctx.base})</HeadlineItemTop>
            <div
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {summary.byBaseQuote(ctx.base, ctx.quote)?.vol || 0}
            </div>
          </HeadLineMarginLeft>
        </HeadLineLeft>

        <HeadLineRight>
          <HeadLineCurrencyFlip onClick={() => app.toggleTheme()}>
            {!app.darkMode ? <IoMoonOutline /> : <FiSun />}
          </HeadLineCurrencyFlip>
        </HeadLineRight>
      </HeadLineWrapper>
    </MiddleTopContainer>
  );
};

export default MiddleTop;
