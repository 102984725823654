import React from "react";
import styled from "styled-components";

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 750px;
  height: 110px;
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid #e7ebee;
  padding-left: 30px;
  padding-right: 20px;
  z-index: 1;
`;

const MobileHeader: React.FC = () => {
  return (
    <HeaderWrapper>
      <img
        style={{
          marginTop: "35px",
        }}
        src="/main_logo.png"
        title="Coinsamba Exchange"
        alt="Coinsamba Exchange"
        height={46}
      />
    </HeaderWrapper>
  );
};

export default MobileHeader;
