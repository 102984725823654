import React, { useEffect } from "react";
import { useTable, usePagination, Column } from "react-table";
import { Table } from "reactstrap";

const CustomTable: React.FC<{
  columns: Column<object>[];
  data: object[];
  loading: boolean;
  pageCount: number;
  setOffset: CallableFunction;
  setLimit: CallableFunction;
}> = ({
  columns,
  data,
  loading,
  pageCount: controlledPageCount,
  setOffset,
  setLimit,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  );

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    setOffset(pageIndex * pageSize);
    setLimit(pageSize);
  }, [pageIndex, pageSize, setLimit, setOffset]);

  // Render the UI for your table
  return (
    <>
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
            },
            null,
            2
          )}
        </code>
      </pre> */}
      <Table
        {...getTableProps()}
        className="text-center"
        size="sm"
        striped
        responsive
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th {...column.getHeaderProps()} key={`col${i}`}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={`row${i}`}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
          <tr>
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <td colSpan={10000}>Loading...</td>
            ) : (
              <td colSpan={10000}>
                Page {pageIndex + 1} of {pageOptions.length}
              </td>
            )}
          </tr>
        </tbody>
      </Table>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          <li
            className={`page-item ${!canPreviousPage && "disabled"} `}
            onClick={() => gotoPage(0)}
          >
            <a className="page-link" href="#" aria-disabled="true">
              {"<<"}
            </a>
          </li>
          <li
            className={`page-item ${!canPreviousPage && "disabled"} `}
            onClick={() => previousPage()}
          >
            <a className="page-link" href="#" aria-disabled="true">
              {"<"}
            </a>
          </li>
          <li
            className={`page-item ${!canNextPage && "disabled"} `}
            onClick={() => nextPage()}
          >
            <a className="page-link" href="#" aria-disabled="true">
              {">"}
            </a>
          </li>
          <li
            className={`page-item ${!canNextPage && "disabled"} `}
            onClick={() => gotoPage(pageCount - 1)}
          >
            <a className="page-link" href="#">
              {">>"}
            </a>
          </li>
        </ul>
      </nav>
      <div className="pagination">
        <span>
          {controlledPageCount > 1
            ? controlledPageCount * pageSize
            : page.length}{" "}
          results found, {page.length} per page
        </span>
      </div>
    </>
  );
};

export default CustomTable;
