import { useMutation } from "@apollo/client";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import cogoToast from "cogo-toast";
import React, { useRef } from "react";
import { Button, Card, FormGroup, Label } from "reactstrap";
import ProvideTwoFactor, {
  useProvideTwoFactor,
} from "../../components/Common/ProvideTwoFactor";
import { useAuth } from "../../hooks/useAuth";
import { changePasswordSchema } from "../../schemas/auth";
import * as Yup from "yup";
import Input from "../../components/Form/Input";
import { CHANGE_PASSWORD } from "../../graphql/auth";

interface IFormData {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

const ChangePassword: React.FC = () => {
  const { user } = useAuth();

  const [change] = useMutation(CHANGE_PASSWORD);

  const tf = useProvideTwoFactor();

  const formRef = useRef<FormHandles>(null);

  const onSubmit: SubmitHandler<IFormData> = async () => {
    if (user?.twoFactor && tf.code.length !== 6) return tf.toggle();

    const formData = formRef.current?.getData() as IFormData;

    try {
      const { currentPassword, newPassword } =
        await changePasswordSchema.validate(formData, {
          abortEarly: false,
        });

      change({
        variables: {
          currentPassword,
          newPassword,
          code: tf.code,
        },
      })
        .then(() => cogoToast.success("password changed"))
        .catch((err) => {
          cogoToast.error(err.message);
        })
        .finally(() => {
          formRef.current?.reset();
          tf.setCode("");
        });
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        console.log(err);
        cogoToast.error(err.message);
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      }
    }
  };

  return (
    <>
      <ProvideTwoFactor
        isOpen={tf.isOpen}
        toggle={tf.toggle}
        onSubmit={onSubmit}
        setCode={tf.setCode}
        code={tf.code}
      />
      <Card body className="mb-3 border-0">
        <h4>Change your password</h4>
        <hr />
        <Form ref={formRef} onSubmit={onSubmit}>
          <FormGroup>
            <Label for="currentPassword">Current Password</Label>
            <Input
              name="currentPassword"
              id="currentPassword"
              type="password"
              required
            />
          </FormGroup>

          <FormGroup>
            <Label for="newPassword">New Password</Label>
            <Input
              name="newPassword"
              id="newPassword"
              type="password"
              required
            />
          </FormGroup>

          <FormGroup>
            <Label for="newPasswordConfirmation">Confirm New Password</Label>
            <Input
              name="newPasswordConfirmation"
              id="newPasswordConfirmation"
              type="password"
              required
            />
          </FormGroup>

          <Button className="mt-3" block color="primary" type="submit">
            Generate
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default ChangePassword;
