import { useQuery, useSubscription } from "@apollo/client";
import cogoToast from "cogo-toast";
import React, { useState } from "react";
import styled from "styled-components";
import { GET_ORDER_BOOK, SUBSCRIBE_ORDERBOOK } from "../../../graphql/trade";
import { IOrder, IOrderbook, ORDER_SIDE } from "../../../typings/Trade";
import { BN } from "../../../utils/bn";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import _ from "lodash";
import useSummary from "../../../hooks/useSummary";
import { useTrade } from "../../../hooks/useTrade";
import { IEnginePair } from "../../../pages/admin/Pairs";

const BookContainer = styled.div`
  background-color: ${(props) => props.theme.backgroundColor2};
  height: calc(100% - 35px);
  will-change: transform;
`;

const BookHeader = styled.div`
  display: flex;

  border-bottom: 1px solid ${(props) => props.theme.divider};
  // color: ${(props) => props.theme.secondary};

  h3 {
    white-space: nowrap;
    margin-bottom: 0;
    // color: ${(props) => props.theme.secondary};
    font-size: 12px;
    line-height: 32px;
  }
`;

const BookHeaderPrice = styled.h3`
  width: 34%;
  position: relative;
  cursor: pointer;
  padding-left: 20px;
`;

const BookHeaderVol = styled.h3`
  width: 34%;
  text-align: right;
  position: relative;
  cursor: pointer;
  padding-right: 11px;
`;

const BookHeaderAmount = styled.h3`
  width: 32%;
  text-align: right;
  position: relative;
  cursor: pointer;
  padding-right: 11px;
`;

const BookBody = styled.div`
  height: calc(100% - 33px);
  position: relative;
`;

const AsksWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  height: calc((100% - 40px) / 2);
  position: relative;
  width: 100%;
`;

const BidsWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  height: calc((100% - 40px) / 2);
  position: relative;
  width: 100%;
`;

const PageBids = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;

const PageAsks = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
`;

interface IOrderBookTableRow {
  side: "ask" | "bid";
}

const OrderBookTableRow = styled.div<IOrderBookTableRow>`
  color: ${(props) =>
    props.side === ORDER_SIDE.ASK
      ? props.theme.ask
      : (props) => props.theme.bid};
  display: flex;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  position: relative;
`;

const OrderBookTableRowPrice = styled.div`
  width: 34%;
  position: relative;
  cursor: pointer;
  padding-left: 20px;
`;

const OrderBookTableRowVol = styled.div`
  width: 34%;
  text-align: right;
  position: relative;
  cursor: pointer;
  padding-right: 11px;
  color: inherit;
`;

const OrderBookTableRowAmount = styled.div`
  width: 32%;
  text-align: right;
  position: relative;
  cursor: pointer;
  padding-right: 11px;
  color: inherit;
`;

const MarketPrice = styled.div`
  height: 40px;
  border-color: ${(props) => props.theme.divider};
  border-style: solid;
  border-width: 1px 0;
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

interface IMarketPrice {
  isTrendingUp: boolean;
}

const MarketPriceLast = styled.div<IMarketPrice>`
  font-size: 18px;
  color: ${(props) => (props.isTrendingUp ? props.theme.bid : props.theme.ask)};
`;

const BookRow = ({
  price,
  vol,
  side,
  rules,
  setPrice,
}: {
  price: number;
  vol: number;
  side: "ask" | "bid";
  setPrice: CallableFunction;
  rules: Partial<IEnginePair>;
}) => {
  return (
    <OrderBookTableRow side={side} onClick={() => setPrice(price)}>
      <OrderBookTableRowPrice>
        <span>{price.toFixed(rules?.pricePrecision || 8)}</span>
      </OrderBookTableRowPrice>
      <OrderBookTableRowVol>
        <span>{vol.toFixed(rules?.basePrecision || 8)}</span>
      </OrderBookTableRowVol>
      <OrderBookTableRowAmount>
        <span>{(vol * price).toFixed(rules?.pricePrecision || 8)}</span>
      </OrderBookTableRowAmount>
    </OrderBookTableRow>
  );
};

const prepareOrderBook = (book: IOrderbook) => {
  const clonnedAsks = _.cloneDeep(book.asks);

  return {
    asks: clonnedAsks.reverse(),
    bids: book.bids,
  };
};

const Book: React.FC<{ base: string; quote: string }> = ({ base, quote }) => {
  const ctx = useTrade();
  const summary = useSummary();

  const [orderbook, setOrderbook] = useState({
    asks: [],
    bids: [],
  } as IOrderbook);

  useQuery(GET_ORDER_BOOK, {
    variables: {
      base,
      quote,
    },
    onError: (err) => cogoToast.error(err.message),
    onCompleted: (data) => {
      if (data?.getOrderbook) {
        setOrderbook(prepareOrderBook(data.getOrderbook));
      }
    },
  });

  useSubscription(SUBSCRIBE_ORDERBOOK, {
    variables: {
      base,
      quote,
    },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      setOrderbook(prepareOrderBook(data.orderbook));
    },
  });

  const aggregateOrder = (orderbook: IOrder[]) => {
    const outputBook: IOrder[] = [];
    let outputPosition = 0;
    if (orderbook.length > 0) {
      outputBook.push(_.cloneDeep(orderbook[outputPosition]));
      for (let i = 1; i < orderbook.length; i++) {
        if (
          BN(orderbook[i].price).isEqualTo(outputBook[outputPosition].price)
        ) {
          outputBook[outputPosition].remainingAmount = BN(
            outputBook[outputPosition].remainingAmount
          )
            .plus(orderbook[i].remainingAmount)
            .toNumber();
        } else {
          outputBook.push(_.cloneDeep(orderbook[i]));
          outputPosition++;
        }
      }
    }
    return outputBook;
  };

  const lastTicker = summary.byBaseQuote(base, quote);
  const prevTicker = summary.byBaseQuote(base, quote, true);

  const isTrendingUp = (lastTicker?.last || 0) > (prevTicker?.last || 0);

  return (
    <BookContainer>
      <BookHeader>
        <BookHeaderPrice>Price({quote})</BookHeaderPrice>
        <BookHeaderVol>Quantity({base})</BookHeaderVol>
        <BookHeaderAmount>Total({quote})</BookHeaderAmount>
      </BookHeader>
      <BookBody>
        <AsksWrapper>
          <PageAsks>
            {aggregateOrder(orderbook.asks).map((o) => (
              <BookRow
                price={o.price}
                vol={o.remainingAmount}
                side="ask"
                rules={ctx?.rules}
                setPrice={ctx?.setPrice}
              />
            ))}
          </PageAsks>
        </AsksWrapper>
        <MarketPrice>
          <MarketPriceLast isTrendingUp={isTrendingUp}>
            {(lastTicker?.last || 0).toFixed(ctx?.rules?.pricePrecision || 8)}{" "}
            {isTrendingUp ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </MarketPriceLast>
          <span>
            {(summary.byBaseQuote(base, quote)?.change || 0).toFixed(2)}%
          </span>
        </MarketPrice>
        <BidsWrapper>
          <PageBids>
            {aggregateOrder(orderbook.bids).map((o) => (
              <BookRow
                price={o.price}
                vol={o.remainingAmount}
                side="bid"
                rules={ctx?.rules}
                setPrice={ctx?.setPrice}
              />
            ))}
          </PageBids>
        </BidsWrapper>
      </BookBody>
    </BookContainer>
  );
};

export default Book;
