import React from "react";
import { Card } from "reactstrap";
import Template from "../../components/Common/Template";
import { useApp } from "../../hooks/appHooks";

const Terms: React.FC = () => {
  const ctx = useApp();

  return (
    <Template nonfluid>
      <Card body className="border-0 mt-3">
        <article>
          <p>
            <strong>1. INTRODUCTION</strong>
          </p>

          <p>
            Thank you for visiting {ctx.branding.name.toUpperCase()}.com (“
            {ctx.branding.name.toUpperCase()}”), a digital asset trading
            website.
          </p>

          <p>
            By visiting, accessing, or using {ctx.branding.name.toUpperCase()}
            .com and associated application program interface or mobile
            applications (“Site”), you consent to the policies and practices of
            our privacy policy (the “Privacy Policy”), so please read them
            carefully.
          </p>

          <p>&nbsp;</p>

          <p>
            This Privacy Policy explains how {ctx.branding.name.toUpperCase()}{" "}
            uses your Personal Data (defined below) as we provide you with
            access and utility through our digital asset trading platform via
            software, API (application program interface), technologies,
            products and/or functionalities (“Service”). In the course of
            providing you our Service, to abide by the laws in the jurisdictions
            that we operate, and to improve our services, we need to collect and
            maintain personal information about you. As a rule, we never
            disclose any personal information about our customers to any
            non-affiliated third parties, except as described below.
          </p>

          <p>
            This Privacy Policy regulates the issues connected with the
            collection, storage, distribution and protection of confidentiality
            of the User data, and also sets the obligations and requirements for
            the User and the website.
          </p>

          <p>
            This Privacy Policy applies only to the{" "}
            {ctx.branding.name.toUpperCase()} website and all services offered
            by {ctx.branding.name.toUpperCase()}.{" "}
            {ctx.branding.name.toUpperCase()} does not control and is not liable
            for the websites located on domains other than https://
            {ctx.branding.name.toUpperCase()}.com (hereinafter the “Website”).
          </p>

          <p>
            It is recommended to review this Privacy Policy periodically to find
            out about its amendments. If you continue to use the Services after
            the amendments come into effect, you thereby accept the Privacy
            Policy as amended.
          </p>

          <p>
            {ctx.branding.name.toUpperCase()} may update, edit and amend this
            Confidentiality Policy, and after then it is immediately published
            on the Website.
          </p>

          <p>
            Amendments to the Confidentiality Policy enter into force
            immediately after its publication unless otherwise is stated in the
            amendments. By continuing to use the Services, you respectively
            accept the Confidentiality Policy with all the amendments made.
          </p>

          <p>&nbsp;</p>

          <p>
            <strong>2. DEFINITIONS</strong>
          </p>

          <p>&nbsp;</p>

          <p>Virtual Financial Asset</p>

          <p>
            As used herein, “Virtual Financial Asset” is also called
            “convertible virtual currency,” “cryptocurrency,” or “digital
            goods”, such as bitcoin or ether, which is based on the
            cryptographic protocol of a computer network that may be (i)
            centralized or decentralized, (ii) closed or open-source, and (iii)
            used as a medium of exchange and/or store of value.
          </p>

          <p>&nbsp;</p>

          <p>Personal Data</p>

          <p>
            As used herein, “Personal Data” means any information relating to an
            identified or identifiable natural person, such as a name, an
            identification number, location data, an online identifier or to one
            or more factors specific to the physical, economic, cultural or
            social identity of you as a natural person.
          </p>

          <p>
            <strong>3. WHAT PERSONAL DATA WE COLLECT</strong>
          </p>

          <p>&nbsp;</p>

          <p>
            3.1. {ctx.branding.name.toUpperCase()} exchange collects, processes,
            and stores Personal Data collected from you via your use of the
            Service or where you have given your consent. This Personal Data may
            include contact details, copies of identification documentation
            provided by you or derived from publicly accessible databases, your
            government identification number as well as information relating to
            your device or internet service (such as an IP address and a MAC
            number).
          </p>

          <p>
            3.2. We collect information you provide during the{" "}
            {ctx.branding.name.toUpperCase()}
            onboarding process, which may be a completed, incomplete, or
            abandoned process.
          </p>

          <p>
            3.3. We may also collect and store certain information automatically
            when you visit the Site. Examples include the internet protocol (IP)
            address used to connect your computer or device to the internet,
            connection information such as browser type and version, your
            operating system and platform, a unique reference number linked to
            the data you enter on our system, login details, the full URL
            clickstream to, through and from the Site (including date and time),
            cookie number and your activity on our Site, including the pages you
            visited, the searches you made and, if relevant, the
            products/services you purchased.
          </p>

          <p>
            3.4. We may receive information about you from third parties if you
            use any websites or social media platforms operated by third parties
            (for example, Facebook, Instagram, Twitter etc.) and, if such
            functionality is available, you have chosen to link your profile on
            the Site with your profile on those other websites or social media
            platforms.
          </p>

          <p>
            3.5. We take our responsibilities under Data Protection Act (the
            “DPA”) seriously. We also recognize the importance of the personal
            data you have entrusted to us and believe that it is our
            responsibility to properly manage, protect and process your personal
            data. To understand more about how we protect the data collected
            from individuals and entities, please see the details below.
          </p>

          <p>
            3.6. We collect, use, store, and transfer your Personal Data, which
            may include the following:
          </p>

          <p>&nbsp;</p>

          <p>
            <strong>Individual customers:</strong>
          </p>

          <p>- Email address</p>

          <p>- Mobile phone number</p>

          <p>
            - Full legal name (including former name, and names in local
            language)
          </p>

          <p>- Nationality</p>

          <p>- Passport number, or any government issued ID number</p>

          <p>- Date of birth (“DOB”)</p>

          <p>
            - Proof of identity (e.g. passport, driver’s license, or
            government-issued ID)
          </p>

          <p>- Residential address</p>

          <p>- Proof of residency</p>

          <p>
            - Additional Personal Data or documentation at the discretion of our
            Compliance Team
          </p>

          <p>
            <strong>Corporate customers:</strong>
          </p>

          <p>
            - Corporate legal name (including the legal name in local language)
          </p>

          <p>- Incorporation/registration Information</p>

          <p>
            - Full legal name of all beneficial owners, directors, and legal
            representatives
          </p>

          <p>
            - Address (principal place of business and/or other physical
            locations)
          </p>

          <p>- Proof of legal existence</p>

          <p>- Description of the business</p>

          <p>- Percentage of ownership for Individual/corporate owners</p>

          <p>
            - Contact information of owners, principals, and executive
            management (as applicable)
          </p>

          <p>
            - Proof of identity (e.g., passport, driver’s license, or
            government-issued ID) for significant individual beneficial owner of
            the institutional customer entity
          </p>

          <p>
            - Personal Data for each entity’s significant beneficial owner of
            the institutional customer entity (see the “Individual Customer”
            section above for details on what Personal Data we collect for
            individuals)
          </p>

          <p>- Source of wealth</p>

          <p>
            - Amount of bitcoin or other digital assets projected to be injected
          </p>

          <p>
            3.7. We are entitled to verify the authenticity of the provided User
            data, his/her identity card or any other documentation on
            third-party databases. You should realize and give consent that your
            information/documentation will be used in the following cases.
          </p>

          <p>&nbsp;</p>

          <p>
            <strong>4. COOKIES</strong>
          </p>

          <p>
            4.1. We may use cookies, web beacons and other similar technologies
            to collect information about the interaction of the User with the
            Site and to identify you from other users on the Site.
          </p>

          <p>
            4.2. A cookie is a small file of letters and numbers that we store
            on your browser or the hard drive of your computer or device. They
            help to save information about the actions of the User and the use
            of the website.
          </p>

          <p>
            4.3. You can block or deactivate cookies in your browser settings.
          </p>

          <p>
            4.4. We use log-in cookies in order to remember you when you have
            logged in for a seamless experience.
          </p>

          <p>
            4.5. We use session cookies to track your movements from page to
            page and in order to store your selected inputs so you are not
            constantly asked for the same information.
          </p>

          <p>
            4.6. This Site uses Google Analytics which is one of the most
            widespread and trusted analytics solution on the web for helping us
            to understand how you use the Site and ways that we can improve your
            experience. These cookies may track things such as how long you
            spend on the Site and the pages that you visit so we can continue to
            produce engaging content.
          </p>

          <p>
            4.7. By continuing to use the Site, you are agreeing to the use of
            cookies on the Site as outlined above. However, please note that we
            have no control over the cookies used by third parties.
          </p>

          <p>
            4.8. For further information on types of cookies and how they work
            visit www.allaboutcookies.org
          </p>

          <p>&nbsp;</p>

          <p>
            <strong>5. INFORMATION SECURITY</strong>
          </p>

          <p>
            5.1. {ctx.branding.name.toUpperCase()} makes every effort to protect
            the confidentiality of personal User data.
          </p>

          <p>
            We take various measures to ensure information security, including
            encryption of the {ctx.branding.name.toUpperCase()} communications
            with SSL; required two-factor authentication for all sessions;
            periodic review of our Personal Data collection, storage, and
            processing practices; and restricted access to your Personal Data on
            a need-to-know bases for our employees and vendors who are subject
            to strict contractual confidentiality obligations.
          </p>

          <p>
            Despite the efforts we make to secure and store personal information
            collected from the Website, we cannot guarantee 100% security of
            personal data during transmission or storage in our systems.
          </p>

          <p>
            5.2. Interaction with {ctx.branding.name.toUpperCase()} is carried
            out by the User on his own initiative; personal data, allowed for
            processing under this Confidentiality Policy, are provided by the
            User at his/her own risk.
          </p>

          <p>
            5.3. User personal data are stored in a safe and confidential mode
            for a period no longer than necessary in accordance with the aim of
            their processing and used strictly for its intended purpose
            specified in this Confidentiality Policy.
          </p>

          <p>5.4. Links to external sites.</p>

          <p>
            The website may contain links to external websites. If you click on
            the link of the external website, you will be redirected to this
            website. The company is not liable for the content and activities of
            such websites, as well as for ensuring your privacy, therefore it is
            strongly recommended that you review the confidentiality policies of
            each visited website.
          </p>

          <p>&nbsp;</p>

          <p>
            <strong>6. MARKETING</strong>
          </p>

          <p>
            6.1. We use the data provided by the User to deliver additional
            marketing information about the products and services we offer, as
            well as to improve the services we provide or to solve emerging
            issues.
          </p>

          <p>
            By using {ctx.branding.name.toUpperCase()}, you accept this Privacy
            Policy and you agree to receive such marketing communications.
          </p>

          <p>
            6.2. Users can opt out from these marketing communications at any
            moment. If you do not want to receive these communications, please
            send an email to support@{ctx.branding.id}.com.
          </p>

          <p>
            6.3. For product related communications, such as policy/terms
            updates and operational notifications, you will not be able to opt
            out of receiving such information.
          </p>

          <p>6.4. We do not pass personal data to third parties.</p>

          <p>&nbsp;</p>

          <p>
            <strong>
              7. CONTACTING {ctx.branding.name.toUpperCase()} ABOUT PRIVACY
              QUESTIONS OR CONCERNS
            </strong>
          </p>

          <p>
            If you have any questions about this Privacy Policy or the use of
            your Personal Data, please contact us by sending an email to
            support@{ctx.branding.id}.com with the subject “PRIVACY REQUEST”.
          </p>
        </article>
      </Card>
    </Template>
  );
};

export default Terms;
