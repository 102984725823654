import { useQuery } from "@apollo/client";
import React from "react";
import CreateAPIKey from "../../components/Account/CreateAPIKey";
import ListAPIKeys from "../../components/Account/ListAPIKeys";
import { LIST_API_KEYS } from "../../graphql/account";

export interface IAPIKey {
  _id: string;
  label: string;
  permissions: string[];
}

const API: React.FC = () => {
  const { data, refetch } = useQuery<{
    listAPIKeys: IAPIKey[];
  }>(LIST_API_KEYS);

  return (
    <>
      <CreateAPIKey refetch={refetch} />
      <ListAPIKeys keys={data?.listAPIKeys || []} refetch={refetch} />
    </>
  );
};

export default API;
