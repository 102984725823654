import React from "react";
import { Card, Table } from "reactstrap";
import AddCurrencyModal from "../../components/Admin/Currencies/AddCurrencyModal";
import EditCurrencyModal from "../../components/Admin/Currencies/EditCurrencyModal";
import ViewCurrencyNetworksModal from "../../components/Admin/Currencies/ViewCurrencyNetworksModal";
import { useApp } from "../../hooks/appHooks";

const Currencies: React.FC = () => {
  const app = useApp();

  return (
    <Card body className="border-0">
      <AddCurrencyModal />
      <hr />
      <Table size="sm" hover stripped="true">
        <thead>
          <tr>
            <th>Type</th>
            <th>Symbol</th>
            <th>Name</th>

            <th> </th>
          </tr>
        </thead>
        <tbody>
          {app.currencies.currencies.map((c) => (
            <tr key={c._id}>
              <td>{c.type}</td>
              <td>{c.symbol}</td>
              <td>{c.name}</td>
              <td>
                <ViewCurrencyNetworksModal
                  refetch={app.currencies.refetch}
                  currency={c}
                />
              </td>
              <td>
                <EditCurrencyModal
                  refetch={app.currencies.refetch}
                  currency={c}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default Currencies;
