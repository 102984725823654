import React from "react";
import styled from "styled-components";
import MobileCoinSwitch from "./MobileCoinSwitch";
import MobileHeader from "./MobileHeader";
import { createGlobalStyle } from "styled-components";
import TradePanel from "./TradePanel";
import OrdersPanel from "./OrdersPanel";

const GlobalStyle = createGlobalStyle`
  body, html {
    font: 28px/1 Roboto,PingFang SC,Hiragino Sans GB,Heiti SC,Microsoft YaHei,WenQuanYi Micro Hei,serif;
    height: 100%;
    max-width: 750px;
    width: 750px;
    margin: 0;
    color: #1f3f59;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    position: relative;

    /* ::after{
      display: block;
      visibility: hidden;
      height: 0;
      clear: both;
      content: ".";
    } */
  }
`;

const PageTrade = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-top: 110px;
  /* padding-bottom: 98px; */
  overflow: hidden;
`;

const MainScreen: React.FC = () => {
  return (
    <>
      <GlobalStyle />

      <MobileHeader />
      <PageTrade>
        {/* <SpotNav /> */}
        <MobileCoinSwitch />
        <TradePanel />
        <OrdersPanel />
      </PageTrade>
    </>
  );
};

export default MainScreen;
