import React, { useMemo } from "react";
import { useState } from "react";
import styled from "styled-components";
import useBalances from "../../../hooks/useBalances";
import { useTrade } from "../../../hooks/useTrade";
import { ORDER_SIDE } from "../../../typings/Trade";
import { BN } from "../../../utils/bn";
import Book from "./Book";

const OrderSPanelContainer = styled.div`
  padding-left: 30px;
  border-bottom: 20px solid #f7f7fb;

  ::after {
    display: block;
    visibility: hidden;
    height: 0;
    clear: both;
    content: ".";
  }
`;

const FloatLeft = styled.div`
  float: left;
  width: 382px;
  padding-top: 18px;
`;

const FloatRight = styled.div`
  float: right;
  width: 298px;
  margin-top: -50px;
`;

const InputBox = styled.div`
  position: relative;

  span {
    position: absolute;
    right: 20px;
    top: 0;
    line-height: 100px;
    font-size: 28px;
    color: #8c9fad;
  }
`;

const InputPrice = styled.input`
  outline: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  border: 1px solid #c8cfd5;
  font-size: 32px;
  font-weight: 700;
  padding-left: 20px;
  padding-right: 20px;

  ::placeholder {
    color: #c5cfd5;
  }
`;

const Equivalent = styled.div`
  color: #c5cfd5;
  font-size: 20px;
  padding-top: 10px;
  box-sizing: border-box;
  height: 60px;
`;

const Available = styled.div`
  height: 74px;
  font-size: 24px;
  padding-top: 10px;
  box-sizing: border-box;
  color: #8c9fad;
`;

const AvailableLine = styled.dl`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AvailableText = styled.dt`
  margin-right: 10px;
  float: left;
  height: 48px;
  font-weight: 400;
`;

const AvailableBalance = styled.dd`
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  span {
    margin-right: 10px;
    color: #1f3f59;
  }

  em {
    display: inline-block;
    vertical-align: middle;
    font-style: normal;
  }
`;

const ButtonGroup = styled.div`
  border: 1px solid #c8cfd5;
  height: 60px;
  box-sizing: border-box;

  button {
    color: #8c9fad;
    width: 126px;
    height: 58px;
    line-height: 60px;
    font-size: 24px;
    float: left;

    border: none;
    outline: none;
    -webkit-appearance: none;
    background: transparent;

    vertical-align: middle;
  }
`;

const ButtonGroupDivider = styled.span`
  float: left;
  width: 1px;
  height: 24px;
  margin-top: 18px;
  background: #e7ebee;
`;

const TotalIndicator = styled.div`
  white-space: nowrap;
  overflow: hidden;
  height: 120px;
  padding-top: 62px;
  box-sizing: border-box;
  color: #8c9fad;

  span {
  }

  em {
    font-style: normal;
    vertical-align: unset;
    font-weight: 600;
    color: #1f3f59;
  }
`;

interface IPlaceButton {
  side: "ask" | "bid";
}

const PlaceButton = styled.button<IPlaceButton>`
  border: none;
  outline: none;

  width: 382px;
  height: 88px;
  font-size: 28px;
  line-height: 88px;
  font-weight: 700;
  color: #fff;

  background-color: ${(props) =>
    props.side !== ORDER_SIDE.ASK
      ? (props) => props.theme.bid
      : props.theme.ask};
`;

const OrdersPanel: React.FC = () => {
  const ctx = useTrade();
  const { rules } = ctx;
  const balances = useBalances();

  const [price, setPrice] = useState(0);
  const [amount, setAmount] = useState(0);

  const amountStep = useMemo(
    () =>
      rules?.amountPrecision
        ? BN(1)
            .shiftedBy(rules.amountPrecision * -1)
            .toNumber()
        : 1,
    [rules]
  );

  return (
    <OrderSPanelContainer>
      <FloatLeft>
        <InputBox>
          <InputPrice
            placeholder="Price"
            type="number"
            step={1 / (1 * 10 ** (ctx?.rules?.pricePrecision || 1))}
            min={1 / (1 * 10 ** (ctx?.rules?.pricePrecision || 1))}
            value={price}
            onChange={({ target }) =>
              setPrice(target.value as unknown as number)
            }
          />
          <span>{ctx.quote}</span>
        </InputBox>
        <Equivalent />
        <InputBox>
          <InputPrice
            placeholder="Amount"
            type="number"
            step={amountStep}
            min={amountStep}
            max={ctx?.rules?.maxTradableAmount || 1}
            value={amount}
            onChange={({ target }) =>
              setAmount(target.value as unknown as number)
            }
          />
          <span>{ctx.base}</span>
        </InputBox>

        <Available>
          <AvailableLine>
            <AvailableText>Available</AvailableText>
            <AvailableBalance>
              <span>
                {ctx.op !== ORDER_SIDE.ASK
                  ? balances.bySymbol(ctx.quote)?.available || 0
                  : balances.bySymbol(ctx.base)?.available || 0}
              </span>
              <em>{ctx.op !== ORDER_SIDE.ASK ? ctx.quote : ctx.base}</em>
            </AvailableBalance>
          </AvailableLine>
        </Available>
        {ctx.op === ORDER_SIDE.ASK && (
          <ButtonGroup>
            <button
              onClick={() =>
                setAmount((balances.bySymbol(ctx.base)?.available || 0) * 0.25)
              }
            >
              25%
            </button>
            <ButtonGroupDivider />
            <button
              onClick={() =>
                setAmount((balances.bySymbol(ctx.base)?.available || 0) * 0.5)
              }
            >
              50%
            </button>
            <ButtonGroupDivider />
            <button
              onClick={() =>
                setAmount(balances.bySymbol(ctx.base)?.available || 0)
              }
            >
              All in
            </button>
          </ButtonGroup>
        )}

        <TotalIndicator>
          <span>
            Total{" "}
            <em>{(amount * price).toFixed(ctx?.rules?.pricePrecision || 1)}</em>{" "}
            <em>{ctx.quote}</em>
          </span>
        </TotalIndicator>
        <PlaceButton side={ctx.op}>
          {ctx.op !== ORDER_SIDE.ASK ? "Buy" : "Sell"}
        </PlaceButton>
      </FloatLeft>
      <FloatRight>
        <Book />
      </FloatRight>
    </OrderSPanelContainer>
  );
};

export default OrdersPanel;
