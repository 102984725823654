import { useQuery, useSubscription } from "@apollo/client";
import cogoToast from "cogo-toast";
import { format } from "date-fns";
import React, { useState } from "react";
import styled from "styled-components";
import { GET_LAST_TRADES, SUBSCRIBE_TRADES } from "../../../graphql/trade";
import { useTrade } from "../../../hooks/useTrade";
import { ORDER_SIDE } from "../../../typings/Trade";

interface ITrade {
  _id?: string;
  price: number;
  amount: number;
  taker: "ask" | "bid";
  createdAt: Date;
}

const TradesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 12px;
  will-change: bottom;
`;

const TradesModuleHeader = styled.div`
  height: 35px;
  background-color: ${(props) => props.theme.backgroundColor2};
  border-bottom: 1px solid ${(props) => props.theme.divider};
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TradesTitle = styled.span`
  font-size: 14px;
`;

const ModuleBody = styled.div`
  background-color: ${(props) => props.theme.backgroundColor2};
  height: calc(100% - 35px);
  position: relative;
`;

const TradesTableHeader = styled.div`
  display: flex;
  padding: 0 16px;
  line-height: 32px;
  border-bottom: 1px solid ${(props) => props.theme.divider};
  // color: ${(props) => props.theme.secondary};
`;

const TradesPrice = styled.div`
  width: 35%;
`;

const TradesVol = styled.div`
  width: 35%;
  text-align: right;
  // color: ${(props) => props.theme.secondary};
`;

const TradesTime = styled.div`
  width: 30%;
  text-align: right;
`;

const TradesTableBody = styled.div`
  height: calc(100% - 33px);
  overflow-y: hidden;
`;

const TradesTableRow = styled.div`
  display: flex;
  padding: 0 11px 0 16px;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  cursor: pointer;
`;

interface ITradesTableRowPrice {
  side: "ask" | "bid";
}

const TradesTableRowPrice = styled.div<ITradesTableRowPrice>`
  color: ${(props) =>
    props.side === ORDER_SIDE.ASK
      ? props.theme.ask
      : (props) => props.theme.bid};
  width: 35%;
`;

const TradestableRowVol = styled.div`
  width: 35%;
  text-align: right;
`;

const LastTrades: React.FC<{ base: string; quote: string }> = ({
  base,
  quote,
}) => {
  const ctx = useTrade();
  const [trades, setTrades] = useState([]);

  useQuery(GET_LAST_TRADES, {
    variables: {
      base,
      quote,
    },
    onCompleted: (data) => {
      if (data?.getLastTrades) setTrades(data.getLastTrades);
    },
    onError: (err) => cogoToast.error(err.message),
  });

  useSubscription(SUBSCRIBE_TRADES, {
    variables: {
      base,
      quote,
    },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const { trades } = data.trades;
      setTrades(trades || []);
    },
  });

  return (
    <TradesWrapper>
      <TradesModuleHeader>
        <TradesTitle>Market Trades</TradesTitle>
      </TradesModuleHeader>
      <ModuleBody>
        <TradesTableHeader>
          <TradesPrice>Price ({quote})</TradesPrice>
          <TradesVol>Quantity</TradesVol>
          <TradesTime>Time</TradesTime>
        </TradesTableHeader>
        <TradesTableBody>
          {trades.map((t: ITrade) => (
            <TradesTableRow>
              <TradesTableRowPrice side={t.taker}>
                {t.price.toFixed(ctx?.rules?.quotePrecision || 8)}
              </TradesTableRowPrice>
              <TradestableRowVol>
                {t.amount.toFixed(ctx?.rules?.basePrecision || 8)}
              </TradestableRowVol>
              <TradestableRowVol>
                {format(new Date(t.createdAt), "HH:mm:ss")}
              </TradestableRowVol>
            </TradesTableRow>
          ))}
        </TradesTableBody>
      </ModuleBody>
    </TradesWrapper>
  );
};

export default LastTrades;
