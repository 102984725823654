import gql from "graphql-tag";

// const NETWORK_INPUT = gql`
//   input NetworkInput {
//     name: String!
//     transferIdentification: String!
//     depositEnabled: Boolean!
//     withdrawalEnabled: Boolean!
//     depositFee: Float!
//     depositFeePercent: Float!
//     withdrawalFee: Float!
//     withdrawalFeePercent: Float!
//     minDeposit: Float!
//     minWithdrawal: Float!
//   }
// `;

export const ADD_CURRENCY_MUTATION = gql`
  mutation AddCurrencyMutation(
    $type: String!
    $symbol: String!
    $name: String!
    $decimalPlaces: Float!
  ) {
    addCurrency(
      type: $type
      symbol: $symbol
      name: $name
      decimalPlaces: $decimalPlaces
    )
  }
`;

export const EDIT_CURRENCY_MUTATION = gql`
  mutation EditCurrencyMutation($_id: String!, $name: String!) {
    editCurrency(_id: $_id, name: $name)
  }
`;

export const TOGGLE_CURRENCY_DEPOSIT_WITHDRAWAL_MUTATION = gql`
  mutation ToggleCurrencyDepositWithdrawalMutation(
    $_id: String!
    $network: String!
    $isDeposit: Boolean!
    $value: Boolean!
  ) {
    toggleCurrencyDepositWithdrawal(
      _id: $_id
      network: $network
      isDeposit: $isDeposit
      value: $value
    )
  }
`;

export const EDIT_CURRENCY_NETWORK_MUTATION = gql`
  mutation EditCurrencyNetworkMutation($_id: String!, $network: NetworkInput!) {
    editCurrencyNetwork(_id: $_id, network: $network)
  }
`;

export const ADD_CURRENCY_NETWORK_MUTATION = gql`
  mutation AddCurrencyNetworkMutation($_id: String!, $network: NetworkInput!) {
    addCurrencyNetwork(_id: $_id, network: $network)
  }
`;

export const GET_NUMER_OF_USERS = gql`
  query {
    numberOfUsers
  }
`;

export const GET_PUBLIC_STATISTICS = gql`
  query {
    publicStatistics {
      users
      pairs
      currencies
    }
  }
`;

export const GET_PAIRS = gql`
  query {
    pairs {
      id
      base
      quote
      enabled
      lastEnginePing
      maxTradableAmount
      pricePrecision
      amountPrecision
      minTotalAmount
      fee
    }
  }
`;

export const ADD_PAIR_MUTATION = gql`
  mutation AddPairMutation(
    $base: String!
    $quote: String!
    $amountPrecision: Float!
    $maxTradableAmount: Float!
    $pricePrecision: Float!
    $minTotalAmount: Float!
    $fee: Float!
  ) {
    addPair(
      base: $base
      quote: $quote
      amountPrecision: $amountPrecision
      maxTradableAmount: $maxTradableAmount
      pricePrecision: $pricePrecision
      minTotalAmount: $minTotalAmount
      fee: $fee
    )
  }
`;

export const TOGGLE_PAIR_MUTATION = gql`
  mutation TogglePairMutation($id: String!, $enabled: Boolean!) {
    togglePair(id: $id, enabled: $enabled)
  }
`;

export const EDIT_PAIR = gql`
  mutation EditPairMutation(
    $id: String!
    $amountPrecision: Float!
    $maxTradableAmount: Float!
    $pricePrecision: Float!
    $minTotalAmount: Float!
    $fee: Float!
  ) {
    editPair(
      id: $id
      amountPrecision: $amountPrecision
      maxTradableAmount: $maxTradableAmount
      pricePrecision: $pricePrecision
      minTotalAmount: $minTotalAmount
      fee: $fee
    )
  }
`;

export const FETCH_USER = gql`
  query ($search: String!) {
    fetchUser(search: $search) {
      user {
        _id
        email
        name
        roles
        twoFactor
        telegram
        createdAt
      }
      balances {
        name
        symbol
        available
        onHold
      }
    }
  }
`;

export const BLOCKUSER_MUTATION = gql`
  mutation blockUserMutation($id: String!) {
    blockUser(id: $id)
  }
`;

export const LOSTPASSWORD_MUTATION = gql`
  mutation lostPasswordMutation($id: String!) {
    delegateLostPassword(id: $id)
  }
`;

export const UNLINKTELEGRAM_MUTATION = gql`
  mutation unlinkTelegramMutation($id: String!) {
    delegateUnlinkTelegram(id: $id)
  }
`;
