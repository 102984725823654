const chartOptions = {
  candle: {
    margin: {
      top: 0.2,
      bottom: 0.1,
    },
    // 'candle_solid'|'candle_stroke'|'candle_up_stroke'|'candle_down_stroke'|'ohlc'|'area'
    type: "candle_solid",
    bar: {
      upColor: "#26A69A",
      downColor: "#EF5350",
      noChangeColor: "#26A69A",
    },
    area: {
      lineSize: 2,
      lineColor: "#2196F3",
      value: "close",
      fillColor: [
        {
          offset: 0,
          color: "rgba(33, 150, 243, 0.01)",
        },
        {
          offset: 1,
          color: "rgba(33, 150, 243, 0.2)",
        },
      ],
    },
    priceMark: {
      show: true,
      high: {
        show: true,
        color: "#D9D9D9",
        textMargin: 5,
        textSize: 10,
        textFamily: "Helvetica Neue",
        textWeight: "normal",
      },
      low: {
        show: true,
        color: "#D9D9D9",
        textMargin: 5,
        textSize: 10,
        textFamily: "Helvetica Neue",
        textWeight: "normal",
      },
      last: {
        show: true,
        upColor: "#26A69A",
        downColor: "#EF5350",
        noChangeColor: "#26A69A",
        line: {
          show: true,
          // 'solid'|'dash'
          style: "dash",
          dashValue: [4, 4],
          size: 1,
        },
        text: {
          show: true,
          size: 12,
          paddingLeft: 2,
          paddingTop: 2,
          paddingRight: 2,
          paddingBottom: 2,
          color: "#fff",
          family: "Helvetica Neue",
          weight: "normal",
          borderRadius: 2,
        },
      },
    },
    tooltip: {
      // 'always' | 'follow_cross' | 'none'
      showRule: "always",
      // 'standard' | 'rect'
      showType: "standard",
      labels: ["时间", "开", "收", "高", "低", "成交量"],
      values: null,
      defaultValue: "n/a",
      rect: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 6,
        offsetLeft: 8,
        offsetTop: 8,
        offsetRight: 8,
        borderRadius: 4,
        borderSize: 1,
        borderColor: "#3f4254",
        fillColor: "rgba(17, 17, 17, .3)",
      },
      text: {
        size: 12,
        family: "Helvetica Neue",
        weight: "normal",
        color: "#D9D9D9",
        marginLeft: 8,
        marginTop: 6,
        marginRight: 8,
        marginBottom: 0,
      },
    },
  },

  technicalIndicator: {
    margin: {
      top: 0.2,
      bottom: 0.1,
    },
    bar: {
      upColor: "#26A69A",
      downColor: "#EF5350",
      noChangeColor: "#26A69A",
    },
    line: {
      size: 1,
      colors: ["#FF9600", "#9D65C9", "#2196F3", "#E11D74", "#01C5C4"],
    },
    circle: {
      upColor: "#26A69A",
      downColor: "#EF5350",
      noChangeColor: "#26A69A",
    },
    lastValueMark: {
      show: false,
      text: {
        show: false,
        color: "#fff",
        size: 12,
        family: "Helvetica Neue",
        weight: "normal",
        paddingLeft: 3,
        paddingTop: 2,
        paddingRight: 3,
        paddingBottom: 2,
        borderRadius: 2,
      },
    },
    tooltip: {
      // 'always' | 'follow_cross' | 'none'
      showRule: "always",
      // 'standard' | 'rect'
      showType: "standard",
      showName: true,
      showParams: true,
      defaultValue: "n/a",
      text: {
        size: 12,
        family: "Helvetica Neue",
        weight: "normal",
        color: "#D9D9D9",
        marginTop: 6,
        marginRight: 8,
        marginBottom: 0,
        marginLeft: 8,
      },
    },
  },
};

export default chartOptions;
