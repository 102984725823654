import React from "react";
import { Switch, Route } from "react-router-dom";
import GenerateVerification from "../pages/token_access/GenerateVerification";
import SignVerification from "../pages/token_access/SignVerification";

const TokenAccessRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/token-access/generate-verification">
        <GenerateVerification />
      </Route>
      <Route exact path="/token-access/sign">
        <SignVerification />
      </Route>
    </Switch>
  );
};

export default TokenAccessRoutes;
