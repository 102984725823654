import React, { ReactNode } from "react";
import { UncontrolledTooltip } from "reactstrap";

const Tooltip: React.FC<{ children: ReactNode; text: any; id: any }> = ({
  children,
  text,
  id,
}) => {
  return (
    <>
      <span id={"uncontrolled" + id}>{children}</span>
      <UncontrolledTooltip placement="top" target={"uncontrolled" + id}>
        {text}
      </UncontrolledTooltip>
    </>
  );
};

export default Tooltip;
