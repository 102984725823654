import { useMutation } from "@apollo/client";
import cogoToast from "cogo-toast";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import {
  CONFIRM_ENABLE_TELEGRAM,
  ENABLE_TELEGRAM,
} from "../../graphql/account";
import { useAuth } from "../../hooks/useAuth";
import { BRANDING } from "../../branding";
import TelegramChat from "./TelegramChat";
import { ITelegramChat } from "../../typings/Account";

const EnableTelegram: React.FC = () => {
  const user = useAuth();

  const [code, setCode] = useState("");
  const [chatId, setChatId] = useState<ITelegramChat>(null);

  const handleTwoFactorChange = (value: string) => {
    setCode(value);
  };

  const [enable] = useMutation<{ enableTelegram: ITelegramChat }>(
    ENABLE_TELEGRAM,
    {
      onCompleted: (res) => {
        if (!res.enableTelegram) {
          cogoToast.error("invalid code, get a new one with our bot");
        } else {
          console.log(res);
          setChatId(res.enableTelegram);
        }
      },
      onError: (err) => cogoToast.error(err.message),
    }
  );

  const [confirm] = useMutation(CONFIRM_ENABLE_TELEGRAM, {
    onCompleted: () => {
      cogoToast.success("linked successfully");
      user.refetch();
    },
    onError: (err) => cogoToast.error(err.message),
  });

  return (
    <>
      <h1>Enable Telegram</h1>
      <ol>
        <li>
          First open your Telegram app and talk with{" "}
          <a
            href={`http://t.me/${BRANDING.coinsamba.telegram_bot}`}
            target="_blank"
          >
            @{BRANDING.coinsamba.telegram_bot}
          </a>
        </li>
        <li>
          Them request a link code that will be used to enable Telegram in your
          Coinsamba account
        </li>
      </ol>

      <Form
        style={{
          maxWidth: "350px",
          marginInline: "auto",
        }}
      >
        <FormGroup className="mb-3">
          <Label for="twoFactorInput">Telegram Link Code</Label>
          <Input
            type="text"
            maxLength={6}
            pattern="[0-9]{6}"
            value={code}
            onChange={({ target }) => handleTwoFactorChange(target.value)}
          />
        </FormGroup>
        {/* {chatId && (
          <FormGroup className="mb-3">
            <Label for="username">Telegram Username</Label>
            <Input type="text" readOnly value={chatId?.username} />
          </FormGroup>
        )} */}
        {chatId && (
          <Card border className="mb-3">
            <CardHeader>
              The following Telegram user is trying to link with your account
            </CardHeader>
            <CardBody>
              <p
                style={{
                  maxWidth: "300px",
                  marginInline: "auto",
                  // marginBottom: "10px",
                }}
              >
                Please confirm you Telegram username and name, after confirming
                you will be able to receive notifications via Telegram.
              </p>
              <TelegramChat chat={chatId} />
            </CardBody>
          </Card>
        )}
        {chatId ? (
          <div className="d-grid gap-2">
            <Button
              color="primary"
              onClick={() =>
                confirm({
                  variables: { chatId: chatId.id },
                })
              }
            >
              Confirm Telegram link
            </Button>
          </div>
        ) : (
          <div className="d-grid gap-2">
            <Button
              color="primary"
              onClick={() =>
                enable({
                  variables: { code },
                })
              }
            >
              Verify
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};

export default EnableTelegram;
