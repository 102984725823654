import React from "react";
import { GrAndroid, GrApple, GrStatusUnknown, GrWindows } from "react-icons/gr";
import { MdDesktopMac } from "react-icons/md";
import { SiLinux } from "react-icons/si";

const OperationalSystemIcon: React.FC<{ name: string; [x: string]: any }> = ({
  name,
  ...rest
}) => {
  if (name === "Windows") {
    return <GrWindows {...rest} />;
  } else if (name === "Android") {
    return <GrAndroid {...rest} />;
  } else if (name === "iOS") {
    return <GrApple {...rest} />;
  } else if (name === "Linux") {
    return <SiLinux {...rest} />;
  } else if (name === "Mac OS") {
    return <MdDesktopMac {...rest} />;
  }
  return <GrStatusUnknown {...rest} />;
};

export default OperationalSystemIcon;
