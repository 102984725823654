import React from "react";
import Routes from "./routes";
import merge from "lodash/merge";
import AuthProvider from "./providers/AuthProvider";
import BalanceProvider from "./providers/BalanceProvider";
import AppProvider from "./providers/AppProvider";
import { ThemeProvider } from "styled-components";

import { LIGHT, DARK, COINSAMBA_COLORS, BISCOINT_COLORS } from "./THEMES";
import { useState } from "react";
import { GlobalStyles } from "./globalStyles";
import { useShortcuts } from "react-shortcuts-hook";
import { BRANDING } from "./branding";
import { useMemo } from "react";

const App = () => {
  const [brand, setBrand] = useState("coinsamba");

  const toggleBrand = () =>
    setBrand((oldState) =>
      oldState === "coinsamba" ? "biscoint" : "coinsamba"
    );

  useShortcuts(["control", "b"], toggleBrand, [brand]);

  const [darkMode, setDarkMode] = useState(false);

  const toggleTheme = () => {
    setDarkMode((oldState) => !oldState);
  };

  const theme = useMemo(() => {
    return merge(
      {},
      darkMode ? DARK : LIGHT,
      brand === "coinsamba" ? COINSAMBA_COLORS : BISCOINT_COLORS
    );
  }, [brand, darkMode]);

  return (
    <AuthProvider>
      <AppProvider
        toggleTheme={toggleTheme}
        darkMode={darkMode}
        branding={BRANDING[brand]}
      >
        <BalanceProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Routes />
          </ThemeProvider>
        </BalanceProvider>
      </AppProvider>
    </AuthProvider>
  );
};

export default App;
