import React from "react";
import { useHistory } from "react-router-dom";
import Slick from "react-slick";
import { ITicker } from "../../components/Home/Summary";
import { useWindowSize } from "@coinsamba/react-tiny-hooks";
import { useQuery } from "@apollo/client";
import { GET_MOVERS } from "../../graphql/trade";
import styled from "styled-components";

const ParentDiv = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`;

const TickerCard = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #333;
  font-family: Titilliumweb, sans-serif;
  margin: 0px;
  box-sizing: border-box;
  // display: flex;
  height: 105px;
  min-width: 170px;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  border-radius: 0px;
  background-color: #fff;
  cursor: pointer;
`;

const TickerPriceAndVariation = styled.div`
  margin-bottom: 5px;
`;

const TickerVariation = styled.div`
  display: flex;
  margin-bottom: 3px;
  flex-direction: row;
  justify-content: space-between;
`;

const TickerCoin = styled.div`
  line-height: 20px;
  font-family: Titilliumweb, sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  margin-right: 20px;
  /* color: #fff; */
  font-size: 16px;
  font-weight: 400;
`;

const TickerVolume = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #333;
  font-family: Titilliumweb, sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 3px;
  flex-direction: row;
  justify-content: space-between;
`;

interface ITickerVariation {
  trendingUp: boolean;
}

const TickerPercentage = styled.div<ITickerVariation>`
  font-size: 14px;
  line-height: 20px;
  font-family: Titilliumweb, sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color: ${(props) => (props.trendingUp ? "#2dc043" : "#df4d33")};
`;

const TickerPrice = styled.div`
  line-height: 20px;
  font-family: Titilliumweb, sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  margin-top: 5px;
  /* color: #fff; */
  font-size: 18px;
`;

const TickerVolumeTitle = styled.div`
  line-height: 20px;
  font-family: Titilliumweb, sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color: #6b8a99;
  font-size: 13px;
  text-align: right;
`;

const TickerVolumeValue = styled.div`
  line-height: 20px;
  font-family: Titilliumweb, sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color: #6b8a99;
  font-size: 13px;
  text-align: right;
`;

const FeaturedCard: React.FC<{
  ticker: ITicker;
}> = ({ ticker: { base, quote, last, vol, change } }) => {
  const history = useHistory();

  const isTrendingUp = change > 0;

  return (
    <ParentDiv onClick={() => history.push(`/trade/${base}-${quote}`)}>
      <TickerCard>
        <TickerPriceAndVariation>
          <TickerVariation>
            <TickerCoin>
              {base}/{quote}
            </TickerCoin>
            <TickerPercentage trendingUp={isTrendingUp}>
              {change.toFixed(2)} %
            </TickerPercentage>
          </TickerVariation>
          <TickerPrice>{last}</TickerPrice>
        </TickerPriceAndVariation>
        <TickerVolume>
          <TickerVolumeTitle>Vol 24h</TickerVolumeTitle>
          <TickerVolumeValue>
            {vol} {base}
          </TickerVolumeValue>
        </TickerVolume>
      </TickerCard>
    </ParentDiv>
  );
};

const SwiperWrapper = styled.div`
  position: relative;
  /* bottom: 114px; */
  /* left: 0; */
  width: 100%;
  margin-bottom: 30px;
`;

const Slider: React.FC = () => {
  const { data } = useQuery(GET_MOVERS, {
    pollInterval: 3000,
    fetchPolicy: "cache-and-network",
  });

  const windowSize = useWindowSize();

  const isMobile = windowSize.width < 768;

  return (
    <SwiperWrapper>
      <Slick
        infinite
        arrows={false}
        dots={true}
        slidesToScroll={isMobile ? 1 : 1}
        slidesToShow={isMobile ? 1 : 3}
        autoplay
        speed={500}
        autoplaySpeed={10000}
        cssEase="ease-out"
      >
        {data?.getMovers.map((m) => (
          <FeaturedCard ticker={m} />
        ))}
      </Slick>
    </SwiperWrapper>
  );
};

export default Slider;
