import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import styled from "styled-components";
import { useAuth } from "../../hooks/useAuth";
import logo from "../../images/logo.png";
import logo_text from "../../images/logo_text.png";
import logo_text_biscoint from "../../images/logo_text_biscoint.png";
import logo_biscoint from "../../images/logo_biscoint.png";
import { useWindowSize } from "@coinsamba/react-tiny-hooks";
import { HiOutlineUserCircle } from "react-icons/hi";
import { useApp } from "../../hooks/appHooks";

const NavbarWrapper = styled.header`
  font-size: 14px;

  height: 60px;
  margin-bottom: 0;
  background-color: #151617;
  color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 15px;
  position: relative;
  z-index: 200;

  a {
    color: inherit;
    text-decoration: none;
    outline: 0;
  }
`;

const NavLeft = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const NavRight = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const NavLogoWrapper = styled.div`
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 3px;

  img {
    margin-top: 5px;
    vertical-align: middle;
    border-style: none;
  }
`;

const NavNormalLeftMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const NavMenuItem = styled.div`
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;

  :hover {
    transition: color 0.3s;
    color: ${(props) => props.theme.primary};
  }
`;

const Example = () => {
  const ctx = useApp();

  const { user, logout, loaded } = useAuth();

  const windowSize = useWindowSize();

  const isMobile = windowSize.width < 768;

  const logoImg = useMemo(() => {
    if (isMobile) {
      return ctx.branding.id === "coinsamba" ? logo : logo_biscoint;
    } else {
      return ctx.branding.id === "coinsamba" ? logo_text : logo_text_biscoint;
    }
  }, [isMobile, ctx.branding.id]);

  return (
    <NavbarWrapper>
      <NavLeft>
        <NavLogoWrapper>
          <Link to="/">
            <img src={logoImg} alt="Logo" height="25px" />
          </Link>
        </NavLogoWrapper>
        <NavNormalLeftMenuWrapper>
          <NavMenuItem>
            <Link to="/trade/BNB-CBRL">Trade</Link>
          </NavMenuItem>
          <NavMenuItem>
            <Link to="/fees">Fees</Link>
          </NavMenuItem>
          {user?.roles.includes("admin") && (
            <NavMenuItem>
              <Link to="/admin/panel">Admin</Link>
            </NavMenuItem>
          )}
        </NavNormalLeftMenuWrapper>
      </NavLeft>
      <NavRight>
        {!loaded ? (
          <NavMenuItem>
            <Spinner />
          </NavMenuItem>
        ) : user ? (
          <>
            {/* <NavMenuItem>
              <Link to="/account/balances">Balances</Link>
            </NavMenuItem> */}
            <NavMenuItem>
              <Link to="/account/ledger" data-cy="account">
                <HiOutlineUserCircle size={20} />
              </Link>
            </NavMenuItem>
            <NavMenuItem onClick={() => logout()}>Logout</NavMenuItem>
          </>
        ) : (
          <>
            <NavMenuItem>
              <Link to="/auth/login">Login</Link>
            </NavMenuItem>

            <Button
              outline
              style={{
                marginRight: "20px",
              }}
              color="primary"
            >
              <Link to="/auth/register">Register</Link>
            </Button>
          </>
        )}
      </NavRight>
    </NavbarWrapper>
  );
};

export default Example;
