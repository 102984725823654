import { useMutation, useQuery } from "@apollo/client";
import cogoToast from "cogo-toast";
import React, { useCallback, useMemo, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import { Badge, Button, Card, Container, Row } from "reactstrap";
import CustomSwitch from "../../components/Form/CustomSwitch";
import {
  GENERATE_TOKEN_EVENT_VERIFICATION_MUTATION,
  GET_CODE_IS_VERIFIED,
  GET_TOKEN_EVENT_DETAILS,
} from "../../graphql/tokenEvent";
import { useApp } from "../../hooks/appHooks";
import { ITokenEvent } from "../../typings/TokenEvent";

const METAMASK_DEEP_LINK = "https://metamask.app.link/dapp/";

const GenerateVerification: React.FC = () => {
  const app = useApp();
  const { search } = useLocation();
  const [metamask, setMetamask] = useState(false);
  const [code, setCode] = useState("");
  const [verified, setVerified] = useState(false);

  const eventId = useMemo(() => {
    const params = new URLSearchParams(search);

    return params.get("event");
  }, [search]);

  const { data } = useQuery<{ getEventDetails: ITokenEvent }>(
    GET_TOKEN_EVENT_DETAILS,
    {
      variables: {
        eventId,
      },
      onError: (err) => {
        console.error(err);
        cogoToast.error(err.message);
      },
    }
  );

  useQuery<{ codeIsVerified: boolean }>(GET_CODE_IS_VERIFIED, {
    variables: {
      code,
    },
    pollInterval: 5000,
    skip: !code,
    onCompleted: (res) => {
      if (res.codeIsVerified) {
        cogoToast.success("User verified");
        setVerified(true);
        setCode("");
      }
    },
    onError: (err) => {
      console.error(err);
      cogoToast.error(err.message);
    },
  });

  const [generate] = useMutation<{ generateTokenEventVerification: string }>(
    GENERATE_TOKEN_EVENT_VERIFICATION_MUTATION
  );

  const generateVerification = useCallback(() => {
    generate({
      variables: {
        eventId,
      },
    })
      .then((res) => {
        setVerified(false);
        setCode(res.data.generateTokenEventVerification);
      })
      .catch((err) => {
        console.error(err);
        cogoToast.error(err.message);
      });
  }, [eventId]);

  const toggleMetamask = useCallback(() => {
    setMetamask((oldState) => !oldState);
  }, []);

  const link = `${metamask ? METAMASK_DEEP_LINK : "https://"}${
    app.branding.host
  }/token-access/sign?code=${code}`;

  return (
    <Container fluid>
      <Card body className="mt-3">
        <Row className="text-center">
          <h2>{data?.getEventDetails?.name}</h2>
        </Row>
        <hr />
        {verified && (
          <Row className="text-center mb-3 mt-3">
            <h2>
              User Verified! <AiOutlineCheck color="green" />
            </h2>
          </Row>
        )}
        {code && (
          <>
            <Row className="mb-3 mt-3 text-center">
              <div>{code && <QRCode value={link} size={300} />}</div>
              <div className="mt-3">
                <CustomSwitch
                  id={"metamaskSwitch"}
                  onChange={toggleMetamask}
                  checked={metamask}
                  label="MetaMask"
                  style={{
                    maxWidth: "150px",
                    margin: "auto",
                  }}
                />
              </div>
            </Row>
            <Row className="mb-3 mt-3 text-center">
              <Badge
                className="m-auto"
                style={{ maxWidth: "200px", fontSize: "15pt" }}
                color="info"
              >
                {code}
              </Badge>
            </Row>
          </>
        )}
        <Row>
          <div className="d-grid gap-2 col-12 mx-auto">
            <Button onClick={generateVerification} size="lg" color="primary">
              Generate New Verification
            </Button>
          </div>
        </Row>
      </Card>
    </Container>
  );
};

export default GenerateVerification;
