import { useMutation, useQuery } from "@apollo/client";

import { format } from "date-fns";
import React, { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import { Column } from "react-table";
import { Badge, Button, Card, Col, Label, Row } from "reactstrap";
import Callout from "../../components/Common/Callout";
import CustomTable from "../../components/Common/CustomTable";
import StringCopy from "../../components/Common/StringCopy";
import {
  DEV_CREDIT_BALANCE,
  GET_ADDRESS,
  GET_DEPOSITS_WITHDRAWALS,
} from "../../graphql/account";
import { useApp } from "../../hooks/appHooks";
import useBalances from "../../hooks/useBalances";
import { Coinsamba } from "../../services/Coinsamba";
import { ASSET_NETWORK } from "../../typings/assets";

/*
According to the token mechanism, making a deposit will occur a 10% fee that is charged by ASS. For all deposits made, an additional 10% trading fee will be charged by MEXC. Total amount received will be 80% of the initial deposit. Users are strongly advised to do their risk assessment before deciding to invest. 


*/

const AddressInfo: React.FC<{
  symbol: string;
}> = ({ symbol }) => {
  const { data } = useQuery(GET_ADDRESS, {
    fetchPolicy: "cache-first",
    variables: {
      symbol,
    },
  });

  if (!data?.getAddress?.address) return null;

  const addrs = data?.getAddress?.address;

  return (
    <Card body className="text-center">
      Deposit Address
      <div className="mx-auto p-3">
        <QRCode value={addrs} size={150} />
      </div>
      <StringCopy toCopy={addrs} />
    </Card>
  );
};

const Deposits: React.FC = () => {
  const { search } = useLocation();
  const app = useApp();
  const balances = useBalances();
  const [selectedNetwork, setSelectedNetwork] = useState<ASSET_NETWORK>();
  const [selectedSymbol, setSelectedSymbol] = useState("");

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const [credit] = useMutation(DEV_CREDIT_BALANCE);

  const { loading, data, refetch } = useQuery<{ getDepositWithdrawal: any }>(
    GET_DEPOSITS_WITHDRAWALS,
    {
      variables: {
        offset: 0,
        limit: 10,
        type: "deposit",
        symbol: selectedSymbol,
      },
      fetchPolicy: "network-only",
      skip: offset !== 0,
      pollInterval: 3000,
    }
  );

  useEffect(() => {
    refetch({
      offset,
      limit,
      type: "deposit",
      symbol: selectedSymbol,
    });
  }, [offset, limit, refetch]);

  const collumns = useMemo(
    () =>
      [
        {
          Header: "Amount",
          accessor: "amount",
          Cell: (props) => (
            <span>
              {props.value} {selectedSymbol}
            </span>
          ),
        },
        {
          Header: "Date",
          accessor: "createdAt",
          Cell: (props) => (
            <span>{format(new Date(props.value), "dd/MM/yyyy HH:mm:ss")}</span>
          ),
        },
        {
          Header: "Progress",
          accessor: "progress",
          Cell: () => <span>Confirmed</span>,
        },
      ] as Column[],
    [selectedSymbol]
  );

  useEffect(() => {
    const query = new URLSearchParams(search);
    const currencyFromQuery = query.get("c");
    console.log(currencyFromQuery);
    setSelectedSymbol(
      currencyFromQuery || app.currencies.currencies[0]?.symbol
    );
  }, []);

  useEffect(() => {
    if (selectedSymbol && currentCurrency.networks.length > 0) {
      setSelectedNetwork(currentCurrency.networks[0].name);
    }
  }, [selectedSymbol]);

  const currentCurrency = useMemo(
    () => app.currencies.currencies.find((c) => c.symbol === selectedSymbol),
    [selectedSymbol]
  );

  const { minDeposit, depositFee, depositFeePercent } = useMemo(
    () =>
      currentCurrency?.networks.find((n) => n.name === selectedNetwork) ?? {
        minDeposit: 0,
        depositFee: 0,
        depositFeePercent: 0,
      },
    [selectedNetwork]
  );

  return (
    <>
      <Card body className="border-0 mb-3">
        <h4>Deposits</h4>
        <hr />
        <Row>
          <Col sm={6}>
            <Label className="mb-3">Currency</Label>
            <select
              className="form-select mb-3"
              aria-label="Default select example"
              value={selectedSymbol}
              onChange={({ target }) => setSelectedSymbol(target.value)}
            >
              {app.currencies.currencies.map((c) => (
                <option value={c.symbol} key={c.symbol}>
                  {c.symbol}
                </option>
              ))}
            </select>
            <Callout>
              <p>
                Min. Deposit Amount: {minDeposit} {selectedSymbol}.<br /> The
                asset you deposited will be lost if less than the Min. Deposit
                Amount.
              </p>
              {!!depositFee && (
                <p>
                  Due to transfer costs a fee of {depositFee} {selectedSymbol}{" "}
                  will be charged to your deposit.
                </p>
              )}
              {!!depositFeePercent && (
                <p>
                  Due to transfer costs a fee of {depositFeePercent}%{" "}
                  {selectedSymbol} will be charged to your deposit.
                </p>
              )}
            </Callout>
            <dl className="mt-3">
              <dt>Available Balance</dt>
              <dd>
                {balances.bySymbol(selectedSymbol)?.available} {selectedSymbol}
              </dd>

              <dt>On Hold</dt>
              <dd>
                {balances.bySymbol(selectedSymbol)?.onHold} {selectedSymbol}
              </dd>
              {Coinsamba.isDev && (
                <dd>
                  <Button
                    onClick={() =>
                      credit({
                        variables: {
                          symbol: selectedSymbol,
                        },
                      })
                    }
                  >
                    [DEV] Credit
                  </Button>
                </dd>
              )}
            </dl>
          </Col>
          <Col sm={6}>
            <span>Available Networks</span>
            <div className="mb-3">
              {currentCurrency?.networks.map((n) => (
                <Badge
                  className="mr-3"
                  color={selectedNetwork === n.name ? "primary" : "secondary"}
                  onClick={() => setSelectedNetwork(n.name)}
                >
                  {n.name.toUpperCase()}
                </Badge>
              ))}
            </div>
            <AddressInfo symbol={selectedSymbol} />
          </Col>
        </Row>
      </Card>
      <Card body className="border-0">
        <CustomTable
          columns={collumns}
          data={data?.getDepositWithdrawal.entries || []}
          loading={loading}
          pageCount={data?.getDepositWithdrawal.totalPages}
          setOffset={setOffset}
          setLimit={setLimit}
        />
      </Card>
    </>
  );
};

export default Deposits;
