import { createContext, useCallback, useContext } from "react";
import { IBalance } from "../typings/Balance";

type ContextProps = {
  balances: IBalance[];
  fetchBalances: CallableFunction;
};

export const BalanceContext = createContext({} as ContextProps);

const useBalances = () => {
  const { balances, fetchBalances } = useContext(BalanceContext);

  const bySymbol = useCallback((symbol: string) => 
     balances.find((b) => b.symbol === symbol)
  , [balances]);

  return {
    fetchBalances,
    balances,
    bySymbol,
  };
};

export default useBalances;
