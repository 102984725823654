import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { BsShield } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa";
import {
  Card,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Row,
} from "reactstrap";
import { LIST_SESSIONS } from "../../graphql/account";
import { useAuth } from "../../hooks/useAuth";
import { ISession } from "../../typings/Account";
import ua from "ua-parser-js";
import OperationalSystemIcon from "../../components/Common/OperationalSystemIcon";
import { Storage } from "../../services/Storage";
import { SESSION_ID_KEY } from "../../providers/AuthProvider";
import { LOGOUT_MUTATION } from "../../graphql/auth";
import { useHistory } from "react-router";

const Security: React.FC = () => {
  const user = useAuth();

  const history = useHistory();

  const { data: listSessionsData, refetch } =
    useQuery<{ listSessions: ISession[] }>(LIST_SESSIONS);

  const [logoutMutation] = useMutation(LOGOUT_MUTATION, {
    onCompleted: () => refetch(),
  });

  return (
    <Card body className="border-0 mb-3">
      <h4>Security</h4>
      <hr />
      <Card className="mb-3">
        <CardHeader>Active Sessions</CardHeader>
        <ListGroup>
          {listSessionsData?.listSessions.map((s) => {
            const agent = new ua(s.agent);
            const os = agent.getOS();

            const isThisSession = Storage.get(SESSION_ID_KEY) === s._id;

            const lastRefresh = new Date(s.lastRefresh);

            return (
              <ListGroupItem
                key={s._id}
                className="d-flex justify-content-between align-items-start"
              >
                <Row>
                  <Col
                    xs="1"
                    className="d-flex"
                    style={{
                      minWidth: "fit-content",
                    }}
                  >
                    <OperationalSystemIcon
                      size={30}
                      className="m-auto"
                      name={os.name}
                    />
                  </Col>
                  <Col>
                    <ListGroupItemHeading>
                      {os.name} {os.version}
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      {isThisSession && "This session"} {s.ip} - Last activity{" "}
                      {lastRefresh.toLocaleDateString()}
                      {" - "}
                      {lastRefresh.toLocaleTimeString()}
                    </ListGroupItemText>
                  </Col>
                </Row>
                {!isThisSession && (
                  <span
                    className="badge bg-primary rounded-pill my-auto"
                    onClick={() =>
                      logoutMutation({
                        variables: {
                          sessionId: s._id,
                        },
                      })
                    }
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Remove
                  </span>
                )}
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </Card>

      <Card className="mb-3">
        <CardHeader>Second layer</CardHeader>
        <ListGroup>
          <ListGroupItem className="d-flex justify-content-between align-items-start">
            <Row>
              <Col
                xs="1"
                className="d-flex"
                style={{
                  minWidth: "fit-content",
                }}
              >
                <BsShield size={30} className="m-auto" />
              </Col>
              <Col>
                <ListGroupItemHeading>
                  Two-factor authentication
                </ListGroupItemHeading>
                <ListGroupItemText>
                  Two-factor authentication (2FA) adds an additional layer of
                  protection beyond passwords.
                </ListGroupItemText>
              </Col>
            </Row>
            <span
              className="badge bg-primary rounded-pill my-auto"
              onClick={() => history.push("/account/two-factor")}
              style={{
                cursor: "pointer",
              }}
            >
              {user.user.twoFactor ? "Disable" : "Enable"}
            </span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-start">
            <Row>
              <Col
                xs="1"
                className="d-flex"
                style={{
                  minWidth: "fit-content",
                }}
              >
                <FaRegPaperPlane size={30} className="m-auto" />
              </Col>
              <Col>
                <ListGroupItemHeading>Telegram</ListGroupItemHeading>
                <ListGroupItemText>
                  Telegram code will be requested for every critical action in
                  you account.
                </ListGroupItemText>
              </Col>
            </Row>
            <span
              className="badge bg-primary rounded-pill my-auto"
              onClick={() => history.push("/account/telegram")}
              style={{
                cursor: "pointer",
              }}
            >
              {user.user.telegram ? "Disable" : "Enable"}
            </span>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Card>
  );
};

export default Security;
