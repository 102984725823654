import React from "react";
import { AiFillCaretDown } from "react-icons/ai";
import styled from "styled-components";
import { useTrade } from "../../../hooks/useTrade";
import { ORDER_SIDE } from "../../../typings/Trade";

const PanelTitle = styled.div`
  height: 86px;
  padding-left: 30px;
  width: 412px;
`;

interface IButton {
  active?: boolean;
  side: "ask" | "bid";
}

const SideButton = styled.button<IButton>`
  border: none;
  outline: none;
  -webkit-appearance: none;
  background: transparent;

  font-size: 40px;
  height: 54px;
  margin-top: 24px;
  line-height: 40px;
  margin-right: 60px;
  padding-bottom: 10px;
  border-bottom: 4px solid transparent;
  font-weight: 700;
  float: left;

  color: ${(props) =>
    props.side !== ORDER_SIDE.ASK
      ? (props) => props.theme.bid
      : props.theme.ask};

  border-bottom-color: ${(props) => (props.active ? "currentColor" : "none")};
`;

const SelectBox = styled.div`
  margin-top: 12px;
  position: relative;
  float: right;
  /* margin-right: 30px; */
  white-space: nowrap;
`;

const Select = styled.select`
  border: none;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  color: rgba(31, 63, 89, 0.5);
  font-size: 28px;
  line-height: 28px;
  padding-right: 30px;
  width: 100%;
  padding: 20px 30px 20px 0;
  display: inline-block;
  vertical-align: middle;
`;

const SelectOption = styled.option`
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
`;

const TradePanel: React.FC = () => {
  const ctx = useTrade();

  return (
    <>
      <PanelTitle>
        <SideButton
          active={ctx.op !== ORDER_SIDE.ASK}
          side="bid"
          onClick={() => ctx.setOp("bid")}
        >
          Buy
        </SideButton>
        <SideButton
          active={ctx.op === ORDER_SIDE.ASK}
          side="ask"
          onClick={() => ctx.setOp("ask")}
          style={{
            marginRight: 0,
          }}
        >
          Sell
        </SideButton>
        <SelectBox>
          <Select>
            <SelectOption>Limit</SelectOption>
          </Select>
          <AiFillCaretDown
            color="rgba(31, 63, 89, 0.5)"
            style={{
              left: "-20px",
              //   top: "-4px",
              position: "relative",
            }}
          />
        </SelectBox>
      </PanelTitle>
    </>
  );
};

export default TradePanel;
