import { useQuery } from "@apollo/client";
import React from "react";
import { Badge, Card } from "reactstrap";
import { GET_NUMER_OF_USERS } from "../../graphql/admin";

const Panel: React.FC = () => {
  const { data: numberOfUsersData } =
    useQuery<{ numberOfUsers: number }>(GET_NUMER_OF_USERS);

  return (
    <Card body className="border-0">
      <h4>Panel</h4>
      <hr />

      <h6>
        Registered Users{" "}
        <Badge className="bg-success">{numberOfUsersData?.numberOfUsers}</Badge>
      </h6>
    </Card>
  );
};

export default Panel;
