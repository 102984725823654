import { useMutation } from "@apollo/client";
import cogoToast from "cogo-toast";
import React, { useRef } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { EDIT_CURRENCY_MUTATION } from "../../../graphql/admin";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import Input from "../../Form/Input";
import * as Yup from "yup";
import { IEnginePair } from "../../../pages/admin/Pairs";
import { editCurrencySchema } from "../../../schemas/admin";
import { AiFillSetting } from "react-icons/ai";
import { ICurrency } from "../../../typings/App";
import { useInfoModal } from "../../Common/InfoModal";
import ModalButton from "../../Common/ModalButton";

const EditCurrencyModal: React.FC<{
  refetch: CallableFunction;
  currency: ICurrency;
}> = ({ refetch, currency }) => {
  const formRef = useRef<FormHandles>(null);
  const { isOpen, toggle } = useInfoModal();

  const [edit] = useMutation(EDIT_CURRENCY_MUTATION, {
    onCompleted: () => {
      cogoToast.success("success");
      toggle();
      refetch();
    },
    onError: (err) => console.error(err),
  });

  const handleSubmit: SubmitHandler<IEnginePair> = async (data) => {
    formRef.current?.setErrors({});
    console.log(currency);
    try {
      const formData = await editCurrencySchema.validate(
        { ...currency, ...data },
        {
          abortEarly: false,
          stripUnknown: true,
        }
      );

      edit({
        variables: formData,
      });
    } catch (err) {
      console.log(err);
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        console.log(err);
        cogoToast.error(err.message);
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      }
    }
  };

  return (
    <>
      <ModalButton
        title="Edit Currency"
        isOpen={isOpen}
        toggle={toggle}
        button={<AiFillSetting onClick={() => toggle()} />}
      >
        <Form ref={formRef} onSubmit={handleSubmit} initialData={currency}>
          <FormGroup>
            <Label for="_id">Id</Label>
            <Input type="text" id="_id" name="_id" disabled />
          </FormGroup>
          <FormGroup>
            <Label for="symbol">Symbol</Label>
            <Input
              type="text"
              id="symbol"
              name="symbol"
              placeholder="Ex.: ADA"
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="Ex.: Cardano"
            />
          </FormGroup>
          {/* <FormGroup>
              <Label for="withdrawalFee">Withdrawal Fee</Label>
              <Input
                type="number"
                id="withdrawalFee"
                name="withdrawalFee"
                placeholder="1"
                step={0.00000001}
                min={0}
                max={10000}
              />
            </FormGroup>
            <FormGroup>
              <Label for="depositFee">Deposit Fee</Label>
              <Input
                type="number"
                id="depositFee"
                name="depositFee"
                placeholder="Ex.: 1"
                step={0.00000001}
                min={0}
                max={10000}
              />
            </FormGroup>
            <FormGroup>
              <Label for="withdrawalFeePercent">Withdrawal Fee Percent</Label>
              <Input
                type="number"
                id="withdrawalFeePercent"
                name="withdrawalFeePercent"
                placeholder="Ex.: 10"
                step={0.00000001}
                min={0}
                max={10000}
              />
            </FormGroup>
            <FormGroup>
              <Label for="depositFeePercent">Deposit Fee Percent</Label>
              <Input
                type="number"
                id="depositFeePercent"
                name="depositFeePercent"
                placeholder="Ex.: 0.1"
                step={0.00000001}
                min={0}
                max={10000}
              />
            </FormGroup>
            <FormGroup>
              <Label for="minDeposit">Minimum Deposit Amount</Label>
              <Input
                type="number"
                id="minDeposit"
                name="minDeposit"
                placeholder="Ex.: 0.1"
                step={0.00000001}
                min={0}
                max={10000}
              />
            </FormGroup>
            <FormGroup>
              <Label for="minWithdrawal">Minimum Withdrawal Amount</Label>
              <Input
                type="number"
                id="minWithdrawal"
                name="minWithdrawal"
                placeholder="Ex.: 0.1"
                step={0.00000001}
                min={0}
                max={10000}
              />
            </FormGroup> */}
          <Button type="submit" color="success" block>
            Save
          </Button>
        </Form>
      </ModalButton>
    </>
  );
};

export default EditCurrencyModal;
