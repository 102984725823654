class Coinsamba {
  get isDev() {
    // return false;
    return process.env.NODE_ENV === "development";
  }

  get apiUrl() {
    if (this.isDev) return "http://localhost:3001/graphql";
    return "https://cex-api-staging.coinsamba.com/graphql";
  }

  get wsUrl() {
    if (this.isDev) return "ws://localhost:3001/graphql";
    return "wss://cex-api-staging.coinsamba.com/graphql";
  }
}

const instance = new Coinsamba();
export { instance as Coinsamba };
