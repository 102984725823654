import gql from "graphql-tag";

export const NETWORK_FIELDS = gql`
  fragment NetworkFragment on ONetwork {
    name
    depositEnabled
    withdrawalEnabled
    nativeDecimalPlaces
    depositFee
    depositFeePercent
    withdrawalFee
    withdrawalFeePercent
    minDeposit
    minWithdrawal
  }
`;

export const BALANCE_FIELDS = gql`
  fragment BalanceFragment on OBalance {
    name
    symbol
    available
    onHold
  }
`;

export const GET_CURRENCIES = gql`
  query {
    getCurrencies {
      _id
      type
      networks {
        ...NetworkFragment
      }
      symbol
      name
      decimalPlaces
    }
  }
  ${NETWORK_FIELDS}
`;

export const GET_BALANCES = gql`
  query {
    getBalances {
      ...BalanceFragment
    }
  }
  ${BALANCE_FIELDS}
`;

export const GET_ADDRESS = gql`
  query ($symbol: String!) {
    getAddress(symbol: $symbol) {
      address
      label
    }
  }
`;

export const DEV_CREDIT_BALANCE = gql`
  mutation DevCreditBalanceMutation($symbol: String!) {
    devCreditBalance(symbol: $symbol)
  }
`;

export const GET_DEPOSITS_WITHDRAWALS = gql`
  query ($offset: Float!, $limit: Float!, $type: String!, $symbol: String!) {
    getDepositWithdrawal(
      offset: $offset
      limit: $limit
      type: $type
      symbol: $symbol
    ) {
      entries {
        _id
        type
        amount
        symbol
        address
        network
        txid
        createdAt
      }
      totalPages
    }
  }
`;

export const GET_LEDGER = gql`
  query ($offset: Float!, $limit: Float!) {
    getLedger(offset: $offset, limit: $limit) {
      entries {
        _id
        description
        credit
        debit
        account
        action
        createdAt
      }
      totalPages
    }
  }
`;

export const GENERATE_API_KEY = gql`
  mutation GenerateAPIKeyMutation(
    $label: String!
    $permissions: [String!]!
    $code: String!
  ) {
    generateAPIKey(label: $label, permissions: $permissions, code: $code)
  }
`;

export const ENABLE_TWOFACTOR = gql`
  query {
    enableTwoFA {
      secret
      uri
      qr
    }
  }
`;

export const CONFIRM_ENABLE_TWOFACTOR = gql`
  mutation ConfirmTwoFactorMutation($secret: String!, $code: String!) {
    confirmEnableTwoFA(secret: $secret, code: $code)
  }
`;

export const DISABLE_TWOFACTOR = gql`
  mutation DisableTwoFactorMutation($code: String!) {
    disableTwoFA(code: $code)
  }
`;

export const DISABLE_TELEGRAM = gql`
  mutation DisableTelegramMutation($code: String!) {
    disableTelegram(code: $code)
  }
`;

export const ENABLE_TELEGRAM = gql`
  mutation EnableTelegram($code: String!) {
    enableTelegram(code: $code) {
      id
      username
      first_name
      last_name
    }
  }
`;

export const CONFIRM_ENABLE_TELEGRAM = gql`
  mutation ConfirmEnableTelegramMutation($chatId: String!) {
    confirmEnableTelegram(chatId: $chatId)
  }
`;

export const DELETE_API_KEY = gql`
  mutation DeleteAPIKeymutation($id: String!) {
    deleteAPIKey(id: $id)
  }
`;

export const LIST_API_KEYS = gql`
  query {
    listAPIKeys {
      _id
      label
      permissions
    }
  }
`;

export const LIST_SESSIONS = gql`
  query {
    listSessions {
      _id
      agent
      ip
      when
      lastRefresh
    }
  }
`;

export const REQUEST_TELEGRAM_CODE = gql`
  mutation requestTelegramCode($resource: String!) {
    requestTelegramCode(resource: $resource)
  }
`;
