import { useMutation } from "@apollo/client";
import cogoToast from "cogo-toast";
import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { DISABLE_TWOFACTOR } from "../../graphql/account";
import { useAuth } from "../../hooks/useAuth";

const DisableTwoFactor: React.FC = () => {
  const { logout } = useAuth();

  const [code, setCode] = useState("");

  const handleTwoFactorChange = (value: string) => {
    setCode(value.replace(/\D/g, ""));
  };

  const handleSubmit = () => {
    enable({
      variables: {
        code,
      },
    });
  };

  const [enable] = useMutation(DISABLE_TWOFACTOR, {
    onCompleted: () => {
      alert("Two Factor Disabled, you need to log in again");
      logout();
    },
    onError: (err) => cogoToast.error(err.message),
  });

  return (
    <>
      <h1>Disable Two Factor</h1>

      <Form>
        <FormGroup className="mb-3">
          <Label for="twoFactorInput">Device Generated Code</Label>
          <Input
            type="text"
            maxLength={6}
            pattern="[0-9]{6}"
            value={code}
            onChange={({ target }) => handleTwoFactorChange(target.value)}
          />
        </FormGroup>
        <div className="d-grid gap-2">
          <Button
            color="primary"
            block
            onClick={handleSubmit}
            disabled={code.length !== 6}
          >
            Confirm Disable Two Factor
          </Button>
        </div>
      </Form>
    </>
  );
};

export default DisableTwoFactor;
