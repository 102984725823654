import React, { useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { RouteProps } from "react-router";
import { useAuth } from "../hooks/useAuth";
import Home from "../pages/public/Home";
import Trade from "../pages/public/Trade";

import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import Terms from "../pages/information/Terms";
import Privacy from "../pages/information/Privacy";
import Fees from "../pages/information/Fees";

import LostPassword from "../pages/auth/LostPassword";
import MainScreen from "../components/Trade/Mobile/MainScreen";
import AccountRoutes from "./AccountRoutes";
import AdminRoutes from "./AdminRoutes";
import RiskAndCompliance from "../pages/information/RiskAndCompliance";
import ResetPassword from "../pages/auth/ResetPassword";
import TokenAccessRoutes from "./TokenAccessRoutes";

interface CustomRouteProps extends RouteProps {
  children: React.ReactNode;
}

function PrivateRoute({ children, ...rest }: CustomRouteProps) {
  const { user, loaded } = useAuth();

  if (!loaded) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          <>{children}</>
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function AdminRoute({ children, ...rest }: CustomRouteProps) {
  const { user, loaded } = useAuth();

  if (!loaded) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user?.roles.includes("admin") ? (
          <>{children}</>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function OnlyGuestRoute({ children, ...rest }: CustomRouteProps) {
  const { user, loaded } = useAuth();

  if (!loaded) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !user ? (
          <>{children}</>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>

        <Route exact path="/fees">
          <Fees />
        </Route>
        <Route exact path="/trade/:pair">
          <Trade />
        </Route>
        <Route exact path="/mobile/trade/:pair">
          <MainScreen />
        </Route>
        <OnlyGuestRoute exact path="/auth/login">
          <Login />
        </OnlyGuestRoute>
        <OnlyGuestRoute exact path="/auth/lost">
          <LostPassword />
        </OnlyGuestRoute>
        <OnlyGuestRoute exact path="/auth/reset-password">
          <ResetPassword />
        </OnlyGuestRoute>
        <OnlyGuestRoute exact path="/auth/register">
          <Register />
        </OnlyGuestRoute>
        <Route exact path="/information/cookies">
          <Home />
        </Route>
        <Route exact path="/information/fees">
          <Home />
        </Route>
        <Route exact path="/information/terms">
          <Terms />
        </Route>
        <Route exact path="/information/privacy">
          <Privacy />
        </Route>
        <Route exact path="/information/risk-and-compliance-disclosure">
          <RiskAndCompliance />
        </Route>
        <PrivateRoute path="/account">
          <AccountRoutes />
        </PrivateRoute>
        <AdminRoute path="/admin">
          <AdminRoutes />
        </AdminRoute>
        <Route path="/token-access">
          <TokenAccessRoutes />
        </Route>
        <Route exact path="/*">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
