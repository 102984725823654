import { Form } from "@unform/web";
import React, { useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Alert, Button, Card, FormText, Label } from "reactstrap";
import Input from "../../components/Form/Input";
import Template from "../../components/Common/Template";
import { resetPasswordSchema } from "../../schemas/auth";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { RESETPASSWORD_MUTATION } from "../../graphql/auth";
import { FormHandles, SubmitHandler } from "@unform/core";
import cogoToast from "cogo-toast";

interface IFormData {
  token: string;
  password: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword: React.FC = () => {
  const [error, setError] = useState("");
  const history = useHistory();
  const token: string | undefined = useQuery().get("token");

  const [resetPasswd] = useMutation<{
    reset: {
      token: string;
      password: string;
    };
  }>(RESETPASSWORD_MUTATION, {
    onCompleted: () => {
      cogoToast.success("Password changed with sucessful");
      history.push("/auth/login");
    },
    onError: () => {
      setError("Network error");
    },
  });

  const formRef = useRef<FormHandles>(null);
  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    try {
      console.log(data)
      const FormData = await resetPasswordSchema.validate(data);
      resetPasswd({
        variables: FormData,
      });
    } catch (err) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        cogoToast.error(err.message);
        /* setError(err.message); */
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      }
    }
  };

  return (
    <>
      <Template>
        <main>
          <div className="d-flex justify-content-center">
            <div className="form-access my-auto">
              <Card
                body
                className="border-0 shadow mt-3"
                style={{
                  maxWidth: "321px",
                }}
              >
                <h4 className="text-center">Reset Password</h4>
                <p
                  className="text-center"
                  style={{
                    fontSize: "13px",
                    color: "rgb(112, 122, 138)",
                  }}
                >
                  Enter with your new password
                </p>
                <Form ref={formRef} onSubmit={onSubmit}>
                  <div className="form-group">
                    <Label>Token</Label>
                    <Input
                      type="token"
                      name="token"
                      placeholder="Token"
                      value={token}
                    />
                  </div>
                  <div className="form-group">
                    <Label>New password</Label>
                    <Input
                      type="password"
                      name="password"
                      className="form-control fb-input-text"
                      placeholder="New password"
                    />
                    {/* TODO: Deve fazer a verificação de senha forte e confirmação de senha*/}
                  </div>
                  <div className="form-group">
                    <Label>Confirm new password</Label>
                    <Input
                      type="password"
                      name="passwordConfirmation"
                      className="form-control fb-input-text"
                      placeholder="Confirm new password"
                    />
                  </div>
                  <Button
                    type="submit"
                    className="mt-3"
                    color="primary"
                    data-cy="login"
                  >
                    Reset password
                  </Button>
                </Form>
                {error && (
                  <Alert className="mt-3" color="danger">
                    {error}
                  </Alert>
                )}

                <FormText className="mt-3">
                  Don't have an account?{" "}
                  <Link to="/auth/register">Register here</Link>
                </FormText>
                <FormText>
                  Did you remember your password?{" "}
                  <Link to="/auth/login">Login</Link>
                </FormText>
              </Card>
            </div>
          </div>
        </main>
      </Template>
    </>
  );
};

export default ResetPassword;
