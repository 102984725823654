import { createContext, useContext } from "react";
import { IEnginePair } from "../pages/admin/Pairs";
import { ORDER_SIDE } from "../typings/Trade";

export interface ITradeContext {
  toggleLeftPanel: CallableFunction;
  leftPanelHidden: boolean;
  base: string;
  quote: string;
  pair: string;
  setPrice: CallableFunction;
  price: number;
  rules: Partial<IEnginePair>;
  op: ORDER_SIDE;
  setOp: CallableFunction;
}

export const TradeContext = createContext({} as ITradeContext);

export const useTrade = () => {
  const ctx = useContext(TradeContext);

  return ctx;
};
