import React from "react";
import styled from "styled-components";
import RightPanelBottom from "./RightPanelBottom";
import RightPanelTop from "./RightPanelTop";

const RightPanelContainer = styled.div`
  width: 615px;
  flex: 0 0 auto;
  margin: 5px 0 0;
  display: flex;
  flex-direction: column;
`;

const RightPanel: React.FC = () => {
  return (
    <RightPanelContainer>
      <RightPanelTop />
      <RightPanelBottom />
    </RightPanelContainer>
  );
};

export default RightPanel;
