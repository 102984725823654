import cogoToast from "cogo-toast";
import React from "react";
import { IoCopyOutline } from "react-icons/io5";
import { UncontrolledTooltip } from "reactstrap";
import sendToClipboard from "../../utils/clipboard";

const Copy: React.FC<{ size?: number; toCopy: any }> = ({ size, toCopy }) => {
  const handleClick = () => {
    sendToClipboard(toCopy);
    cogoToast.success(`Copiado ${toCopy}`);
  };

  return (
    <>
      <IoCopyOutline
        size={size}
        style={{
          cursor: "pointer",
        }}
        id="tooltipcopy"
        onClick={handleClick}
      />
      <UncontrolledTooltip target="tooltipcopy">Copiar</UncontrolledTooltip>
    </>
  );
};

export default Copy;
