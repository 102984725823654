import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { IActivableComponent } from "./LeftPanel";
import { TiChartAreaOutline } from "react-icons/ti";
import { init, dispose, Chart } from "klinecharts";
import { useWindowSize } from "@coinsamba/react-tiny-hooks";
import { useQuery } from "@apollo/client";
import { IKline, parseAPIData } from "../../../utils/generatedKLineDataList";
import { GET_KLINES, GET_LAST_KLINE } from "../../../graphql/trade";
import chartOptions from "../../../utils/chartOptions";
import { useTrade } from "../../../hooks/useTrade";

const MiddleMiddleContainer = styled.div`
  flex: 1 1 auto;
  position: relative;
  display: flex;
`;

const KLineWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const KLineHeader = styled.div`
  height: 40px;
  background-color: ${(props) => props.theme.backgroundColor2};
  border-bottom: 1px solid ${(props) => props.theme.divider};
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const KLineHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const KLineActions = styled.div`
  display: flex;
  align-items: center;
`;

const KLineInterval = styled.div<IActivableComponent>`
  padding: 6px 4px;
  color: ${(props) => (props.active ? props.theme.primary : "inherit")};
  font-size: 12px;
  transform: all 0.3s;
  cursor: pointer;

  :hover {
    color: ${(props) => props.theme.primary};
  }
`;

const KLineIndicatorsSymbol = styled.div`
  // none
`;

const KLineIndicatorsInterval = styled.div`
  position: relative;
`;

const KLineIndicatorsArrow = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ced1e4;
  border-radius: 2px;
  cursor: pointer;
`;

const KLineIndicatorsBox = styled.div`
  position: absolute;
  transform: translate(-50%);
  left: 8px;
  z-index: 99999999;
  width: 230px;
  background: ${(props) => props.theme.backgroundColor2};
  border: 1px solid ${(props) => props.theme.divider};
  border-radius: 4px;
  padding: 8px;
`;

const KLineIndicatorBoxHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  height: 22px;
`;

const KLineIndicatorBoxItems = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const KLineIndicatorBoxItem = styled.div<IActivableComponent>`
  color: ${(props) => (props.active ? props.theme.primary : "inherit")};
  width: 44px;
  height: 21px;
  border: 1px solid
    ${(props) => (props.active ? props.theme.primary : props.theme.divider)};
  background: ${(props) => props.theme.backgroundColor2};
  border-radius: 2px;
  position: relative;
  text-align: center;
  margin: 0 9px 10px 0;
  cursor: pointer;
`;

const TVChartContainer = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  background-color: ${(props) => props.theme.backgroundColor2};
  display: flex;
  color: #000000;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: url(/main_logo.png);
    background-size: 192px 50px;
    background-position: center;
    background-repeat: no-repeat;
    filter: grayscale(100%);
    opacity: 0.2;
  }
`;

const TVChartContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

function getLanguageOptions(language) {
  return {
    candle: {
      tooltip: {
        labels:
          language === "zh-CN"
            ? ["时间", "开", "收", "高", "低", "成交量"]
            : language === "zh-HK"
            ? ["時間", "開", "收", "高", "低", "成交量"]
            : ["T", "O", "C", "H", "L", "V"],
      },
    },
  };
}

const mainTechnicalIndicatorTypes = ["MA", "EMA", "SAR"];

const MiddleMiddle: React.FC = () => {
  const { base, quote } = useTrade();
  const chart = useRef<Chart>(null);
  const [indicator, setIndicator] = useState("EMA");
  const [toolBox, setToolBox] = useState(false);
  const [frame, setFrame] = useState("1");
  const [klines] = useState([] as IKline[]);
  const [skipPolling, setSkipPolling] = useState(true);

  const windowSize = useWindowSize();

  useEffect(() => {
    if (chart.current) chart.current.resize();
  }, [windowSize.width]);

  useEffect(() => {
    chart.current = init("basic-k-line");
    if (chart.current) {
      chart.current.createTechnicalIndicator("VOL", false);
      chart.current.createTechnicalIndicator(indicator, false, {
        id: "candle_pane",
      });
      chart.current.setStyleOptions(chartOptions);
      chart.current.setStyleOptions(getLanguageOptions("en-US"));
      // chart.current.createTechnicalIndicator("MA", false, {
      //   id: "candle_pane",
      // });
      // chart.current.createTechnicalIndicator("KDJ", false, { height: 80 });
    }

    // todo
    // kLineChart?.resize
    return () => {
      dispose("basic-k-line");
    };
  }, []);

  useEffect(() => {
    console.log("skip polling", skipPolling);
  }, [skipPolling]);

  useEffect(() => {
    if (chart.current) chart.current.applyNewData(parseAPIData(klines));
  }, [chart, klines]);

  useQuery<{ getKlines: IKline[] }>(GET_KLINES, {
    fetchPolicy: "no-cache",
    variables: {
      base,
      quote,
      frame,
    },
    onCompleted: (res) => {
      chart.current.applyNewData(parseAPIData(res.getKlines));
      setSkipPolling(false);
    },
  });

  const { data } = useQuery<{ getLastKline: IKline }>(GET_LAST_KLINE, {
    skip: skipPolling,
    pollInterval: 5000,
    fetchPolicy: "network-only",
    variables: {
      base,
      quote,
      frame,
    },
  });

  useEffect(() => {
    if (data) {
      chart.current.updateData(parseAPIData([data.getLastKline])[0]);
    }
  }, [data]);

  return (
    <MiddleMiddleContainer>
      <KLineWrapper>
        <KLineHeader>
          <KLineHeaderLeft>
            <div>
              <KLineActions>
                <KLineInterval
                  onClick={() => setFrame("1")}
                  active={frame === "1"}
                >
                  <span>1min</span>
                </KLineInterval>
                <KLineInterval
                  onClick={() => setFrame("60")}
                  active={frame === "60"}
                >
                  <span>1hour</span>
                </KLineInterval>
                <KLineInterval
                  onClick={() => setFrame("1D")}
                  active={frame === "1D"}
                >
                  <span>1day</span>
                </KLineInterval>
              </KLineActions>
            </div>
            <KLineIndicatorsSymbol
              onMouseEnter={() => setToolBox(true)}
              onMouseLeave={() => setToolBox(false)}
            >
              <KLineIndicatorsInterval>
                <KLineIndicatorsArrow>
                  <TiChartAreaOutline />
                </KLineIndicatorsArrow>
                {toolBox && (
                  <KLineIndicatorsBox onMouseLeave={() => setToolBox(false)}>
                    <div>
                      <KLineIndicatorBoxHead>
                        <span>Main Indicators</span>
                      </KLineIndicatorBoxHead>
                      <KLineIndicatorBoxItems>
                        {mainTechnicalIndicatorTypes.map((t) => (
                          <KLineIndicatorBoxItem
                            active={indicator === t}
                            onClick={() => {
                              setIndicator(t);
                              chart.current.createTechnicalIndicator(t, false, {
                                id: "candle_pane",
                              });
                            }}
                          >
                            {t}
                          </KLineIndicatorBoxItem>
                        ))}
                      </KLineIndicatorBoxItems>
                    </div>
                  </KLineIndicatorsBox>
                )}
              </KLineIndicatorsInterval>
            </KLineIndicatorsSymbol>
          </KLineHeaderLeft>
        </KLineHeader>
        <TVChartContainer>
          <TVChartContent id="basic-k-line" />
        </TVChartContainer>
      </KLineWrapper>
    </MiddleMiddleContainer>
  );
};

export default MiddleMiddle;
