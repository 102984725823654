import React from "react";
import Template from "../../components/Common/Template";
import styled from "styled-components";
import LeftPanel from "../../components/Trade/Pro/LeftPanel";
import MiddlePanel from "../../components/Trade/Pro/MiddlePanel";
import RightPanel from "../../components/Trade/Pro/RightPanel";
import TradeProvider from "../../providers/TradeProvider";
import { useWindowSize } from "@coinsamba/react-tiny-hooks";

const NAVBAR_HEIGHT = 60;

const TradeContainer = styled.div`
  min-height: calc(100vh - ${NAVBAR_HEIGHT}px);
  box-sizing: border-box;
  background-color: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.text};
`;

const Layout = styled.div`
  font-size: 12px;
  display: block;
  box-sizing: border-box;
`;

const TradeBody = styled.div`
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
  display: flex;
  align-items: stretch;
  min-width: 1366px;
  position: relative;
`;

const Trade: React.FC = () => {
  const windowSize = useWindowSize();

  const smallScreen = windowSize.width < 1393;

  return (
    <TradeProvider>
      <Template nonContainer nonFooter>
        <TradeContainer>
          <Layout>
            <TradeBody>
              <LeftPanel smallScreen={smallScreen} />
              <MiddlePanel />
              <RightPanel />
            </TradeBody>
          </Layout>
        </TradeContainer>
      </Template>
    </TradeProvider>
  );
};

export default Trade;
