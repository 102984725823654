import { useMutation } from "@apollo/client";
import {
  CANCEL_BATCH_ORDER_MUTATION,
  CANCEL_ORDER_MUTATION,
} from "../graphql/trade";

const useOrder = () => {
  const [cancel] = useMutation(CANCEL_ORDER_MUTATION);
  const [batchCancel] = useMutation(CANCEL_BATCH_ORDER_MUTATION);

  return { cancel, batchCancel };
};

export default useOrder;
