import React from "react";
import { RiPlayList2Fill } from "react-icons/ri";

import styled from "styled-components";
const SwitchWrapper = styled.div`
  padding-left: 30px;
  height: 110px;
  font-size: 32px;
  font-weight: 700;
  line-height: 110px;
  position: relative;
  font-style: normal;
`;

const MobileCoinSwitch: React.FC = () => {
  return (
    <SwitchWrapper>
      <p>
        <RiPlayList2Fill size={40} />
        <span
          style={{
            verticalAlign: "unset",
          }}
        >
          BTC/CBRL
        </span>
      </p>
    </SwitchWrapper>
  );
};

export default MobileCoinSwitch;
