import { useQuery } from "@apollo/client";
import React from "react";
import { Card, Row } from "reactstrap";
import TokenEventCard from "../../components/Account/TokenEvent/TokenEventCard";
import AddTokenEventModal from "../../components/Admin/TokenEvent/AddTokenEventModal";
import { GET_MY_TOKEN_EVENTS } from "../../graphql/tokenEvent";
import { ITokenEvent } from "../../typings/TokenEvent";

const MyEvents: React.FC = () => {
  const { data } =
    useQuery<{ getMyEvents: [ITokenEvent] }>(GET_MY_TOKEN_EVENTS);

  return (
    <Card body className="border-0">
      <h4>My Events</h4>
      <hr />
      <AddTokenEventModal />
      <Row className="mt-3">
        {data?.getMyEvents.map((e) => (
          <TokenEventCard event={e} />
        ))}
      </Row>
    </Card>
  );
};

export default MyEvents;
