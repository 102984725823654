import React from "react";
import styled from "styled-components";
import { useTrade } from "../../../hooks/useTrade";
import Book from "./Book";
import LastTrades from "./LastTrades";

const RightPanelTopContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
`;

const RightUpLeft = styled.div`
  width: 320px;
  margin: 0 5px 0 0;
  position: relative;
`;

const RightUpRight = styled.div`
  width: 290px;
  position: relative;
`;

const ModuleHeader = styled.div`
  height: 35px;
  background-color: ${(props) => props.theme.backgroundColor2};
  border-bottom: 1px solid ${(props) => props.theme.divider};
  padding: 0 16px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RightPanelTop: React.FC = () => {
  const { base, quote } = useTrade();

  return (
    <RightPanelTopContainer>
      <RightUpLeft>
        <ModuleHeader />
        <Book base={base} quote={quote} />
      </RightUpLeft>
      <RightUpRight>
        <LastTrades base={base} quote={quote} />
      </RightUpRight>
    </RightPanelTopContainer>
  );
};

export default RightPanelTop;
