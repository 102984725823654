import React from "react";
import { Card, Table, UncontrolledTooltip } from "reactstrap";
import { BiLeftDownArrowCircle, BiRightTopArrowCircle } from "react-icons/bi";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import useBalances from "../../hooks/useBalances";
import { useHistory } from "react-router-dom";

const Balances: React.FC = () => {
  const { balances } = useBalances();

  const history = useHistory();

  return (
    <Card body className="border-0">
      <h4>Balances</h4>
      <hr />

      <Table className="text-center" size="sm" striped responsive>
        <thead>
          <tr>
            <th>Simbolo</th>
            <th>Nome</th>
            <th>Available</th>
            <th>On Hold</th>
            <th>Pending Deposit</th>
            <th>Pending Withdraw</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {balances.map((b) => (
            <tr>
              <td>{b.symbol}</td>
              <td>{b.name}</td>
              <td>{b.available}</td>
              <td>{b.onHold}</td>
              <td>0</td>
              <td>0</td>
              <td>
                <UncontrolledTooltip
                  placement="right"
                  target={`withdraw${b.symbol}`}
                >
                  Sacar {b.symbol}
                </UncontrolledTooltip>
                <UncontrolledTooltip
                  placement="right"
                  target={`trade${b.symbol}`}
                >
                  Negociar {b.symbol}
                </UncontrolledTooltip>
                <UncontrolledTooltip
                  placement="right"
                  target={`deposit${b.symbol}`}
                >
                  Deposit {b.symbol}
                </UncontrolledTooltip>
                <BiLeftDownArrowCircle
                  size={20}
                  id={`deposit${b.symbol}`}
                  onClick={() =>
                    history.push(`/account/deposits?c=${b.symbol}`)
                  }
                />
                <BiRightTopArrowCircle size={20} id={`withdraw${b.symbol}`} />{" "}
                <CgArrowsExchangeAlt size={20} id={`trade${b.symbol}`} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default Balances;
