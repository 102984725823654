import React from "react";
import styled from "styled-components";
import { ITelegramChat } from "../../typings/Account";

const ChatList = styled.div`
  /* margin: 0; */
  /* flex-direction: column; */
  /* position: relative; */
  /* width: 100%; */
  /* background-color: #fff; */
  display: block;
  width: 200px;
  margin-inline: auto;
  margin-bottom: 10px;
`;

const TelegramChatWrapper = styled.div`
  height: 72px;
  border-radius: 10px;
  display: flex;
  /* align-items: flex-start; */
  /* flex-direction: row; */
  cursor: pointer;
  padding: 0.5625rem;
  overflow: hidden;
  background-color: #474747;
  padding-top: 5px;
`;

const TelegramAvatarElement = styled.div`
  pointer-events: none;
  position: relative;

  flex: 0 0 auto;

  --size: 54px;
  --multiplier: 1;
  --color-top: #72d5fd;
  --color-bottom: #2a9ef1;
  color: #fff;
  width: var(--size);
  height: var(--size);
  line-height: var(--size) !important;
  border-radius: 50%;
  background: linear-gradient(var(--color-top), var(--color-bottom));
  text-align: center;
  font-size: calc(1.25rem / var(--multiplier));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 2px;
`;

const TelegramUserCaption = styled.div`
  pointer-events: none;
  position: relative;

  overflow: hidden;
  color: #ffffff;
  /* color: var(--secondary-text-color); */
  flex: 1 1 auto;
  padding: 0.0625rem 0.4375rem 0.0625rem 0.5625rem;
`;

const TelegramDialogTitle = styled.p`
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 27px;
`;

const TelegramUserTitle = styled.span`
  flex-grow: 1;

  display: flex !important;
  align-items: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 27px;

  font-weight: bold;
`;

const TelegramDialogSubTitle = styled.p`
  margin-top: -3px;

  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 27px;

  text-align: start;
`;

const TelegramUserLastMessage = styled.span`
  flex-grow: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 27px;
`;

const TelegramChat: React.FC<{ chat: ITelegramChat }> = ({ chat }) => {
  const initials = `${chat.first_name[0]}${
    chat.last_name ? chat.last_name[0] : ""
  }`;

  return (
    <ChatList>
      <TelegramChatWrapper>
        {/* <TelegramChatRipple> */}
        <TelegramAvatarElement>{initials}</TelegramAvatarElement>
        <TelegramUserCaption>
          <TelegramDialogTitle>
            <TelegramUserTitle>
              {chat.first_name} {chat.last_name}
            </TelegramUserTitle>
          </TelegramDialogTitle>
          <TelegramDialogSubTitle>
            <TelegramUserLastMessage>@{chat.username}</TelegramUserLastMessage>
          </TelegramDialogSubTitle>
        </TelegramUserCaption>
        {/* </TelegramChatRipple> */}
      </TelegramChatWrapper>
    </ChatList>
  );
};

export default TelegramChat;
