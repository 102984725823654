import React from "react";

const CustomSwitch: React.FC<{
  id: string;
  onChange: CallableFunction;
  checked: boolean;
  label?: string;
  style?: React.CSSProperties;
}> = ({ id, onChange, checked, label, style }) => {
  return (
    <div className="form-check form-switch" style={style}>
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        id={id}
        onChange={() => onChange()}
        checked={checked}
      />
      {label && (
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

export default CustomSwitch;
