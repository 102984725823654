import format from "date-fns/format";
import React from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";
import { ITokenEvent } from "../../../typings/TokenEvent";
import { EXPLORERS } from "../../../utils/web3/EXPLORERS";
import { GrStatusInfo } from "react-icons/gr";
import TokenEventParticipantsModal from "./TokenEventParticipantsModal";

const TokenEventCard: React.FC<{ event: ITokenEvent }> = ({ event }) => {
  return (
    <Col xs="12" md="6" lg="4" className="mb-3">
      <Card>
        <CardHeader
          style={{
            cursor: "pointer",
          }}
        >
          <h4 className="text-center">
            {event.name} <GrStatusInfo />
          </h4>
        </CardHeader>
        <CardBody>
          <Table responsive>
            <thead>
              <tr>
                <th>label</th>
                <th>value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_id</td>
                <td>
                  <a
                    href={`/token-access/generate-verification?event=${event._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {event._id}
                  </a>
                </td>
              </tr>
              <tr>
                <td>network</td>
                <td>{event.network}</td>
              </tr>
              <tr>
                <td>token</td>
                <td>
                  <a
                    href={`${EXPLORERS[event.network]}/token/${event.token}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {event.token}
                  </a>
                </td>
              </tr>
              <tr>
                <td>when</td>
                <td>{format(new Date(event.when), "dd/MM/yyyy hh:mm")}</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
        <CardBody>
          <TokenEventParticipantsModal eventId={event._id} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default TokenEventCard;
