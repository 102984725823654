import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export const useInfoModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((oldState) => !oldState);

  return { isOpen, toggle };
};

const InfoModal: React.FC<{
  isOpen: boolean;
  toggle: CallableFunction;
  title: string;
}> = ({ isOpen, toggle, title, children }) => {
  return (
    <Modal isOpen={isOpen} toggle={() => toggle()}>
      <ModalHeader toggle={() => toggle()}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => toggle()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InfoModal;
