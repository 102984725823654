import React, { useRef, useEffect } from "react";
import ReactSelect, {
  Props as SelectProps,
  OptionTypeBase,
} from "react-select";
import { useField } from "@unform/core";

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  id: string;
  disabled: boolean;
}

export default function CreatableSelect({ name, ...rest }: Props) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
      setValue: (ref: any, value: any) => {
        ref.select.setValue(value);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <ReactSelect
      defaultValue={defaultValue}
      ref={selectRef}
      classNamePrefix="react-select"
      isDisabled={!!rest.disabled}
      {...rest}
    />
  );
}
