import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalButton: React.FC<{
  title: string;
  children: React.ReactNode;
  button?: React.ReactNode;
  isOpen: boolean;
  toggle: CallableFunction;
  size?: string;
}> = ({ title, children, isOpen, toggle, button, size }) => {
  return (
    <>
      {button ? (
        button
      ) : (
        <Button color="primary" onClick={() => toggle()}>
          {title}
        </Button>
      )}

      <Modal isOpen={isOpen} toggle={() => toggle()} size={size}>
        <ModalHeader toggle={() => toggle()}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => toggle()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalButton;
