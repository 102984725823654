import { useMutation } from "@apollo/client";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import cogoToast from "cogo-toast";
import React, { useRef, useState } from "react";
import { Button, Card, FormGroup, Label } from "reactstrap";
import { GENERATE_API_KEY } from "../../graphql/account";
import { useAuth } from "../../hooks/useAuth";
import InfoModal, { useInfoModal } from "../Common/InfoModal";
import InputCopy from "../Common/InputCopy";
import ProvideTwoFactor, {
  useProvideTwoFactor,
} from "../Common/ProvideTwoFactor";
import Checkbox from "../Form/CheckBox";
import Input from "../Form/Input";

interface IFormData {
  label: string;
  trade: boolean;
  view: boolean;
}

const CreateAPIKey: React.FC<{ refetch: CallableFunction }> = ({ refetch }) => {
  const { user } = useAuth();

  const [apiKey, setApiKey] = useState("");

  const [generate] = useMutation(GENERATE_API_KEY);

  const tf = useProvideTwoFactor();
  const inf = useInfoModal();

  const formRef = useRef<FormHandles>(null);

  const onSubmit: SubmitHandler<IFormData> = () => {
    if (user?.twoFactor && tf.code.length !== 6) return tf.toggle();

    const formData = formRef.current?.getData() as IFormData;

    const { label, trade } = formData;

    const permissions = ["view"];

    if (trade) permissions.push("trade");

    generate({
      variables: {
        label,
        permissions,
        code: tf.code,
      },
    })
      .then(({ data }) => {
        setApiKey(data.generateAPIKey);
      })
      .catch((err) => {
        cogoToast.error(err.message);
      })
      .finally(() => {
        formRef.current?.reset();
        refetch();
        inf.toggle();
        tf.setCode("");
      });
  };

  return (
    <>
      <InfoModal
        isOpen={inf.isOpen}
        toggle={inf.toggle}
        title="API Key generated!"
      >
        <>
          <p>This is your api key, keep it in a secure place.</p>
          <InputCopy value={apiKey} />
        </>
      </InfoModal>
      <ProvideTwoFactor
        isOpen={tf.isOpen}
        toggle={tf.toggle}
        onSubmit={onSubmit}
        setCode={tf.setCode}
        code={tf.code}
      />
      <Card body className="mb-3 border-0">
        <h4>Generate new API Key</h4>
        <hr />
        <Form ref={formRef} onSubmit={onSubmit}>
          <FormGroup>
            <Label for="label">Name</Label>
            <Input
              name="label"
              id="label"
              type="text"
              minLength={3}
              maxLength={10}
              required
            />
            <small>This name will be used to identify your key</small>
          </FormGroup>

          <Label>Permissions</Label>
          <div className="form-check">
            <Checkbox
              className="form-check-input"
              id="view"
              name="view"
              checked
            />
            <Label className="form-check-label" for="view">
              View
            </Label>
          </div>

          <div className="form-check">
            <Checkbox
              className="form-check-input"
              id="trade"
              name="trade"
              defaultChecked={false}
            />
            <Label className="form-check-label" for="trade">
              Trade
            </Label>
          </div>

          <Button className="mt-3" block color="primary" type="submit">
            Generate
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default CreateAPIKey;
