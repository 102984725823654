import React from "react";
import styled from "styled-components";
import MiddleBottom from "./MiddleBottom";
import MiddleMiddle from "./MiddleMiddle";
import MiddleTop from "./MiddleTop";

const MiddleContainer = styled.div`
  flex: 1 1 auto;
  margin: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
`;

const MiddlePanel: React.FC = () => {
  return (
    <MiddleContainer>
      <MiddleTop />
      <MiddleMiddle />
      <MiddleBottom />
    </MiddleContainer>
  );
};

export default MiddlePanel;
