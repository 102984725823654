import { useMutation, useQuery } from "@apollo/client";
import cogoToast from "cogo-toast";
import React, { useState } from "react";
import { Card, Table } from "reactstrap";
import PairModal from "../../components/Admin/PairModal";
import Tooltip from "../../components/Common/Tooltip";
import { isAfter, subSeconds } from "date-fns";
import { GET_PAIRS, TOGGLE_PAIR_MUTATION } from "../../graphql/admin";
import CustomSwitch from "../../components/Form/CustomSwitch";
import { useInterval } from "@coinsamba/react-tiny-hooks";

export interface IPair {
  id: string;
  base: string;
  quote: string;
  enabled?: boolean;
  lastEnginePing: Date;
  maxTradableAmount: number;
  pricePrecision: number;
  amountPrecision: number;
  minTotalAmount: number;
  fee: number;
}

export interface IEnginePair extends IPair {
  basePrecision: number;
  quotePrecision: number;
}

const Pairs: React.FC = () => {
  const [dateNow, setDateNow] = useState(new Date());

  const { data, refetch } = useQuery<{
    pairs: IEnginePair[];
  }>(GET_PAIRS, {
    pollInterval: 3000,
    onError: (err) => cogoToast.error(err.message),
  });

  const [togglePair] = useMutation(TOGGLE_PAIR_MUTATION, {
    onCompleted: (res) => {
      cogoToast.success(JSON.stringify(res));
      console.log(res);
      refetch();
    },
    onError: (err) => cogoToast.error(err.message),
  });

  useInterval(() => setDateNow(new Date()), 500);

  return (
    <Card body className="border-0">
      <PairModal refetch={refetch} />
      <hr />
      <Table className="text-center" size="sm" hover stripped="true">
        <thead>
          <tr>
            <td>Base</td>
            <td>Quote</td>
            <td>Online</td>
            <td>Enabled</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {data?.pairs.map((p) => (
            <tr>
              <td>{p.base}</td>
              <td>{p.quote}</td>
              <td
                style={{
                  color: isAfter(
                    new Date(p.lastEnginePing),
                    subSeconds(dateNow, 4)
                  )
                    ? "green"
                    : "red",
                  cursor: "default",
                }}
              >
                <Tooltip
                  id={`tooltip${p.base}${p.quote}`}
                  text={p.lastEnginePing}
                >
                  ⬤
                </Tooltip>
              </td>
              <td>
                <CustomSwitch
                  id={`switch${p.base}${p.quote}`}
                  onChange={() =>
                    togglePair({
                      variables: {
                        id: p.id,
                        enabled: !p.enabled,
                      },
                    })
                  }
                  checked={p.enabled}
                />
              </td>
              <td>
                <PairModal pair={p} refetch={refetch} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default Pairs;
