import { useMutation } from "@apollo/client";
import { FormHandles, SubmitHandler } from "@unform/core/typings/types";
import cogoToast from "cogo-toast";
import React, { useRef } from "react";
import {
  ADD_CURRENCY_NETWORK_MUTATION,
  EDIT_CURRENCY_NETWORK_MUTATION,
} from "../../../graphql/admin";
import {
  addCurrencyNetworkSchema,
  editCurrencyNetworkSchema,
} from "../../../schemas/admin";
import { useInfoModal } from "../../Common/InfoModal";
import ModalButton from "../../Common/ModalButton";
import * as Yup from "yup";
import { Button, FormGroup, Label } from "reactstrap";
import Input from "../../Form/Input";
import { Form } from "@unform/web";
import CustomSelect from "../../Form/CustomSelect";
import { ICurrency, INetwork } from "../../../typings/App";
import { ASSET_NETWORK } from "../../../typings/assets";
import { AiFillSetting } from "react-icons/ai";

const AddOrEditCurrencyNetworkModal: React.FC<{
  currency: ICurrency;
  refetch: CallableFunction;
  network?: INetwork;
}> = ({ currency, refetch, network }) => {
  const isEdit = !!network;

  const formRef = useRef<FormHandles>(null);
  const { isOpen, toggle } = useInfoModal();

  const [edit] = useMutation(
    isEdit ? EDIT_CURRENCY_NETWORK_MUTATION : ADD_CURRENCY_NETWORK_MUTATION,
    {
      onCompleted: () => {
        cogoToast.success("success");
        toggle();
        refetch();
      },
      onError: (err) => console.error(err),
    }
  );

  const handleSubmit: SubmitHandler = async (data) => {
    formRef.current?.setErrors({});

    if (isEdit) {
      data.name = network.name;
    }

    try {
      const formData = await (isEdit
        ? editCurrencyNetworkSchema
        : addCurrencyNetworkSchema
      ).validate(data, {
        abortEarly: false,
        stripUnknown: true,
      });

      edit({
        variables: { _id: currency._id, network: formData },
      });
    } catch (err) {
      console.log(err);
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        console.log(err);
        cogoToast.error(err.message);
        err.inner.forEach((error) => {
          console.log(error.message);
          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      }
    }
  };

  return (
    <ModalButton
      title={isEdit ? "Edit Network" : "Add Network"}
      isOpen={isOpen}
      toggle={toggle}
      button={isEdit && <AiFillSetting onClick={() => toggle()} />}
    >
      <Form ref={formRef} onSubmit={handleSubmit} initialData={network}>
        <FormGroup>
          <Label for="name">Network Name</Label>
          <CustomSelect
            options={Object.values(ASSET_NETWORK).map((network) => ({
              label: network,
              value: network,
            }))}
            id="name"
            name="name"
            placeholder="Ex.: bsc"
            disabled={isEdit}
            isMulti={false}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="withdrawalFee">Withdrawal Fee</Label>
          <Input
            type="number"
            id="withdrawalFee"
            name="withdrawalFee"
            placeholder="Ex.: 1"
            step={0.00000001}
            min={0}
            max={10000}
            required
          />
          <small>In {currency.symbol}</small>
        </FormGroup>
        <FormGroup>
          <Label for="depositFee">Deposit Fee</Label>
          <Input
            type="number"
            id="depositFee"
            name="depositFee"
            placeholder="Ex.: 1"
            step={0.00000001}
            min={0}
            max={10000}
            required
          />
          <small>In {currency.symbol}</small>
        </FormGroup>
        <FormGroup>
          <Label for="withdrawalFeePercent">Withdrawal Fee Percent</Label>
          <Input
            type="number"
            id="withdrawalFeePercent"
            name="withdrawalFeePercent"
            placeholder="Ex.: 10"
            step={0.00000001}
            min={0}
            max={10000}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="depositFeePercent">Deposit Fee Percent</Label>
          <Input
            type="number"
            id="depositFeePercent"
            name="depositFeePercent"
            placeholder="Ex.: 0.1"
            step={0.00000001}
            min={0}
            max={10000}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="minDeposit">Minimum Deposit Amount</Label>
          <Input
            type="number"
            id="minDeposit"
            name="minDeposit"
            placeholder="Ex.: 0.1"
            step={0.00000001}
            min={0}
            max={10000}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="minWithdrawal">Minimum Withdrawal Amount</Label>
          <Input
            type="number"
            id="minWithdrawal"
            name="minWithdrawal"
            placeholder="Ex.: 0.1"
            step={0.00000001}
            min={0}
            max={10000}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="nativeDecimalPlaces">
            Native Decimal Places (Precision)
          </Label>
          <Input
            type="number"
            id="nativeDecimalPlaces"
            name="nativeDecimalPlaces"
            placeholder="Ex.: 2"
            step={1}
            min={0}
            max={10000}
            required
            disabled={isEdit}
          />
        </FormGroup>
        <Button type="submit" color="success" block>
          Save
        </Button>
      </Form>
    </ModalButton>
  );
};

export default AddOrEditCurrencyNetworkModal;
