import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/main.scss";
import "./styles/styckyFooter.scss";
import "./i18n";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  split,
  concat,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { getMainDefinition } from "@apollo/client/utilities";
import { Storage } from "./services/Storage";
import { Coinsamba } from "./services/Coinsamba";
import { WebSocketLink } from "@apollo/client/link/ws";
import { TOKEN_KEY } from "./providers/AuthProvider";
import { setContext } from "@apollo/client/link/context";
import { Web3ReactProvider } from "@web3-react/core";
import getLibrary from "./utils/web3/getLibrary";
// import { create as fingerPrint } from "seed-fingerprint";

// const fingerprint = fingerPrint(BRANDING.coinsamba.id).result();

const batchHttpLink = new BatchHttpLink({
  uri: Coinsamba.apiUrl,
  batchMax: 8, // No more than 5 operations per batch
  batchInterval: 50, // Wait no more than 20ms after first batched operation
  batchDebounce: true,
  fetchOptions: {
    mode: "cors",
  },
});

const wsLink = new WebSocketLink({
  uri: Coinsamba.wsUrl,
  options: {
    reconnect: true,
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Storage.get(TOKEN_KEY);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const wsAndHttpLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  batchHttpLink
);

const client = new ApolloClient({
  link: concat(authLink, wsAndHttpLink),
  cache: new InMemoryCache(),
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <App />
      </Web3ReactProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
