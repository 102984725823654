import React from "react";
import styled from "styled-components";
import Slider from "./Slider";
import herobg from "../../images/herobg.jpg";

const HeroWrapper = styled.div`
  background-image: url(${herobg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
`;

const Container = styled.div`
  max-width: 1240px !important;
  width: 100% !important;
  margin: 0 auto !important;
  padding: 0 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;

const HeroTitle = styled.h1`
  font-size: 30px;
  margin-top: 57px;
  letter-spacing: 0.025em;
  color: #fff;
  text-align: center;
`;

const Hero: React.FC = () => {
  return (
    <HeroWrapper>
      <Container>
        <HeroTitle>Fast and Secure Digital Assets Exchange Platform</HeroTitle>

        <Slider />
      </Container>
    </HeroWrapper>
  );
};

export default Hero;
