import { useQuery } from "@apollo/client";
import format from "date-fns/format";
import React from "react";
import { Card, Table } from "reactstrap";
import AddTokenEventModal from "../../components/Admin/TokenEvent/AddTokenEventModal";
import { GET_ALL_TOKEN_EVENTS } from "../../graphql/tokenEvent";
import { ITokenEvent } from "../../typings/TokenEvent";

const TokenEvent: React.FC = () => {
  const { data } =
    useQuery<{ getAllEvents: [ITokenEvent] }>(GET_ALL_TOKEN_EVENTS);
  console.log(data);
  return (
    <Card body className="border-0">
      <h4>Token Event</h4>
      <hr />
      <AddTokenEventModal />
      <Table>
        <thead>
          <tr>
            <th>_id</th>
            <th>name</th>
            <th>when</th>
            <th>network</th>
          </tr>
        </thead>
        <tbody>
          {data?.getAllEvents?.map((e) => (
            <tr>
              <td>
                <a
                  href={`/token-access/generate-verification?event=${e._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {e._id}
                </a>
              </td>
              <td>{e.name}</td>
              <td>{format(new Date(e.when), "dd/MM/yyyy hh:mm")}</td>
              <td>{e.network}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default TokenEvent;
