import React from "react";
import { Switch, Route } from "react-router-dom";
import AccountTemplate from "../components/Account/AccountTemplate";
import Profile from "../pages/account/Profile";
import Balances from "../pages/account/Balances";
import Ledger from "../pages/account/Ledger";
import TwoFactor from "../pages/account/TwoFactor";
import Deposits from "../pages/account/Deposits";
import API from "../pages/account/API";
import Template from "../components/Common/Template";
import ChangePassword from "../pages/account/ChangePassword";
import Security from "../pages/account/Security";
import Telegram from "../pages/account/Telegram";
import MyEvents from "../pages/account/MyEvents";

const AccountRoutes: React.FC = () => {
  return (
    <Template nonfluid>
      <AccountTemplate>
        <Switch>
          <Route exact path="/account/profile">
            <Profile />
          </Route>
          <Route exact path="/account/balances">
            <Balances />
          </Route>
          <Route exact path="/account/deposits">
            <Deposits />
          </Route>
          <Route exact path="/account/withdrawals">
            <Deposits />
          </Route>
          <Route exact path="/account/ledger">
            <Ledger />
          </Route>
          <Route exact path="/account/two-factor">
            <TwoFactor />
          </Route>
          <Route exact path="/account/telegram">
            <Telegram />
          </Route>
          <Route exact path="/account/change-password">
            <ChangePassword />
          </Route>
          <Route exact path="/account/security">
            <Security />
          </Route>
          <Route exact path="/account/api">
            <API />
          </Route>
          <Route exact path={"/account/my-token-events"}>
            <MyEvents />
          </Route>
        </Switch>
      </AccountTemplate>
    </Template>
  );
};

export default AccountRoutes;
