import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string().email().lowercase().required(),
  password: Yup.string().required(),
});

export const registerSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().lowercase().required(),
  password: Yup.string().required().min(8, "Password must be longer than 8 characters"),
});

export const changePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required(),
  newPassword: Yup.string().required().min(8, "Password must be longer than 8 characters"),
  newPasswordConfirmation: Yup.mixed().test(
    "match",
    "Passwords do not match", // your error message
    function () {
      return this.parent.newPassword === this.parent.newPasswordConfirmation;
    }
  ),
});

export const lostPasswordSchema = Yup.object().shape({
  email: Yup.string().email().lowercase().required(),
});

export const resetPasswordSchema = Yup.object().shape({
  token: Yup.string().required(),
  password: Yup.string().required().min(8, "Password must be longer than 8 characters"),
  passwordConfirmation: Yup.mixed().test(
    "match",
    "Passwords do not match", // your error message
    function () {
      return this.parent.password === this.parent.passwordConfirmation;
    }
  ),
});
