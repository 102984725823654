import React from "react";
import styled from "styled-components";
import { IoIosSearch, IoIosStarOutline } from "react-icons/io";
import { TiArrowUnsorted } from "react-icons/ti";
import { useHistory } from "react-router";
import { useState } from "react";
import useSummary from "../../../hooks/useSummary";
import { useTrade } from "../../../hooks/useTrade";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useClickOutside } from "../../../hooks/useClickOutside";

interface IHide {
  hide: boolean;
}

const LeftContainer = styled.div<IHide>`
  width: 290px;
  flex: 0 0 auto;
  margin: ${(props) => (props.hide ? "none" : "5px 5px 0 0")};

  display: block;

  box-sizing: border-box;

  position: ${(props) => (props.hide ? "absolute" : "relative")};

  z-index: ${(props) => (props.hide ? 150 : "inherit")};

  background-color: ${(props) => props.theme.backgroundColor2};

  box-shadow: ${(props) => (props.hide ? "2px 2px 8px" : "none")};

  height: ${(props) => (props.hide ? "100%" : "none")};
`;

const WalletBalance = styled.div`
  background-color: ${(props) => props.theme.backgroundColor2};
  /* border-bottom: 1px solid ${(props) => props.theme.divider}; */
  height: 50px;
  padding: 20px 20px;

  display: flex;

  justify-content: flex-end;
`;

// const WalletHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   position: relative;
// `;

const SearchWrapper = styled.div`
  height: 48px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const SearchAffixWrapper = styled.div`
  width: calc(100% - 20px);
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: ${(props) => props.theme.inputBackground};
  border-color: ${(props) => props.theme.inputBorderColor};

  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 6px 11px;
  font-size: 14px;
  line-height: 1.5715;
  background-image: none;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
`;

const SearchInputPreffix = styled.span`
  margin-right: 4px;
  display: flex;
  flex: none;
  align-items: center;
`;

const SearchInput = styled.input`
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  font-size: 14px;
  background-image: none;
  border-radius: 2px;
  transition: all 0.3s;
`;

const TradeTabs = styled.div`
  background-color: ${(props) => props.theme.backgroundColor2};
  border-bottom: 1px solid ${(props) => props.theme.divider};
  display: flex;
  align-items: center;
`;

export interface IActivableComponent {
  active?: boolean;
}

const TradeTabsTab = styled.div<IActivableComponent>`
  height: 36px;
  line-height: 36px;
  flex: 1 1;
  border-right: 1px solid ${(props) => props.theme.backgroundColor2};
  // color: ${(props) => props.theme.secondary};
  padding: 0 4px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;

  background-color: ${(props) => (props.active ? props.theme.primary : "none")};
  color: ${(props) => (!props.active ? "inherit" : "white")};
`;

const TableHead = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  text-align: right;
  padding: 0 10px;
  /* color: #3d3d56; */
  cursor: pointer;
`;

const TableHeadCol1 = styled.div`
  width: 54%;
  text-align: left;
`;

const TableHeadCol2 = styled.div`
  width: 30%;
`;

const TableHeadCol3 = styled.div`
  width: 30%;
`;

const TableBody = styled.div`
  // height: calc(100vh - 225px);
  height: calc(100vh - 240px);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${(props) => props.theme.backgroundColor2};
`;

const TableRow = styled.div<IActivableComponent>`
  display: flex;
  align-items: center;
  height: 30px;
  text-align: right;
  padding: 0 10px;
  cursor: pointer;

  box-shadow: ${(props) =>
    props.active ? `inset 3px 0 0 0 ${props.theme.primary}` : "none"};
  background-color: ${(props) =>
    props.active ? "${props=> props.theme.backgroundColor2}" : "inherit"};
`;

interface IPercentageAverage {
  isTrendingUp: boolean;
}

const PercentagemAverage = styled.span<IPercentageAverage>`
  color: ${(props) =>
    props.isTrendingUp ? (props) => props.theme.bid : props.theme.ask};
  font-weight: 500;
`;

const tabs = ["FAV", "CBRL", "BUSD", "ALL"];

const LeftPanel: React.FC<{ smallScreen: boolean }> = ({ smallScreen }) => {
  const clickRef = React.useRef();
  const ctx = useTrade();
  const { summary } = useSummary();
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("CBRL");

  const canShowMenu = !smallScreen || ctx.leftPanelHidden;

  useClickOutside(clickRef, () => {
    if (canShowMenu) ctx.toggleLeftPanel();
  });

  const history = useHistory();

  return canShowMenu ? (
    <LeftContainer hide={smallScreen} ref={clickRef}>
      <WalletBalance onClick={() => ctx.toggleLeftPanel()}>
        {/* <WalletHeader>
          <span>
            <span>Total assets</span>
            <IoIosEye
              style={{
                marginLeft: "4px",
                cursor: "pointer",
              }}
            />
          </span>
        </WalletHeader> */}
        {/* <div>
          <span>0.00000000 BTC</span>
          <span> ≈ 0.00 USD</span>
        </div> */}
        {smallScreen && (
          <AiOutlineArrowLeft
            size={20}
            style={{
              cursor: "pointer",
            }}
          />
        )}
      </WalletBalance>
      <SearchWrapper>
        <SearchAffixWrapper>
          <SearchInputPreffix>
            <IoIosSearch />
          </SearchInputPreffix>
          <SearchInput
            type="text"
            size={1}
            placeholder="Search pair"
            onChange={({ target }) => setSearch(target.value.toUpperCase())}
          />
        </SearchAffixWrapper>

        {/* <BsGear
          style={{
            marginLeft: "10px",
          }}
        /> */}
      </SearchWrapper>
      <TradeTabs>
        {tabs.map((t) => (
          <TradeTabsTab
            onClick={() => setActiveTab(t)}
            active={t === activeTab}
          >
            {t === "FAV" ? <IoIosStarOutline /> : t}
          </TradeTabsTab>
        ))}
      </TradeTabs>
      <TableHead>
        <TableHeadCol1>
          <span>Pair</span>
        </TableHeadCol1>
        <TableHeadCol2>
          <span>
            <span>Price</span>
            <TiArrowUnsorted />
          </span>
        </TableHeadCol2>
        <TableHeadCol3>
          <span>
            <span>Change</span>
            <TiArrowUnsorted />
          </span>
        </TableHeadCol3>
      </TableHead>
      <TableBody>
        {(summary.summary || []).map((p) => {
          const isSearched = p.base.includes(search);
          // todo get this from cookies
          const favorites = [];

          const show =
            (p.quote === activeTab && isSearched) ||
            favorites.includes(`${p.base}-${p.quote}`) ||
            activeTab === "ALL";

          return show ? (
            <TableRow
              active={ctx.pair === `${p.base}-${p.quote}`}
              onClick={() => history.push(`/trade/${p.base}-${p.quote}`)}
            >
              <TableHeadCol1>
                <span>
                  <IoIosStarOutline />
                </span>
                <span
                  style={{
                    marginLeft: "6px",
                  }}
                >
                  {p.base}/{p.quote}
                </span>
              </TableHeadCol1>
              <TableHeadCol2>
                <span>
                  <span>{p.last}</span>
                </span>
              </TableHeadCol2>
              <TableHeadCol3>
                <span>
                  <PercentagemAverage isTrendingUp={p.change >= 0}>
                    {p.change.toFixed(2)}%
                  </PercentagemAverage>
                </span>
              </TableHeadCol3>
            </TableRow>
          ) : null;
        })}
      </TableBody>
    </LeftContainer>
  ) : null;
};

export default LeftPanel;
