export interface IBranding {
  host: string;
  name: string;
  id: string;
}

export const BRANDING = {
  coinsamba: {
    host: "testnet.coinsamba.com",
    name: "Coinsamba",
    id: "coinsamba",
    telegram_bot: "coinsambatestnet_bot",
  },
  biscoint: {
    host: "testnet.coinsamba.com",
    name: "Biscoint",
    id: "biscoint",
    telegram_bot: "coinsambatestnet_bot",
  },
};
