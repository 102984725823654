import { Form } from "@unform/web";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Card, FormText, Label } from "reactstrap";
import Input from "../../components/Form/Input";
import Template from "../../components/Common/Template";
import { lostPasswordSchema } from "../../schemas/auth";
import { useMutation } from "@apollo/client";
import { LOSTPASSWORD_MUTATION } from "../../graphql/auth";
import { FormHandles, SubmitHandler } from "@unform/core";
import cogoToast from "cogo-toast";

interface IFormData {
  email: string;
}

const LostPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [message, setMessage] = useState("");

  const [lostPasswd] = useMutation<{
    reset: {
      email: string;
    };
  }>(LOSTPASSWORD_MUTATION, {
    onCompleted: () => {
      setMessage(
        "If this email is registered in our database, we will send an email to reset the password."
      );
    },
    onError: () => {
      cogoToast.error("Network error, try again later");
    },
  });

  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    try {
      const FormData = await lostPasswordSchema.validate(data);
      lostPasswd({
        variables: FormData,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Template>
        <main>
          <div className="d-flex justify-content-center">
            <div className="form-access my-auto">
              <Card
                body
                className="border-0 shadow mt-3"
                style={{
                  maxWidth: "321px",
                }}
              >
                <h4 className="text-center">Reset Login Password</h4>
                <p
                  className="text-center"
                  style={{
                    fontSize: "13px",
                    color: "rgb(112, 122, 138)",
                  }}
                >
                  Enter your email and we will email you a link to reset your
                  password.
                </p>
                <Form ref={formRef} onSubmit={onSubmit}>
                  <div className="form-group">
                    <Label>Email</Label>
                    <Input
                      type="email"
                      name="email"
                      className="form-control fb-input-text"
                      placeholder="Email Address"
                    />
                  </div>
                  <Button
                    type="submit"
                    className="mt-3"
                    color="primary"
                    data-cy="resetPassword"
                  >
                    Reset password
                  </Button>
                </Form>
                {message && (
                  <Alert className="mt-3" color="success">
                    {message}
                  </Alert>
                )}

                <FormText className="mt-3">
                  {"Não tem uma conta?"}{" "}
                  <Link to="/auth/register">{"Registre aqui"}</Link>
                </FormText>
                <FormText>
                  {"Lembrou sua senha?"}{" "}
                  <Link to="/auth/login">{"Faça login"}</Link>
                </FormText>
              </Card>
            </div>
          </div>
        </main>
      </Template>
    </>
  );
};

export default LostPassword;
