import { format } from "date-fns";
import React from "react";
import { RiFileChartLine } from "react-icons/ri";

import styled from "styled-components";
import useOrder from "../../../hooks/useOrder";
import { IOrder } from "../../../typings/Trade";

export const OrdersContent = styled.div`
  background-color: ${(props) => props.theme.backgroundColor2};
  font-size: 12px;
  position: relative;
`;

export const OrdersTableHeader = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.divider};
  /* // color: ${(props) => props.theme.secondary}; */

  display: flex;
  align-items: center;
  padding: 0 0 0 16px;
  height: 30px;
  transition: all 0.15s ease-in;
  background-color: ${(props) => props.theme.backgroundColor2};
  width: 100%;
  position: relative;
`;

export const OrdersTableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 16px;
`;

export const OrdersTableCell = styled.div`
  height: 100%;
  line-height: 30px;
`;

export const OrdersTableBody = styled.div`
  height: 215px;
  overflow-y: auto;
`;

export const OrdersTableEmpty = styled.div`
  text-align: center;
  padding: 32px 0;
  // color: ${(props) => props.theme.secondary};
`;

export const OrderCell = styled.div`
  text-align: right;
  height: 100%;
  line-height: 30px;
`;

export const ActionCell = styled.span`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 100%;
  // color: ${(props) => props.theme.secondary};
  padding: 0 20px;
  :hover {
    color: ${(props) => props.theme.bid};
  }
`;

const CancelCell = styled.span`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 100%;
  // color: ${(props) => props.theme.secondary};
  padding: 0 20px;
  :hover {
    color: #df384e;
  }
`;

const ActiveOrders: React.FC<{ orders: IOrder[]; refetch: CallableFunction }> =
  ({ orders, refetch }) => {
    const { cancel } = useOrder();

    return (
      <OrdersContent>
        <OrdersTableHeader>
          <OrdersTableCell
            style={{
              width: "20%",
              textAlign: "left",
            }}
          >
            Time
          </OrdersTableCell>

          <OrdersTableCell
            style={{
              width: "10%",
              textAlign: "left",
            }}
          >
            Pair
          </OrdersTableCell>

          <OrdersTableCell
            style={{
              width: "6%",
              whiteSpace: "nowrap",
            }}
          >
            Side
          </OrdersTableCell>

          <OrdersTableCell
            style={{
              width: "18%",
              whiteSpace: "nowrap",
            }}
          >
            Price
          </OrdersTableCell>

          <OrdersTableCell
            style={{
              width: "21%",
              whiteSpace: "nowrap",
            }}
          >
            Filled Amount
          </OrdersTableCell>

          <OrdersTableCell
            style={{
              width: "16%",
            }}
          >
            Amount
          </OrdersTableCell>

          <OrdersTableCell
            style={{
              width: "10%",
              paddingRight: "20px",
            }}
          >
            Action
          </OrdersTableCell>
        </OrdersTableHeader>
        <OrdersTableBody>
          {!orders.length && (
            <OrdersTableEmpty>
              <RiFileChartLine size={48} />
              <div>No data</div>
            </OrdersTableEmpty>
          )}
          {orders.map((o) => (
            <OrdersTableRow>
              <OrdersTableCell
                style={{
                  width: "20%",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                }}
              >
                {format(new Date(o.createdAt), "dd/MM/yyyy hh:mm")}
              </OrdersTableCell>
              <OrdersTableCell
                style={{
                  width: "10%",
                  textAlign: "left",
                }}
              >
                {o.base}/{o.quote}
              </OrdersTableCell>
              <OrdersTableCell
                style={{
                  width: "6%",
                  whiteSpace: "nowrap",
                }}
              >
                {o.side}
              </OrdersTableCell>
              <OrdersTableCell
                style={{
                  width: "18%",
                  whiteSpace: "nowrap",
                }}
              >
                {o.price} {o.quote}
              </OrdersTableCell>
              <OrdersTableCell
                style={{
                  width: "21%",
                  whiteSpace: "nowrap",
                }}
              >
                {o.filledAmount} {o.base}
              </OrdersTableCell>
              <OrdersTableCell
                style={{
                  width: "16%",
                }}
              >
                {o.originalAmount} {o.base}
              </OrdersTableCell>
              <OrdersTableCell
                style={{
                  width: "10%",
                  paddingRight: "20px",
                }}
              >
                <CancelCell
                  onClick={() =>
                    cancel({
                      variables: {
                        orderId: o._id,
                      },
                    }).then(() => refetch())
                  }
                >
                  Cancel
                </CancelCell>
              </OrdersTableCell>
            </OrdersTableRow>
          ))}
        </OrdersTableBody>
      </OrdersContent>
    );
  };

export default ActiveOrders;
