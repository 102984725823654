import React from "react";
import { Link } from "react-router-dom";
import logo_text from "../../images/logo_text.png";

const Footer: React.FC = () => {
  return (
    <footer
      className="bd-footer py-5 mt-5 bg-dark text-white"
      style={{
        backgroundColor: "#151617",
      }}
    >
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-3 mb-3">
            <Link to="/">
              <img src={logo_text} alt="Logo" height="50px" />
            </Link>
            <ul className="list-unstyled small text-muted">
              <li className="mb-2">
                Fast and Secure Digital Assets Exchange Platform
              </li>
            </ul>
          </div>
          <div className="col-6 col-lg-2 offset-lg-1 mb-3">
            <h5>Information</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <Link to="/information/privacy">Privacy Policy</Link>
              </li>
              <li className="mb-2">
                <Link to="/information/terms">Terms of Service</Link>
              </li>
              <li className="mb-2">
                <Link to="/information/risk-and-compliance-disclosure">
                  Risk and Compliance Disclosure
                </Link>
              </li>
              {/* <li className="mb-2">
                <a href="/docs/5.0/examples/">API terms</a>
              </li> */}
            </ul>
          </div>
          {/* <div className="col-6 col-lg-2 mb-3">
            <h5>Guides</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <a href="/docs/5.0/getting-started/">Getting started</a>
              </li>
              <li className="mb-2">
                <a href="/docs/5.0/examples/starter-template/">
                  Starter template
                </a>
              </li>
              <li className="mb-2">
                <a href="/docs/5.0/getting-started/webpack/">Webpack</a>
              </li>
              <li className="mb-2">
                <a href="/docs/5.0/getting-started/parcel/">Parcel</a>
              </li>
            </ul>
          </div> */}
          {/* <div className="col-6 col-lg-2 mb-3">
            <h5>Community</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <a href="https://github.com/twbs/bootstrap/issues">Issues</a>
              </li>
              <li className="mb-2">
                <a href="https://github.com/twbs/bootstrap/discussions">
                  Discussions
                </a>
              </li>
              <li className="mb-2">
                <a href="https://github.com/sponsors/twbs">
                  Corporate sponsors
                </a>
              </li>
              <li className="mb-2">
                <a href="https://opencollective.com/bootstrap">
                  Open Collective
                </a>
              </li>
              <li className="mb-2">
                <a href="https://bootstrap-slack.herokuapp.com/">Slack</a>
              </li>
              <li className="mb-2">
                <a href="https://stackoverflow.com/questions/tagged/bootstrap-5">
                  Stack Overflow
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
