import React from "react";
import NavBar from "./Navbar";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";
import Footer from "./Footer";

const Template: React.FC<{
  children: React.ReactElement | React.ReactElement[];
  title?: string;
  style?: React.CSSProperties | undefined;
  nonfluid?: boolean;
  nonFooter?: boolean;
  nonContainer?: boolean;
}> = ({ children, title, style, nonfluid, nonFooter, nonContainer }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <NavBar />
      {!nonContainer ? (
        <Container fluid={!nonfluid} style={style}>
          {children}
        </Container>
      ) : (
        children
      )}

      {!nonFooter && <Footer />}
    </>
  );
};

export default Template;
