import { useContext } from "react";

import { AppContext } from "../providers/AppProvider";

const useSummary = () => {
  const { summary } = useContext(AppContext);

  const byBaseQuote = (symbol: string, quote: string, isPrev = false) => {
    return ((isPrev ? summary.prevSummary : summary.summary) || []).find(
      (s) => s.base === symbol && s.quote === quote
    );
  };

  return {
    summary,
    byBaseQuote,
  };
};

export default useSummary;
