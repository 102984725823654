import { useMutation } from "@apollo/client";
import cogoToast from "cogo-toast";
import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { CONFIRM_ENABLE_TWOFACTOR } from "../../graphql/account";
import { useAuth } from "../../hooks/useAuth";
import { ITwoFactor } from "../../pages/account/TwoFactor";
import Copy from "../Common/Copy";
import QRCode from "react-qr-code";

const EnableTwoFactor: React.FC<{ twoFactor: ITwoFactor }> = ({
  twoFactor,
}) => {
  const { logout } = useAuth();

  const [code, setCode] = useState("");

  const handleTwoFactorChange = (value: string) => {
    setCode(value);
  };

  const handleSubmit = () => {
    enable({
      variables: {
        secret: twoFactor.secret,
        code,
      },
    });
  };

  const [enable] = useMutation(CONFIRM_ENABLE_TWOFACTOR, {
    onCompleted: () => {
      alert("Two Factor Enabled, you need to log in again");
      logout();
    },
    onError: (err) => cogoToast.error(err.message),
  });

  return (
    <>
      <h1>Enable Two Factor</h1>
      <div className="mx-auto p-3">
        <QRCode value={twoFactor.uri} size={150} />
      </div>

      <p>
        {twoFactor.secret} <Copy toCopy={twoFactor.secret} />
      </p>

      <Form>
        <FormGroup className="mb-3">
          <Label for="twoFactorInput">Device Generated Code</Label>
          <Input
            type="text"
            maxLength={6}
            pattern="[0-9]{6}"
            value={code}
            onChange={({ target }) => handleTwoFactorChange(target.value)}
          />
        </FormGroup>
        <div className="d-grid gap-2">
          <Button color="primary" onClick={handleSubmit}>
            Enable Two Factor
          </Button>
        </div>
      </Form>
    </>
  );
};

export default EnableTwoFactor;
