import React from "react";
import { Switch, Route } from "react-router-dom";
import AccountTemplate from "../components/Admin/AdminTemplate";
import Template from "../components/Common/Template";
import Currencies from "../pages/admin/Currencies";
import Pairs from "../pages/admin/Pairs";
import Panel from "../pages/admin/Panel";
import TokenEvent from "../pages/admin/TokenEvent";
import Users from "../pages/admin/Users";

const AdminRoutes: React.FC = () => {
  return (
    <Template nonfluid>
      <AccountTemplate>
        <Switch>
          <Route exact path="/admin/panel">
            <Panel />
          </Route>
          <Route exact path="/admin/currencies">
            <Currencies />
          </Route>
          <Route exact path="/admin/pairs">
            <Pairs />
          </Route>
          <Route exact path="/admin/users">
            <Users />
          </Route>
          <Route exact path="/admin/token-event">
            <TokenEvent />
          </Route>
        </Switch>
      </AccountTemplate>
    </Template>
  );
};

export default AdminRoutes;
