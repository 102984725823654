export interface IKline {
  open: number;
  high: number;
  low: number;
  close: number;
  vol: number;
  openTime: Date;
}

export interface ChartKLine {
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
  timestamp: number;
  turnover: number;
}

export const convertToChart = ({
  open,
  high,
  low,
  close,
  vol,
  openTime,
}: IKline): ChartKLine => ({
  open,
  high,
  low,
  close,
  volume: vol,
  timestamp: new Date(openTime).getTime(),
  turnover: ((open + close + high + low) / 4) * vol,
});

export const parseAPIData = (data: IKline[]): ChartKLine[] =>
  data.map((e) => convertToChart(e));
