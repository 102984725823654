export enum ORDER_SIDE {
  ASK = "ask",
  BID = "bid",
}

export interface IOrderbook {
  asks: IOrder[];
  bids: IOrder[];
}

export interface IOrder {
  _id: string;
  status: "open" | "filled" | "canceled";
  base: string;
  quote: string;
  side: ORDER_SIDE;
  price: number;
  averagePrice: number;
  originalAmount: number;
  filledAmount?: number;
  remainingAmount: number;
  fee: number;
  createdAt: string;
}

export interface IOrderBookRow {
  side?: string;
  percent: number;
}

export interface IMobileMenuProps {
  active?: boolean;
}
