import { useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";
export interface IUser {
  _id: string,
  email: string;
  name: string;
  roles: string[];
  twoFactor: boolean;
  telegram: boolean;
  createdAt: Date;
}

export const useAuth = () => {
  const ctx = useContext(AuthContext);

  return ctx;
};
