import { DefaultTheme } from "styled-components";

const defaultColors = {
  ask: "#df384e",
  bid: "#2ebc84",
};

export const COINSAMBA_COLORS = {
  primary: "#f48120",
  secondary: "#fff2e7",
};

export const BISCOINT_COLORS = {
  primary: "#f3c011",
  secondary: "#fff2e7",
};

export const DARK: DefaultTheme = {
  ...defaultColors,
  text: "#959da4",
  body: "#f8f9fa",
  title: "#fff",
  backgroundColor: "#151617",
  backgroundColor2: "#1a1b1c",
  divider: "#1e1f20",
  inputBackground: "#2c2d2e",
  inputBorderColor: "#2c2d2e",
  sliderColor: "#333435",
};

export const LIGHT: DefaultTheme = {
  ...defaultColors,
  text: "#555b63",
  body: "#f8f9fa",
  title: "#151617",
  backgroundColor: "#f5f7fa",
  backgroundColor2: "#fff",
  divider: "#f4f5f7",
  inputBackground: "#edeff1",
  inputBorderColor: "#edeff1",
  sliderColor: "#dee2e6",
};
