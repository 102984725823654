import React from "react";
import { useQuery } from "@apollo/client";
import { createContext } from "react";
import { ITicker } from "../components/Home/Summary";
import { GET_SUMMARY } from "../graphql/trade";
import cogoToast from "cogo-toast";
import { GET_CURRENCIES } from "../graphql/account";
import { ICurrency } from "../typings/App";
import { IBranding } from "../branding";

export interface IAppContext {
  branding: IBranding;
  darkMode: boolean;
  toggleTheme: CallableFunction;
  summary: {
    prevSummary: ITicker[];
    summary: ITicker[];
  };
  currencies: { refetch: CallableFunction; currencies: ICurrency[] };
}

export const AppContext = createContext({} as IAppContext);

const AppProvider: React.FC<{
  children: React.ReactNode;
  toggleTheme: CallableFunction;
  darkMode: boolean;
  branding: IBranding;
}> = ({ children, toggleTheme, darkMode, branding }) => {
  const { data: summaryData, previousData: previousDataSummaryData } =
    useQuery<{ getSummary: ITicker[] }>(GET_SUMMARY, {
      pollInterval: 3000,
      onError: (err) => cogoToast.error(err.message),
    });

  const { data, refetch } = useQuery<{ getCurrencies: ICurrency[] }>(
    GET_CURRENCIES,
    {
      pollInterval: 3000,
      onError: (err) => cogoToast.error(err.message),
    }
  );

  return (
    <AppContext.Provider
      value={{
        branding,
        darkMode,
        toggleTheme,
        summary: {
          summary: summaryData?.getSummary || [],
          prevSummary: previousDataSummaryData?.getSummary || [],
        },
        currencies: { refetch, currencies: data?.getCurrencies || [] },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
