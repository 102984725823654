import React from "react";
import { AiFillLock } from "react-icons/ai";
import styled from "styled-components";
import { useApp } from "../../hooks/appHooks";

// import { Container } from './styles';

const Wrapper = styled.div`
  box-sizing: border-box;
  min-width: 0px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid rgb(245, 245, 245);
  border-radius: 23px;
  display: inline-flex;
  margin: 0px auto 8px;
  width: auto;
  padding: 8px 24px;
  -webkit-box-align: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  line-height: 18px;
  height: 18px;
`;

const Icon = styled.div`
  box-sizing: border-box;
  margin: 0px 4px 0px 0px;
  min-width: 0px;
  color: rgb(14, 203, 129);
  font-size: 12px;
  fill: rgb(14, 203, 129);
  width: 1em;
  height: 1em;
`;

const TextWrapper = styled.div`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;

  span {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: rgb(14, 203, 129);
  }
`;

const CorrectURL: React.FC = () => {
  const ctx = useApp();
  return (
    <Wrapper>
      <IconWrapper>
        <Icon>
          <AiFillLock />
        </Icon>
      </IconWrapper>
      <TextWrapper>
        <span>https://</span>
        {ctx.branding.host}
      </TextWrapper>
    </Wrapper>
  );
};

export default CorrectURL;
