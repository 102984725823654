import React, { useState, createContext, useLayoutEffect, useRef } from "react";
import jwtDecode from "jwt-decode";
import { Storage } from "../services/Storage";
import { useHistory } from "react-router-dom";
import { IUser } from "../hooks/useAuth";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  REFRESH_TOKEN_MUTATION,
  LOGOUT_MUTATION,
  REFETCH_USER_QUERY,
} from "../graphql/auth";
import cogoToast from "cogo-toast";

export const TOKEN_KEY = "token-cex";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const SESSION_ID_KEY = "sessionId";

export interface IAuthContext {
  user?: IUser;
  // setUser: (val: any) => void;
  login: (token: string, refreshToken: string, sessionId: string) => void;
  logout: () => void;
  loaded: boolean;
  refetch: () => void;
}

const getStoredToken = async (refresh: CallableFunction) => {
  const token: string = Storage.get(TOKEN_KEY);
  const parsedToken: IUser & { exp: number } = token ? jwtDecode(token) : null;

  let refreshToken = Storage.get(REFRESH_TOKEN_KEY) as string;

  const doRefresh = parsedToken && parsedToken.exp < Date.now() / 1000 - 60;

  // console.log(`token exp ${parsedToken?.exp} / now date ${Date.now()}`);
  // console.log(`jwt is valid ${!doRefresh}`);
  // console.log(`do refresh ${doRefresh}`);

  let user = doRefresh ? null : parsedToken;

  if (doRefresh && refreshToken) {
    // Storage.unset(TOKEN_KEY);
    // Storage.unset(REFRESH_TOKEN_KEY);
    try {
      const {
        data: { refreshToken: data },
      } = await refresh({
        variables: {
          token,
          refreshToken,
        },
      });

      // console.log("data returned", data, typeof data);
      // console.log("refreshToken", data.refreshToken);
      // console.log("jwt", data.token);

      user = jwtDecode(data.token);
      refreshToken = data.refreshToken;
      Storage.set(TOKEN_KEY, data.token);
      Storage.set(REFRESH_TOKEN_KEY, data.refreshToken);
    } catch (error) {
      // refresh token is invalid, destroy him
      Storage.unset(TOKEN_KEY);
      Storage.unset(REFRESH_TOKEN_KEY);
      cogoToast.info("session expired");
      refreshToken = null;
      console.error(error);
    }
  }

  return {
    doRefresh,
    user,
    refreshToken,
  };
};

export const AuthContext = createContext({} as IAuthContext);

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const fetchLock = useRef(false);

  const [loaded, setLoaded] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState<IUser>(null);

  const [refetchUserQuery] = useLazyQuery<{ me: IUser }>(REFETCH_USER_QUERY, {
    onCompleted: (res) => {
      if (res.me) {
        setUser(res.me);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    refetchWritePolicy: "overwrite",
  });

  const [refreshTokenMutation] = useMutation<{
    token: string;
    refreshToken: string;
  }>(REFRESH_TOKEN_MUTATION);

  const [logoutMutation] = useMutation(LOGOUT_MUTATION);

  useLayoutEffect(() => {
    const withLock = async (cb: () => Promise<any>) => {
      if (!fetchLock.current) {
        fetchLock.current = true;

        await cb();

        fetchLock.current = false;
      }
    };

    withLock(() =>
      getStoredToken(refreshTokenMutation)
        .then(({ user }) => {
          setUser(user);
        })
        .finally(() => {
          setLoaded(true);
        })
    );

    const refreshInterval = setInterval(async () => {
      withLock(() =>
        getStoredToken(refreshTokenMutation).then(({ user, doRefresh }) => {
          if (doRefresh) setUser(user);
        })
      );
    }, 500);

    return () => (refreshInterval ? clearInterval(refreshInterval) : null);
  }, []);

  // send mutation to server to invalidate this token
  const logout = () => {
    const sessionId = Storage.get(SESSION_ID_KEY);

    Storage.unset(TOKEN_KEY);
    Storage.unset(REFRESH_TOKEN_KEY);
    Storage.unset(SESSION_ID_KEY);
    setUser(null);
    cogoToast.success("successfully logout");
    history?.push("/");
    logoutMutation({
      variables: {
        sessionId,
      },
    }).catch((e) => console.error(e));
  };

  const login = (token: string, refreshToken: string, sessionId: string) => {
    Storage.set(TOKEN_KEY, token);
    Storage.set(SESSION_ID_KEY, sessionId);
    Storage.set(REFRESH_TOKEN_KEY, refreshToken);

    setUser(jwtDecode(token));
    history?.push("/");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        loaded,
        refetch: refetchUserQuery,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
