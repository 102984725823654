import { useMutation } from "@apollo/client";
import cogoToast from "cogo-toast";
import React from "react";
import { Button, Form } from "reactstrap";
import { DISABLE_TELEGRAM } from "../../graphql/account";
import { useAuth } from "../../hooks/useAuth";
import TelegramCodeInput, {
  useTelegramCodeInput,
} from "../Common/TelegramCodeInput";

const DisableTelegram: React.FC = () => {
  const { refetch } = useAuth();

  const tg = useTelegramCodeInput();

  const [disable] = useMutation(DISABLE_TELEGRAM, {
    onCompleted: () => {
      cogoToast.success("telegram disabled");
      refetch();
    },
    onError: (err) => cogoToast.error(err.message),
  });

  const handleSubmit = () => {
    disable({
      variables: {
        code: tg.code,
      },
    });
  };

  return (
    <>
      <h1>Disable Telegram</h1>
      <p>
        To disable Telegram in your account, we'll request a code that will be
        sent to your phone
      </p>
      <Form
        style={{
          maxWidth: "350px",
          marginInline: "auto",
        }}
      >
        <TelegramCodeInput
          resource="disableTelegram"
          code={tg.code}
          setCode={tg.setCode}
        />
        <div className="d-grid gap-2">
          <Button
            color="primary"
            block
            onClick={handleSubmit}
            disabled={!tg.isValid}
          >
            Confirm Disable Two Factor
          </Button>
        </div>
      </Form>
    </>
  );
};

export default DisableTelegram;
