import { useQuery } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { GET_PUBLIC_STATISTICS } from "../../graphql/admin";

const Wrapper = styled.section`
  margin-top: 20px;
  margin-bottom: 20px;

  background-color: #eff0f2;
  padding: 48px 0 45px;
`;

const Container = styled.div`
  max-width: 1240px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`;

const Item = styled.div`
  text-align: center;

  p {
    font-weight: 500;
    color: ${(props) => props.theme.primary};
    font-size: 30px;
  }

  span {
    display: block;
    text-transform: uppercase;
    font-size: 15px;
    color: #7d7f88;
    margin-top: 22px;
  }
`;

const StatisticsSection: React.FC = () => {
  const { data } = useQuery<{
    publicStatistics: {
      users: number;
      currencies: number;
      pairs: number;
    };
  }>(GET_PUBLIC_STATISTICS);

  return (
    <Wrapper>
      <Container>
        <Item>
          <p>{data?.publicStatistics?.users}</p>
          <span>Active Users</span>
        </Item>
        <Item>
          <p>{data?.publicStatistics?.currencies}</p>
          <span>Assets</span>
        </Item>
        <Item>
          <p>{data?.publicStatistics?.pairs}</p>
          <span>Pairs</span>
        </Item>
      </Container>
    </Wrapper>
  );
};

export default StatisticsSection;
