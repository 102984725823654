import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { GET_TRADING_RULES } from "../graphql/trade";
import { TradeContext } from "../hooks/useTrade";
import { IEnginePair } from "../pages/admin/Pairs";
import { ORDER_SIDE } from "../typings/Trade";

const TradeProvider: React.FC = ({ children }) => {
  const [leftPanelHidden, setLeftPanelHidden] = useState(false);
  const { params } = useRouteMatch() as { params: { pair: string } };
  const [price, setPrice] = useState(0);
  const { pair } = params;
  const [base, quote] = pair.split("-");
  const [op, setOp] = useState<ORDER_SIDE>(ORDER_SIDE.BID);

  const { data } = useQuery<{
    getPairTradingRules: IEnginePair;
  }>(GET_TRADING_RULES, {
    variables: {
      base,
      quote,
    },
  });

  const toggleLeftPanel = () => setLeftPanelHidden((oldState) => !oldState);

  return (
    <TradeContext.Provider
      value={{
        toggleLeftPanel,
        leftPanelHidden,
        pair,
        base,
        quote,
        price,
        setPrice,
        rules: data?.getPairTradingRules || {},
        op,
        setOp,
      }}
    >
      {children}
    </TradeContext.Provider>
  );
};

export default TradeProvider;
