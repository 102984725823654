import React from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import styled from "styled-components";
import useSummary from "../../../hooks/useSummary";
import { useTrade } from "../../../hooks/useTrade";

// import { Container } from './styles';

const BookHeader = styled.div`
  height: 50px;
  font-size: 24px;
  color: #c5cfd5;
  margin-right: 30px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
`;

const BookSide = styled.div`
  height: 240px;
`;

const BookRow = styled.dl`
  height: 48px;
  line-height: 48px;
  font-size: 24px;
  overflow: hidden;
`;

const BookRowPrice = styled.dd`
  color: #8c9fad;
  margin-right: 30px;
  float: left;
`;

const BookRowAmount = styled.dd`
  color: #8c9fad;
  margin-right: 30px;
  float: right;
`;

const CurrentPriceWrapper = styled.div`
  height: 86px;
  box-sizing: border-box;
  padding-top: 12px;
  /* font-size: 0; */
`;

const NowPrice = styled.em`
  height: 30px;
  line-height: 30px;
  font-size: 30px;
  font-weight: 700;
  float: left;
  white-space: nowrap;
  font-style: normal;
  /* display: inline-block; */
  vertical-align: middle;
  margin-right: 20px;
`;

const Change = styled.b`
  line-height: 30px;
  vertical-align: baseline;
  float: left;
`;

const Book: React.FC = () => {
  const ctx = useTrade();
  const summary = useSummary();

  const lastTicker = summary.byBaseQuote(ctx.base, ctx.quote);
  const prevTicker = summary.byBaseQuote(ctx.base, ctx.quote, true);

  const isTrendingUp = (lastTicker?.last || 0) > (prevTicker?.last || 0);

  return (
    <>
      <BookHeader>
        <span>Price</span>
        <span>Amount</span>
      </BookHeader>
      <BookSide>
        <BookRow>
          <BookRowPrice>39843.33</BookRowPrice>
          <BookRowAmount>0.23</BookRowAmount>
        </BookRow>
      </BookSide>
      <CurrentPriceWrapper>
        <NowPrice>
          {summary.byBaseQuote(ctx.base, ctx.quote)?.last || 0}
        </NowPrice>{" "}
        <Change>
          {" "}
          {isTrendingUp ? "+" : ""}
          {(summary.byBaseQuote(ctx.base, ctx.quote)?.change || 0).toFixed(2)}%
        </Change>
        {isTrendingUp ? (
          <IoIosArrowUp size={25} />
        ) : (
          <IoIosArrowDown size={25} />
        )}
      </CurrentPriceWrapper>
      <BookSide>
        <BookRow>
          <BookRowPrice>39843.33</BookRowPrice>
          <BookRowAmount>0.23</BookRowAmount>
        </BookRow>
      </BookSide>
    </>
  );
};

export default Book;
