import { Coinsamba } from "../services/Coinsamba";

export function getCookieDate(days = 30) {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
}

function set(key: string, value: any, days: number) {
  try {
    let expires = "";
    if (days) {
      expires = "; expires=" + getCookieDate(days);
    }
    document.cookie = `${key}=${JSON.stringify(
      value
    )};expires=${expires};domain=${
      Coinsamba.isDev ? "localhost" : ".coinsamba.com"
    };path=/`;
  } catch (error) {
    return null;
  }
}

function get(key: string) {
  try {
    const nameEQ = key + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) {
        return JSON.parse(decodeURI(c.replace(nameEQ, "")));
      }
    }
  } catch (error) {
    return null;
  }
}

function unset(key: string) {
  set(key, null, -30);
}

const jsCookie = {
  get,
  set,
  unset,
};

export default jsCookie;
