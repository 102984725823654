import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
 & :root {
    --bs-primary: ${(props) => props.theme.primary};
}

  body {
    /* background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text}; */
    font-family: dabb, PingFang SC, Open Sans, Hiragino Sans GB, Heiti SC, Microsoft YaHei, WenQuanYi Micro Hei, monospace, serif;
    transition: all 0.50s linear;
  }

  & a {
    color: ${(props) => props.theme.primary};
  }
`;
