export enum NETWORKS_ENV {
  MAINNET = "mainnet",
  TESTNET = "testnet",
}

export enum TRANSFER_IDENTIFICATION {
  ADDRESS = "address",
  LABEL = "label",
  CONTRACT = "contract",
}

export enum ASSET_TYPE {
  CRYPTO = "cryptocurrency",
  TOKEN = "token",
  NFT = "nft",
  STABLECOIN = "stablecoin",
  SYNTHETIC = "synthetic",
}

export enum ASSET_NETWORK {
  ETH = "eth",
  TRX = "trx",
  BSC = "bsc",
  BTC = "btc",
  XRP = "xrp",
  XLM = "xlm",
}

export const NETWORK_TRANSFER_IDENTIFICATION = {
  [ASSET_NETWORK.ETH]: TRANSFER_IDENTIFICATION.CONTRACT,
  [ASSET_NETWORK.TRX]: TRANSFER_IDENTIFICATION.CONTRACT,
  [ASSET_NETWORK.BSC]: TRANSFER_IDENTIFICATION.CONTRACT,
  [ASSET_NETWORK.BTC]: TRANSFER_IDENTIFICATION.ADDRESS,
  [ASSET_NETWORK.XRP]: TRANSFER_IDENTIFICATION.LABEL,
  [ASSET_NETWORK.XLM]: TRANSFER_IDENTIFICATION.LABEL,
};

export const NETWORKS_CONFIRMATIONS = {
  [ASSET_NETWORK.ETH]: 12,
  [ASSET_NETWORK.TRX]: 12,
  [ASSET_NETWORK.BSC]: 12,
  [ASSET_NETWORK.BTC]: 3,
  [ASSET_NETWORK.XRP]: 3,
  [ASSET_NETWORK.XLM]: 6,
};
