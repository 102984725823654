import { useQuery } from "@apollo/client";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Column } from "react-table";
import { Card } from "reactstrap";
import CustomTable from "../../components/Common/CustomTable";
import { GET_LEDGER } from "../../graphql/account";

const Ledger: React.FC = () => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const { loading, data, refetch } = useQuery(GET_LEDGER, {
    variables: {
      offset: 0,
      limit: 10,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch({
      offset,
      limit,
    });
  }, [offset, limit, refetch]);

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Account",
          accessor: "account",
        },
        {
          Header: "Credit",
          accessor: "credit",
        },
        {
          Header: "Debit",
          accessor: "debit",
        },
        {
          Header: "Action",
          accessor: "action",
        },
        {
          Header: "Date",
          accessor: "createdAt",
          Cell: (props) => (
            <span>{format(new Date(props.value), "dd/MM/yyyy HH:mm:ss")}</span>
          ),
        },
      ] as Column[],
    []
  );

  return (
    <Card body className="border-0">
      <h4>Ledger</h4>
      <hr />
      <CustomTable
        columns={columns}
        data={data?.getLedger.entries || []}
        loading={loading}
        pageCount={data?.getLedger.totalPages}
        setOffset={setOffset}
        setLimit={setLimit}
      />
    </Card>
  );
};

export default Ledger;
