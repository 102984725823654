import gql from "graphql-tag";

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!, $code: String) {
    login(email: $email, password: $password, code: $code) {
      user {
        _id
        email
        name
        roles
        twoFactor
        telegram
      }
      token
      refreshToken
      sessionId
    }
  }
`;

export const LOSTPASSWORD_MUTATION = gql`
mutation lostPasswordMutation($email: String!) {
  lostPassword(email: $email)
}`;

export const RESETPASSWORD_MUTATION = gql`
mutation resetPasswordMutation($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password)
}`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshTokenMutation($token: String!, $refreshToken: String!) {
    refreshToken(token: $token, refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation LogoutMutation($sessionId: String!) {
    logout(sessionId: $sessionId)
  }
`;

export const REGISTER_MUTATION = gql`
  mutation RegisterMutation(
    $name: String!
    $email: String!
    $password: String!
  ) {
    register(name: $name, email: $email, password: $password)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation RegisterMutation(
    $currentPassword: String!
    $newPassword: String!
    $code: String!
  ) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
      code: $code
    )
  }
`;

export const REFETCH_USER_QUERY = gql`
  query {
    me {
      _id
      email
      name
      roles
      twoFactor
      telegram
      createdAt
    }
  }
`;
