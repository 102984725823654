import jsCookie from "../utils/jscookie";

const PLATFORM_NAME = "coinsamba";

class Storage {
  help(prefix: string, variable: string, value: any = null) {
    const key = `@${PLATFORM_NAME}/${prefix}-${variable}`;
    if (value !== null) this.set(key, value);
    return this.get(key);
  }

  set(key: string, value: any) {
    jsCookie.set(key, value, 30);
  }

  get(key: string) {
    try {
      return jsCookie.get(key);
    } catch (error) {
      return null;
    }
  }

  unset(key: string) {
    try {
      return jsCookie.unset(key);
    } catch (error) {
      return null;
    }
  }
}

const instance = new Storage();
export { instance as Storage };
