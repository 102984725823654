import React from "react";
import { Card } from "reactstrap";
import DisableTelegram from "../../components/Account/DisableTelegram";
import EnableTelegram from "../../components/Account/EnableTelegram";
import { useAuth } from "../../hooks/useAuth";

export interface ITwoFactor {
  secret: string;
  uri: string;
  qr: string;
}

const Telegram: React.FC = () => {
  const user = useAuth();

  return (
    <Card body className="text-center border-0">
      {user.user.telegram ? <DisableTelegram /> : <EnableTelegram />}
    </Card>
  );
};

export default Telegram;
