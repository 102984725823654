import BigNumber from "bignumber.js";

BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  FORMAT: {
    groupSeparator: "",
    decimalSeparator: ".",
  },
});

const BN = (number: number | string): BigNumber => new BigNumber(number);

export { BN };
