import React from "react";
import { Card } from "reactstrap";
import Template from "../../components/Common/Template";
import { useApp } from "../../hooks/appHooks";

const Terms: React.FC = () => {
  const ctx = useApp();
  return (
    <Template nonfluid>
      <Card body className="border-0 mt-3">
        <article>
          <p>
            <strong>TERMS OF USE (updated 1.03.2021):</strong>
          </p>

          <p>
            <span>
              Thank you for visiting {ctx.branding.name.toUpperCase()}.com (“
              {ctx.branding.name.toUpperCase()}” or “We”). By visiting,
              accessing, or using {ctx.branding.name.toUpperCase()}.com and
              associated application program interface or mobile applications
              (“Site”), You (“User” or “You”) consent to the Terms of Service
              (the “Terms”), so please read them carefully.
            </span>
            <br />
            <br />
            <span>
              The Terms constitute the user agreement and understanding
              regarding the use of any or all of the Services, and any manner of
              accessing them, between: You and one of the following service
              providers (“We,” “Us” or “Our”), depending on your location,
              nationality, services used, and your level of KYC completed.
            </span>
            <br />
            <span>
              This User Agreement (hereinafter Agreement) defines the conditions
              for the usage of sections of the {ctx.branding.name.toUpperCase()}
              .com website (the "Site") and access to the trading platform (the
              "Platform").
            </span>
            <br />
            <span>
              Please read these Terms carefully before using{" "}
              {ctx.branding.name.toUpperCase()} services because they affect
              your legal rights and obligations. By using the service, you agree
              to become bound by the terms and conditions of this agreement.
            </span>
            <br />
            <br />
            <span>
              <strong>1. YOUR ACCEPTANCE OF TERMS</strong>
            </span>
          </p>

          <p>
            <span>
              If you do not agree with any of these Terms please do not use
              {ctx.branding.name.toUpperCase()} exchange, as by using our
              services you will be deemed to have irrevocably agreed to these
              Terms. Please note that these Terms may be revised and reissued by
              without notice at any time. You should visit this page regularly
              to review the current Terms of Use, since your continued use of
              the services will be deemed as irrevocable acceptance of any
              revisions of the Terms.
            </span>
          </p>

          <p>
            <span>
              We earnestly ask you to read these Terms and do not use the Site
              or the Platform in case you do not accept them.
            </span>
          </p>

          <p>
            <span>
              The platform managed by {ctx.branding.name.toUpperCase()} (the
              “Service”), allows Buyers (“Buyer”) and Sellers (“Seller”),
              generally referred to as “Customer”, to buy and sell digital
              assets, called “Cryptocurrency”, on-line.
            </span>
          </p>

          <p>
            <span>
              1.1. By registering and opening an account (“Account”) on
              {ctx.branding.name.toUpperCase()} exchange, You acknowledge and
              guarantee that:
            </span>
          </p>

          <p>
            <span>
              - You have reached the required legal age set by the legislation
              of the country where You are either a legal citizen or a resident
              and that You are fully capable of making independent sole
              decisions and that You also have all the necessary rights to use
              {ctx.branding.name.toUpperCase()} exchange services, according to
              the jurisdiction of the country of which You are a legal citizen
              or a resident.
              <br />
              <span>
                - You have not previously been suspended or removed from using
                our Services or other similar services provided by other
                parties.
                <br />
                - You&nbsp;have full power and authority to enter into these
                Terms and in doing so will not violate any other agreement to
                which you are a party.
                <br />
                - You are using our platform in your own name and solely for
                your own benefit.
                <br />- You&nbsp;will not use our Services if any applicable
                laws in your country prohibit you from doing so in accordance
                with these Terms.
              </span>
              <br />- You have read all documents posted on the website,
              including the Customer Agreement, the Risk Warning, the Privacy
              policy and Cookie policy, as well as all other documents, and that
              You completely agree with them and do not have any concerns or
              complaints and not planning to take any legal actions against
              {ctx.branding.name.toUpperCase()} exchange, its employees,
              partners or contractors.
            </span>
            <br />
            <span>
              - You understand that all documents posted at the website are
              available in English language only. You acknowledge that You know
              the English language at a sufficient level to fully understand the
              text of these documents, and that You fully understand the legal
              consequences of these documents.
              <br />
              In case You do not understand completely or understand the English
              language poorly, You acknowledge that You used the services of a
              professional interpreter.
              <br />
              If You do not agree with any of the above statements or documents
              posted on the website You will immediately stop using the services
              and leave this website. Continuation of Users use of Coinsamba.com
              website confirms Your agreement with the above statements and all
              documents posted on the website.
              <br />- You confirm that You are not a citizen or a legal resident
              of the following countries: United States of America [including
              all U.S.A. territories like Puerto Rico, American Samoa, Guam,
              Northern Mariana Island, and the US Virgin Islands (St. Croix, St.
              John and St. Thomas), Democratic People’s Republic of Korea (North
              Korea), Sudan, Syria;&nbsp;that You are not a current or a former
              member of the Government of Venezuela; that You&nbsp;
              <span>
                are not a designated person under regulations issued pursuant to
                the lists of individuals or entities identified by the Security
                Council of the United Nations or the Committee as individuals or
                entities to whom or which apply the measures referred to in
                paragraph 8(d) of Resolution 1718 (2006) (including any such
                list as updated from time to time by the Security Council or the
                Committee, and made available on the Internet through the
                official United Nations website at http://www.un.org/).
              </span>
            </span>
          </p>

          <p>
            <span>
              1.2. {ctx.branding.name.toUpperCase()} reserves the right to
              revise and reissue without notice parts of these Terms at any
              time.
              <br />
              <br />
              <span>
                {ctx.branding.name.toUpperCase()} may not make the Services
                available in all markets and jurisdictions, and may restrict or
                prohibit use of the Services from certain jurisdictions
                (“Restricted Locations”).
              </span>
            </span>
          </p>

          <p>
            <span>
              You should visit this page regularly to review this User Agreement
              in order to be aware of its changes. If, after incorporated
              changes, you continue to use the Services, it means that You
              completely accept and agree to the changes. And thus, accept the
              User Agreement as amended. As long as You agree to the terms of
              the User Agreement, the Service will grant You a personal and
              non-transferable limited right to enter and use the Site and the
              Platform.
            </span>
          </p>

          <p>
            <span>
              By agreeing to these Terms and possible changes, You empower the
              Service to act as an intermediary between Sellers and Buyers to
              trade on the platform in accordance with the following paragraphs
              and to perform the functions described below.
            </span>
            <br />
            <br />
            <span>
              <strong>2. DEFINITIONS</strong>
            </span>
          </p>

          <p>
            <span>
              Platform - is a technical, functional and organizational
              structure, managed by the Service so that Sellers and Buyers can
              enter into Cryptocurrency trading transactions.
              <br />
              Cryptocurrency - is a type of digital currency, the creation, and
              control of which is based on cryptographic methods.
              <br />
              Algorithms such as Proof-of-work and / or Proof-of-stake are used.
              The functioning of such systems is decentralized in distributed
              computer networks.
              <br />
              Seller - the person placing the offer to sell Cryptocurrency on
              the platform.
              <br />
              Buyer - the person placing the offer to purchase Cryptocurrency on
              the platform.
              <br />
              The Customer and/or a Client and/or a User - is a Buyer or Seller,
              or any other account holder.
              <br />
              Transaction - a bilateral agreement between the Buyer and the
              Seller for the exchange of Cryptocurrency using the Platform.
              <br />
              Price - is the cost in nominal dollars for which the Client is
              ready to buy and sell Cryptocurrency using the Platform.
              <br />
              Commission - the amount that is charged to the user account for
              the operations of the Service.
            </span>
            <br />
            <br />
            <span>
              <strong>3. CUSTOMER ACCOUNT</strong>
            </span>
          </p>

          <p>
            <span>
              3.1. The Client is personally liable for the safety of account
              information from third parties, including the password and PIN
              code, for all actions and operations carried out with his account.
              The Client shall immediately notify the Service about any cases of
              suspicion or unauthorized use of his account or password. Using
              the recommendations of the Service will ensure maximum security of
              the information provided to the Service.
              <br />
              3.2. The Client has no right to use any other account except his
              own. The Client has no right to attempt to illegally gain access
              to the Site, and any attempt to do this or assistance to third
              parties in doing this will lead to termination of customer service
              and loss of all funds in his account. In such situation, the
              service reserves the right to take any other actions towards the
              Client.
              <br />
              In addition, the Client shall personally abide all legal
              requirements related to anti-money laundering and the financing of
              acts of terrorism. When registering an account, the Client agrees
              to provide the Service with true and up-to-date information about
              himself, which is requested by the Site when registering an
              account and constantly update such information.
              <br />
              3.3. The Client can have only one account and cannot use or create
              any account under the other name except of his own. When multiple
              accounts of one and the same Client are detected, such accounts
              are blocked by the Service without warning.
              <br />
              If the above rules are violated, the Service reserves the right to
              refuse the provision of services to the Client without paying
              balances of accounts.
              <br />
              3.4. Complaints related to any financial transactions are accepted
              within 30 calendar days from the date of the financial
              transaction. After this period, no complaints will be accepted.
              <br />
              {ctx.branding.name.toUpperCase()}.com provides information about
              transactions only for the last 30 days. Older information is
              automatically deleted to reduce server load.
              <br />
              3.5. The Client agrees that in order to ensure the security of
              Client accounts and the monitoring of fraudulent activity on the
              platform, the Service has the right, by default, to use the tools
              of internal control and programs for its implementation, to
              collect and store any information regarding Clients and their
              transactions, including personal information about the Clients
              provided by them during the registration of the account, or
              requested by the Service during the execution of transactions, and
              indirect information, including IP addresses, information about
              the operating system being in use, software configuration, and
              other information gathered by the use of «cookies» technology.
              <br />
              3.6. The Service reserves the right to demand identification
              information (pass the KYC identification) from the Client,
              depending on the amounts placed on the accounts or when detecting
              suspicious activity, which may signify illegal actions.
              <br />
              3.7. The Client understands and agrees that if it is reasonable
              for the Service to suppose that money laundering or any other
              illegal activity is carried out through the Client’s account, the
              Service has the right to suspend your account and also require the
              Client to provide additional information and necessary documents
              for fact-finding.
              <br />
              The Client's account, including all his transactions, may be
              frozen until the Service considers the documents and accepts them
              as satisfying the requirements of the legislation on countering
              fraud and terrorist financing.
              <br />
              3.8. The Service has the right to request the Client to provide
              any additional information and the necessary documents, at the
              request of any competent authority or on the basis of any
              applicable legislation or regulation of any country, including
              legislation about controlling fraud and terrorist financing. If
              the Client refuses to provide the requested information, provides
              incomplete or incorrect information and documents, the Service has
              the right to unilaterally refuse the Client to provide services
              and maintain the account.
              <br />
              3.9. Accounts may be used exclusively for the purposes specified
              in these Terms. Accounts that have been inactive over a year ago
              since the last visit may be blocked. To activate a blocked
              account, the Client needs to contact support. An account is
              considered to be inactive if its owner logged into his account
              more than a year ago. Accounts that remain inactive for 2 years
              are subject to deletion.
            </span>
            <br />
            <br />
            <span>
              <strong>
                4. PLATFORM FOR PURCHASE AND SALE, DEPOSIT AND WITHDRAWAL
              </strong>
            </span>
          </p>

          <p>
            <span>
              4.1. The Buyer may place Cryptocurrency purchase offers on the
              Platform, and the Seller may place Cryptocurrency sale offers.
              Cryptocurrency purchase and sale prices are set by the Client at
              its discretion.
              <br />
              4.2. The Client understands that the placed orders for the
              purchase or sale of Cryptocurrencies are considered to be ready
              for making a transaction with other Clients. As soon as the Seller
              and the Buyer agree to make a transaction, it is considered
              mandatory and cannot be withdrawn. After the automatic transaction
              confirmation, the Client loses the right to cancel the order about
              the Cryptocurrency purchase and sale transaction.
              <br />
              4.3. When conducting a transaction between the Buyer and the
              Seller, the Service has the exclusive right to conduct a
              transaction in the amount corresponding to the price specified in
              the transaction, less applicable fees.
              <br />
              4.4. Replenishment of funds and withdrawals of funds performed
              with violation of the rules specified in the forms for depositing
              and withdrawing funds, including incorrect details, are considered
              lost and are not refundable.
            </span>
            <br />
            <br />
            <span>
              <strong>5. SERVICE RESPONSIBILITIES</strong>
            </span>
          </p>

          <p>
            <span>
              5.1. The Client agrees that, when conducting transactions, he
              trades with other Clients, and also acknowledges that the Service
              acts only as an intermediary in such transactions, and not as a
              counterparty.
              <br />
              Thus, the Client undertakes to act in accordance with all laws and
              regulations relating to transactions.
            </span>
          </p>

          <p>
            <span>
              For its part, the Service declares and guarantees that:
              <br />
              - All possible measures will be used to facilitate and ensure the
              conduct of transactions between Clients.
              <br />
              - All purchase and sales transactions made on the Platform will be
              conducted anonymously.
              <br />
              - The transaction price will be calculated on the basis of the
              actual relevant orders made by the Clients together with the
              current commissions.
              <br />
              - From the moment when certain orders for the purchase and sale of
              Cryptocurrencies coincided, these orders cannot be canceled.
              <br />
              - All funds and all Cryptocurrencies deposited by the Client to
              his account will be stored by the Service on his behalf, according
              to the data specified in the profile.
              <br />
              - All laws and regulations regarding the proposed Platform will be
              respected.
              <br />
              If the Client violates one or more of the above conditions, the
              Service has the right to deactivate his account and freeze all
              funds, and Cryptocurrency stored in it.
            </span>
          </p>

          <p>
            <span>
              5.2. The platform is not intended to provide legal, tax, insurance
              or investment services. The history of orders and tenders is
              provided solely to ensure that the Users are aware of the
              purchases and sales made, and should not be considered as an
              investment recommendation from the Service. The client is
              personally responsible for the decisions about making a
              transaction.
            </span>
            <br />
            <br />
            <span>
              <strong>6. USER RESPONSIBILITIES</strong>
            </span>
          </p>

          <p>
            <span>
              6.1. When using the Platform, the Client is obliged to perform
              operations subject to the conditions prescribed in these Rules.
              <br />
              6.2. Client shall not register multiple accounts for any purpose.
              <br />
              6.3. Client may not use another User’s accounts.
              <br />
              6.4. Client must not use this website or Services in any way that
              causes, or may cause, damage to this site or impairment of the
              availability or accessibility of this website; or in connection
              with any illegal fund payment and settlement including but not
              limited to payment by illegal means like fictitious transaction,
              false price, and transaction refund and so on; illegal cash-out or
              fund transfer activities or any unlawful, illegal, fraudulent or
              harmful purpose or activity under applicable laws and regulations.
              Users agree to indemnify and hold{" "}
              {ctx.branding.name.toUpperCase()} exchange and their officers,
              directors, shareholders, predecessors, successors in interest,
              employees, agents, subsidiaries and affiliates, harmless from any
              demands, loss, liability, claims or expenses (including attorneys’
              fees), made against {ctx.branding.name.toUpperCase()} exchange by
              any third party due to or arising out of or in connection with
              Users’ use of this website or Services.
              <br />
              6.5. The Seller guarantees that the Cryptocurrency offered for
              sale corresponds to the actual Cryptocurrency types and are from
              legitimate sources.
              <br />
              6.6. The Buyer guarantees that the funds for the purchase
              corresponds to his actual assets and are from legitimate sources.
              <br />
              6.7. The Client agrees not to use the Platform for money
              laundering, financing of terrorism or carrying out any illegal
              activity that could harm the operation of the Platform.
              <br />
              6.8. Without the authorization or permission of{" "}
              {ctx.branding.name.toUpperCase()}
              exchange, the User shall not use any of the commercial activities
              in the name of this Site or in any form of the Site as a place,
              platform or medium for engaging in commercial activities.
              <br />
              6.9. The Client undertakes to independently monitor the news of
              the {ctx.branding.name.toUpperCase()} platform in order to be
              timely informed about all actions performed by the Service on the
              Platform.
              <br />
              6.10. The Client undertakes to notify the support service, stating
              the reason, if necessary, to have more than several thousand
              simultaneously open orders.
              <br />
              6.11. The Client permits the Service to delete opened orders that
              are completely out of the market (10-fold or more distance from
              the market price).
              <br />
              6.12. The Client confirms that when making a transaction, the
              Platform sends and receives funds, Cryptocurrency on behalf of the
              Buyer and on behalf of the Seller, through the IT systems used on
              the Platform at the time of the transaction.
              <br />
              6.13. The Platform is intended to trading and / or exchange of
              cryptocurrency. Direct transit of cryptocurrency or other funds
              outside the platform is prohibited. In case of violation of this
              rule, the accounts of the Buyer and the Seller will be deactivated
              without payment of funds.
              <br />
              6.14. The Client guarantees non-disclosure of information obtained
              during communication with the Support Service. In case of
              violation of this rule, the account of the Client will be
              deactivated without payment of funds.
              <br />
              6.15. The Client undertakes to regularly review the news of the
              Service, published on the official accounts of the Service in
              social networks. The Client agrees that he is solely responsible
              for the loss of funds resulting from the failure to comply with
              the recommendations published on the Site.
              <br />
              6.16. The Client understands that it is possible to make any
              complaints to the {ctx.branding.name.toUpperCase()} service in no
              other way than by filling the Support Service form. The{" "}
              {ctx.branding.name.toUpperCase()} service will not respond and
              will not consider the issues and complaints of the Clients
              published on the forums and on social networks.
            </span>
            <br />
            <br />
            <span>
              <strong>7. FEES</strong>
            </span>
          </p>

          <p>
            <span>
              7.1. Fees may be incurred for part of the Services provided by us
              to the Users. By using our Services, you agree to incur these
              fees.
              <br />
              7.2. We have the right to set and modify our fee structure and at
              our own discretion. {ctx.branding.name.toUpperCase()} may also
              start charging fees on free services. Any fee changes shall be
              announced in advance.
              <br />
              7.3 If you do not agree with any fees charged, You should stop
              using the Service immediately. You are still responsible for any
              fees previously incurred.
              <br />
              7.4 Unless otherwise stated or agreed, the User agrees that We
              have the right to automatically and without prior notice deduct
              the above-mentioned service fees directly from the assets of the
              User account.
              <br />
              7.5 If You do not pay in full and on time, we reserves the right
              to interrupt, suspend or terminate the service.
            </span>
            <br />
            <br />
            <span>
              <strong>8. INTELLECTUAL PROPERTY</strong>
            </span>
          </p>

          <p>
            <span>
              8.1. The Service has all rights of the intellectual property to
              all materials posted on the platform. The Client is not entitled
              to copy, distribute, reproduce, republish, upload, transmit,
              modify, publish or otherwise use the materials posted on the
              platform.
            </span>
            <br />
            <br />
            <span>
              <strong>9. LIABILITIES</strong>
            </span>
          </p>

          <p>
            <span>
              9.1. The Client confirms his rights of the owner to use the fund
              amounts and cryptocurrencies in his account, and that the
              conducted operations do not violate the rights of any third
              parties or current legislation. The Client undertakes to pay
              restitution to the Service for all losses incurred by the Service
              because of violating the rights of third parties or for violating
              current legislation due to the fault of the Client.
              <br />
              9.2. The Service shall not be liable for any direct or indirect
              damages that occurred during the use of the site if the damage
              caused is not caused by a violation of these Terms by the Service.
              <br />
              9.3. The Service shall not be liable for malfunctions, breakdowns,
              delays or lack of access to the Internet, or if for any other
              reason the Site is not available for usage at any time or for any
              period.
              <br />
              9.4. The Site may contain links to third-party sites as
              information resources provided by third parties. The Service shall
              not be liable for the content and activities of such sites and for
              securing your confidentiality, therefore, strongly recommends you
              to read the privacy policies of each visited site.
              <br />
              9.5. This Privacy Policy applies only to the{" "}
              {ctx.branding.name.toUpperCase()} website.
              {ctx.branding.name.toUpperCase()} does not control and is not
              responsible for sites located on domains other than https://
              {ctx.branding.id}.com
              <br />
              9.6. In the case of committing illegal activities by third-party
              sites links to which the Site contains or the actions of the
              Clients, the Service will transfer to the relevant national
              authorities all the necessary information, including names,
              addresses, and other requested information. The client
              acknowledges that his account may be frozen at any time upon the
              request of any competent government authority.
              <br />
              9.7. The Service is not responsible for losses incurred as a
              result of failures in software (nodes, wallets) of third parties,
              software (nodes, wallets) provided by third parties, as well as in
              blockchains and as a result of any other technical features of
              cryptocurrencies traded on the exchange .<br />
              9.8. The Service also cannot be held responsible for funds lost in
              case if the Service was notified not on time or not notified at
              all by the developers or representatives of the cryptocurrency
              about any problems with this cryptocurrency, including all kinds
              of forks, technical problems with the node or any other problems
              potentially leading to losses. In this case, the Client may
              recover the lost funds in two possible ways.
              <br />
              9.9. The priority option of reimbursement is full cost recovery by
              the developers or representatives of the particular
              cryptocurrency. The secondary option of reimbursement is the
              debiting of funds from the balance sheets of Clients in accordance
              with the sum of their deposits in this particular cryptocurrency
              on the Service.
              <br />
              9.10. These Terms do not affect the legal rights of users in any
              way.
              <br />
              9.11. These Terms do not in any way exclude or limit the liability
              of any party for fraud, death or bodily harm caused by negligence,
              violation of laws, or any other activities that cannot be limited
              or excluded legally.
              <br />
              9.12. Subject to the foregoing, the aggregate liability of the
              Service, which may arise as a result of using the Site and/or the
              Platform by the Client or is connected with this, will in no
              circumstances exceed the total amount on the account of the Client
              that makes a claim minus the amount of commission, if it might be,
              or 100% of the amount of the transaction (s), which are the
              subject of a claim minus the amount of the commission.
            </span>
            <br />
            <br />
            <span>
              <strong>10. TERMINATION</strong>
            </span>
          </p>

          <p>
            <span>
              10.1. The Service has the right at any time to unilaterally
              terminate the Platform as a whole or part of its functions without
              prior notice to Clients. Upon further use of the Platform, it is
              considered that the Client has accepted these changes. The client
              can make any financial claims within 10 calendar days from the
              date the changes in this agreement enter into force.
            </span>
          </p>

          <p>
            <span>
              10.2. If the Client has a complaint or does not agree with these
              Terms, he can close his account at any time.
            </span>
          </p>

          <p>
            <span>
              10.3. The Client also agrees that the Service may restrict the
              Client’s access to the Site, including deleting accounts, denying
              access to the Site and its contents, as well as ceasing any
              services. The Service has the right to take any technical and
              legal measures to prohibit the Client from using the Site if the
              Service has reason to believe that the Client creates situations
              of judicial responsibility or violates the intellectual property
              rights of third parties, the rights of other clients or the terms
              of this agreement.
            </span>
          </p>

          <p>
            <span>
              10.4. The Service has the right to suspend or terminate the
              maintaining of the Client’s account for any reason, including but
              not limited to:
            </span>
          </p>

          <p>
            <span>
              (1) an attempt to access the Site or the account of another
              member,
              <br />
              (2) bypassing security software aimed at limiting the use or
              protecting any information on the Site,
              <br />
              (3) using the Platform to carry out any unauthorized activity,
              including legalization of illegally obtained income, as well as
              financing of terrorism or other criminal activities,
              <br />
              (4) violation of these Terms,
              <br />
              (5) unforeseen difficulties in operating systems,
              <br />
              (6) law enforcement or other governmental bodies requests
              <br />
              (7) high activity (more than 100 transactions) during the day,
              exerting influence on the market, in order to artificially pump
              the trading volumes,
              <br />
              (8) public and private insults towards employees and/or the
              Exchange,
              <br />
              (9) threats to support team
              <br />
              10.5. The Client agrees that the Service, as a private owner, has
              the exclusive right to suspend or replace the action of the Site
              or the Platform, as well as to stop providing services to the
              Client without providing specific information about the reason for
              it, and will not be liable to him or any third party for the
              termination of the account service or the inaccessibility of the
              Site
              <br />
              10.6. The Client understands and agrees that his accounts can be
              frozen until provides the documents confirming his identity to the
              Service, if the Service considers it necessary for the further
              maintenance of the Client’s account, as well as the execution of
              operations and/or work of the Platform.
            </span>
            <br />
            <br />
            <span>
              <strong>11. COMPLIANCE WITH YOUR LOCAL LAWS</strong>
            </span>
          </p>

          <p>
            <span>
              11.1. It is your sole responsibility to determine whether, and to
              what extent, any taxes apply to any transactions You conduct
              through the Services, and to withhold, collect, report and remit
              the correct amounts of taxes to the appropriate tax authorities.
              <br />
              11.2. Additionally, You agree to comply with all relevant laws of
              jurisdiction of your legal citizenship or residence as applicable.
              Regarding the prevention of terrorist financing and anti-money
              laundering (AML), we will work with local authorities. When using
              our Services, You acknowledge that your actions are in a legal and
              proper manner and your sources of VFAs are not from illegal
              activities. We may discretionarily or in coordination with local
              law enforcement authorities seize, restrict or close-out your
              account, fiat currency and VFAs.
            </span>
            <br />
            <br />
            <span>
              <strong>12. PRIVACY POLICY</strong>
            </span>
          </p>

          <p>
            <span>
              12.1. Please refer to our Privacy Policy for information about how
              we collect, use, and share your information.
            </span>
            <br />
            <br />
            <span>
              <strong>13. DISCLAIMER</strong>
            </span>
          </p>

          <p>
            <span>
              13.1 Network Service Provider: {ctx.branding.name.toUpperCase()},
              as a third party platform for "network service provider", does not
              guarantee the information and services provided in this &nbsp;Site
              can fully meet the needs of users. We shall not be liable for
              errors, insults, defamation, nonfeasance, obscenity, pornography,
              or blasphemy that may occur during the process of acceptance of
              the Parties’ web service.
              <br />
              13.2 Service Interruption: Based on the special nature of the
              Internet, we do not guarantee that the service will not be
              interrupted; the timeliness and security of the service are also
              not guaranteed, and we do not bear the responsibility which is not
              caused by {ctx.branding.name.toUpperCase()}.
              <br />
              13.3 Safety of the Network: We try to provide a safety network
              environment to the users; however, we do not guarantee that the
              Site or its servers are free of viruses or other potentially
              harmful factors; therefore, the user should use the industry’s
              recognized software to check and kill any virus in the files
              downloaded from the Site.
              <br />
              13.4 User Information: we are not responsible for the failure of
              preservation, modification, deletion or storage of the information
              provided by the user. Nor will we be liable for the typographical
              errors, negligence, etc. not intentionally caused by{" "}
              {ctx.branding.name.toUpperCase()}. We have the right but no
              obligation to improve or correct any omission, error of any part
              of this Site.
              <br />
              13.5 Accuracy, Completeness, and Reliability of the Site: Unless
              we have expressly agreed in writing, we shall not guarantee the
              accuracy, completeness, reliability of any content, such as, but
              not limited to, advertising from the platform in any manner
              (including but not limited to, containing, connecting, by way of,
              or downloading) from the Site; we are not responsible for any
              products, services, information or materials purchased or obtained
              by the user according to the content information on this website.
              The user bears the risk of using the content of this website.
              <br />
              13.6 User Opinion: The user comments published by users of the
              Site, are only on behalf of the users personal point of view. It
              does not mean that this Site agrees with their views or confirms
              their description. This Site does not bear any legal
              responsibility caused by any user comments.
              <br />
              13.7 Announcements: In regards to notices issued to the users, we
              will deliver these notices through a formal page announcement,
              station letter, e-mail, customer service phone call, SMS or
              regular mail delivery. We do not bear any legal responsibility for
              any winning, discount activities or information which are
              delivered by other channels.
              <br />
              13.8 Charges, Commissions, Transactions and Other Fees: we have
              the right to adjust the recharge, commission, transaction and
              other fees according to market conditions, and terminate any
              promotional efforts early.
            </span>
            <br />
            <br />
            <span>
              <strong>14. ADDITIONALLY</strong>
            </span>
          </p>

          <p>
            <span>
              14.1. In case of force majeure, in accordance with the current
              legislation, the fulfillment of any obligations to the injured
              party will be suspended until the circumstances are resolved.
              <br />
              14.2. In case if the relevant competent judicial authority
              considers any provision of the Terms and Conditions null and void,
              this provision will be enforced to the maximum extent possible,
              and all other provisions remain in full force.
            </span>
            <br />
            <br />
            <span>
              <strong>15. CONTACTS</strong>
            </span>
          </p>

          <p>
            <span>
              15.1. In case of any questions related to these Terms and/or use
              of the Site and the Platform, the Client can contact the Service
              Support.
            </span>
          </p>
        </article>
      </Card>
    </Template>
  );
};

export default Terms;
