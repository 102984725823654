import { ChangeEvent, useCallback, useEffect, useState } from "react";
import React from "react";
import { useMutation } from "@apollo/client";
import { SIGN_TOKEN_EVENT_VERIFICATION_MUTATION } from "../../graphql/tokenEvent";
import cogoToast from "cogo-toast";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { injected } from "../../utils/web3/connectors";
import { Container, Button, Row, Input } from "reactstrap";
import { useLocation } from "react-router-dom";
import InputCopy from "../../components/Common/InputCopy";
import { useApp } from "../../hooks/appHooks";

function SignVerification() {
  const app = useApp();
  const { activate, library, account } = useWeb3React<Web3>();
  const { search } = useLocation();
  const [code, setCode] = useState<string>("");

  useEffect(() => {
    const params = new URLSearchParams(search);

    const codeFromQuery = params.get("code");

    if (codeFromQuery) {
      setCode(codeFromQuery);
    }
  }, []);

  const [sign] = useMutation(SIGN_TOKEN_EVENT_VERIFICATION_MUTATION, {
    onCompleted: () => {
      setCode("");
      cogoToast.success("signed");
    },
    onError: (err) => {
      console.error(err);
      setCode("");
      cogoToast.error(err.message);
    },
  });

  const parseEventCode = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setCode(value.toUpperCase());
  };

  useEffect(() => {
    activate(injected);
  }, []);

  const handleSign = useCallback(
    (code: string) => {
      if (code.length === 4 && library) {
        library?.eth.personal
          .sign(code, account, "")
          .then((signature) =>
            sign({
              variables: {
                code,
                signature,
                account,
              },
            })
          )
          .catch((err) => console.error(err));
      }
    },
    [library]
  );

  useEffect(() => {
    handleSign(code);
  }, [code]);

  const link = `https://${app.branding.host}/token-access/sign?code=${code}`;

  return (
    <Container fluid>
      {code && (
        <>
          <Row className="mb-3 mt-3 text-center">
            <Input
              className="m-auto"
              style={{ maxWidth: "200px", fontSize: "15pt" }}
              type="text"
              maxLength={4}
              placeholder="Insert code"
              value={code}
              onChange={parseEventCode}
            />
          </Row>
          <Row className="mb-3 mt-3 text-center">
            <div style={{ maxWidth: "200px" }} className="m-auto p-0">
              <InputCopy value={link} />
            </div>
          </Row>
        </>
      )}
      <Row className="mb-3 mt-3 text-center">
        <div className="d-grid gap-2 col-12 mx-auto">
          <Button
            color="primary"
            onClick={() => (account ? handleSign(code) : activate(injected))}
          >
            {account ? " Sign verification" : "Connect Wallet"}
          </Button>
        </div>
      </Row>
    </Container>
    // <StyledQrWrapper>
    //   <div>
    //     <p>Scan the QR Code provided by event manager</p>
    //   </div>
    //   <QrReader
    //     delay={300}
    //     onError={handleError}
    //     onScan={handleScan}
    //     style={{
    //       height: 240,
    //       width: 320,
    //     }}
    //   />
    //   <InputWrapper>
    //     <input
    //       type="text"
    //       maxLength={4}
    //       placeholder="Or insert event id"
    //       style={{ width: "100%" }}
    //       value={code}
    //       onChange={parseEventCode}
    //     />
    //   </InputWrapper>
    // </StyledQrWrapper>
  );
}

export default SignVerification;
