import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Card, Input, Table } from "reactstrap";
import { IUser } from "../../hooks/useAuth";
import { FETCH_USER } from "../../graphql/admin";
import { IBalance } from "../../typings/Balance";
import cogoToast from "cogo-toast";
import { BLOCKUSER_MUTATION, LOSTPASSWORD_MUTATION, UNLINKTELEGRAM_MUTATION } from "../../graphql/admin";

const Users: React.FC = () => {
  const [fetchUser, data] = useLazyQuery<{
    fetchUser: { user: IUser; balances: IBalance[] };
  }>(FETCH_USER, {
    onError: (e) => cogoToast.error(e.message),
  });

  const [search, setSearch] = useState("");

  const userData = data?.data?.fetchUser;

  const [blockuser] = useMutation(BLOCKUSER_MUTATION, {
    onCompleted: () => {
      cogoToast.success("User blocked with sucessful");
    },
    onError: (err) => cogoToast.error(err.message),
  });

  const [lostpassword] = useMutation(LOSTPASSWORD_MUTATION, {
    onCompleted: () => {
      cogoToast.success("Ok, A password reset email will be sent.");
    },
    onError: (err) => cogoToast.error(err.message),
  });

  const [unlinktelegram] = useMutation(UNLINKTELEGRAM_MUTATION, {
    onCompleted: () => {
      cogoToast.success("Telegram unlinked with sucessful.");
    },
    onError: (err) => cogoToast.error(err.message),
  });


  return (
    <Card body className="border-0">
      <h4>Users</h4>
      <hr />

      <Input
        type="text"
        value={search}
        className="mb-3"
        placeholder="Type userId or e-mail"
        onChange={({ target }) => setSearch(target.value)}
      />
      <Button
        color="primary"
        onClick={() => fetchUser({ variables: { search } })}
      >
        Fetch
      </Button>
      {userData && (
        <>
          <div className="user-actions" style={{ marginTop: '25px' }}>
            <p style={{ fontSize: "22px" }}>Actions</p>
            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
              <Button color="primary" onClick={() => {
                blockuser({
                  variables: {
                    id: userData.user._id
                  }
                })
              }}>Block user</Button>
              <Button color="primary" onClick={() => {
                lostpassword({
                  variables: {
                    id: userData.user._id
                  }
                })
              }}>Lost password</Button>
              <Button color="primary" onClick={() => {
                unlinktelegram({
                  variables: {
                    id: userData.user._id
                  }
                })
              }}>Remove telegram</Button>
            </div>
          </div>
          <hr />
          <Table>
            <thead>
              <tr>
                <th>Field</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(userData.user).map((d) => (
                <tr>
                  <td>{d}</td> <td>{userData.user[d]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <hr />
          <Table className="text-center" size="sm" striped responsive>
            <thead>
              <tr>
                <th>Simbolo</th>
                <th>Nome</th>
                <th>Available</th>
                <th>On Hold</th>
                <th>Pending Deposit</th>
                <th>Pending Withdraw</th>
              </tr>
            </thead>
            <tbody>
              {userData.balances.map((b) => (
                <tr>
                  <td>{b.symbol}</td>
                  <td>{b.name}</td>
                  <td>{b.available}</td>
                  <td>{b.onHold}</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Card>
  );
};

export default Users;
