import { useQuery } from "@apollo/client";
import format from "date-fns/format";
import React from "react";
import { Table } from "reactstrap";
import { GET_EVENT_PARTICIPANTS } from "../../../graphql/tokenEvent";
import { ITokenEventParticipant } from "../../../typings/TokenEvent";
import { useInfoModal } from "../../Common/InfoModal";
import ModalButton from "../../Common/ModalButton";

const TokenEventParticipantsModal: React.FC<{ eventId: string }> = ({
  eventId,
}) => {
  const { isOpen, toggle } = useInfoModal();
  const { data } = useQuery<{ getEventParticipants: [ITokenEventParticipant] }>(
    GET_EVENT_PARTICIPANTS,
    {
      variables: {
        eventId,
      },
    }
  );

  return (
    <ModalButton
      title="Show Participants"
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
    >
      <div style={{ maxHeight: 400 }}>
        <Table responsive>
          <thead>
            <tr>
              <th>Address</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {data?.getEventParticipants.map((p) => (
              <tr>
                <td>{p.account}</td>
                <td>{format(new Date(p.createdAt), "dd/MM/yyyy hh:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </ModalButton>
  );
};

export default TokenEventParticipantsModal;
