function fallbackCopyTextToClipboard(text: string): void {
  const textArea = document.createElement("textarea");
  const { scrollY } = window;

  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);

  window.scrollTo(0, scrollY);
}

async function copyTextToClipboard(text: string): Promise<void> {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  return navigator.clipboard.writeText(text);
}

export default copyTextToClipboard;
