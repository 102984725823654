import { useQuery } from "@apollo/client";
import React from "react";
import { Card } from "reactstrap";
import DisableTwoFactor from "../../components/Account/DisableTwoFactor";
import EnableTwoFactor from "../../components/Account/EnableTwoFactor";
import { ENABLE_TWOFACTOR } from "../../graphql/account";

export interface ITwoFactor {
  secret: string;
  uri: string;
  qr: string;
}

const TwoFactor: React.FC = () => {
  const { data, error, loading } = useQuery<{
    enableTwoFA: ITwoFactor;
  }>(ENABLE_TWOFACTOR, {
    fetchPolicy: "no-cache",
  });

  return (
    <Card body className="text-center border-0">
      {loading && (
        <span
          style={{
            margin: "auto",
          }}
        >
          Loading...
        </span>
      )}
      {data && <EnableTwoFactor twoFactor={data?.enableTwoFA} />}
      {error && <DisableTwoFactor />}
    </Card>
  );
};

export default TwoFactor;
