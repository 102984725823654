import React from "react";
import styled from "styled-components";
import { GrInfo } from "react-icons/gr";

const CalloutWrapper = styled.div`
  position: relative;
  background-color: #fff2e8;
  border-radius: 4px;
  padding: 12px 40px;

  svg {
    position: absolute;
    left: 16px;
    top: 13px;
    font-size: 16px;
  }
`;

const CalloutText = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #1c242c;
`;

const Callout: React.FC = ({ children }) => {
  return (
    <CalloutWrapper>
      <GrInfo />
      <CalloutText>{children}</CalloutText>
    </CalloutWrapper>
  );
};

export default Callout;
