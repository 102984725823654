import React, { useMemo } from "react";
import styled from "styled-components";
import Slick from "react-slick";
import { IoIosGlobe } from "react-icons/io";
import { SiProbot } from "react-icons/si";
import { useTheme } from "styled-components";
import { GiTrade } from "react-icons/gi";

import { useWindowSize } from "@coinsamba/react-tiny-hooks";

const Wrapper = styled.section`
  background-color: ${(props) => props.theme.bg0};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 56px 20px;
  text-align: center;
`;

const Title = styled.h1`
  text-align: center;
  font-weight: 500;
  color: #060b1e;
  font-size: 30px;
  line-height: 2rem;
`;

const SliderWrapper = styled.div`
  margin-top: 50px;
  position: relative;
`;

const SliderItem = styled.div`
  width: 387px;
`;

const SliderTitle = styled.h3`
  color: #060b1e;
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 22px;
  text-align: center;
`;

const SliderDescription = styled.p`
  font-size: 15px;
  color: #7d7f88;
  max-width: 350px;
  margin: 0 auto;
  line-height: 1.2rem;
  text-align: center;
  padding: 0 35px;
`;

const FeaturesSection: React.FC = () => {
  const theme = useTheme();

  const windowSize = useWindowSize();

  const isMobile = windowSize.width < 768;

  const features = useMemo(
    () => [
      {
        title: "Global presence",
        description: "Ability to negotiate around the world.",
        icon: IoIosGlobe,
      },
      {
        title: "Low fees",
        description:
          "We always value for keeping low fees and launching trading promotions.",
        icon: GiTrade,
      },
      {
        title: "Developer friendly API",
        description:
          "Make the most out of your trading bot with our leading API and its low latency data and execution feeds.",
        icon: SiProbot,
      },
    ],
    []
  );

  return (
    <Wrapper>
      <Container>
        <Title>
          Coinsamba is a global digital asset trading platform that bridges the
          worlds of fiat and cryptocurrencies.
        </Title>
        <SliderWrapper>
          <Slick
            infinite
            arrows={false}
            dots={true}
            slidesToScroll={isMobile ? 1 : 1}
            slidesToShow={isMobile ? 1 : 2}
            autoplay
            speed={500}
            autoplaySpeed={10000}
            cssEase="ease-out"
          >
            {features.map((m) => (
              <SliderItem key={m.title}>
                <m.icon size={40} color={theme.primary} />
                <SliderTitle>{m.title}</SliderTitle>
                <SliderDescription>{m.description}</SliderDescription>
              </SliderItem>
            ))}
          </Slick>
        </SliderWrapper>
      </Container>
    </Wrapper>
  );
};

export default FeaturesSection;
