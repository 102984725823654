import React from "react";
import { IBalance } from "../typings/Balance";
import { useQuery } from "@apollo/client";
import { GET_BALANCES } from "../graphql/account";
import { useAuth } from "../hooks/useAuth";
import { BalanceContext } from "../hooks/useBalances";

const BalanceProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const user = useAuth();

  const { data, refetch: fetchBalances } = useQuery<{
    getBalances: IBalance[];
  }>(GET_BALANCES, {
    skip: !user.user,
    pollInterval: 3000,
    fetchPolicy: "network-only",
  });

  const balances = data?.getBalances || [];

  return (
    <BalanceContext.Provider
      value={{
        balances,
        fetchBalances,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};

export default BalanceProvider;
