import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import ReactCodeInput from "react-verification-code-input";

export const useProvideTwoFactor = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [code, setCode] = useState("");

  const toggle = () => setIsOpen((oldState) => !oldState);

  return { code, setCode, isOpen, toggle };
};

const ProvideTwoFactor: React.FC<{
  onSubmit: CallableFunction;
  isOpen: boolean;
  toggle: CallableFunction;
  setCode: (code: string) => void;
  code: string;
}> = ({ onSubmit, isOpen, toggle, setCode, code }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onComplete = () => {
    setIsLoading(true);
    setTimeout(() => {
      toggle();
      setIsLoading(false);
      onSubmit();
    }, 500);
  };

  return (
    <Modal isOpen={isOpen} toggle={() => toggle()}>
      <ModalHeader toggle={() => toggle()}>Please enter your 2FA</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup className="text-center mb-3">
            <Label for="twoFactorInput">
              Enter 6-digit code from your authenticator application
            </Label>

            <ReactCodeInput
              type="number"
              fields={6}
              autoFocus
              className="code-input"
              onChange={(code) => setCode(code)}
              loading={isLoading}
              values={code.toString().split("")}
            />
          </FormGroup>
          <div className="d-grid gap-2">
            <Button
              color="primary"
              block
              onClick={() => onComplete()}
              disabled={code.length !== 6}
            >
              Confirm
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ProvideTwoFactor;
