import { useMutation } from "@apollo/client";
import { FormHandles, SubmitHandler, useField } from "@unform/core";
import cogoToast from "cogo-toast";
import React, { useEffect, useMemo, useRef } from "react";
import { InputProps } from "reactstrap";
import styled from "styled-components";
import { PLACE_ORDER_MUTATION } from "../../../graphql/trade";
import { useAuth } from "../../../hooks/useAuth";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { useState } from "react";
import useBalances from "../../../hooks/useBalances";
import { Link } from "react-router-dom";
import order_sent from "../../../audio/order_sent.wav";
import { useTrade } from "../../../hooks/useTrade";
import { ORDER_SIDE } from "../../../typings/Trade";
import { BN } from "../../../utils/bn";

interface FormData {
  price: number;
  amount: number;
}

const TradeActionPart = styled.div`
  width: 50%;
  padding: 16px 16px 0;

  input:focus {
    border-color: ${(props) => props.theme.primary};
    outline: none;
  }
`;

const TradeActionBalance = styled.div`
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TradeActionLabel = styled.div`
  // color: ${(props) => props.theme.secondary};
  font-size: 12px;
  margin-right: 8px;
`;

const TradeActionItemWrapper = styled.div`
  position: relative;
  margin-bottom: 8px;
`;

const InputAffixWrapper = styled.span`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* color: rgba(0, 0, 0, 0.65); */
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
`;

const InputPrefix = styled.span`
  position: absolute;
  top: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  line-height: 0;
  transform: translateY(-50%);

  left: 12px;

  background-color: transparent;

  // color: ${(props) => props.theme.secondary};
  height: 0;
`;

const AntInput = styled.input`
  overflow: visible;

  box-sizing: border-box;
  margin: 0;
  /* color: rgba(0, 0, 0, 0.65); */
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  width: 100%;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5;
  background-image: none;
  border: 1px solid ${(props) => props.theme.inputBorderColor};
  border-radius: 4px;
  transition: all 0.3s;

  -webkit-appearance: none;

  touch-action: manipulation;

  position: relative;
  text-align: inherit;

  min-height: 100%;

  background-color: ${(props) => props.theme.inputBackground};
  height: 34px;

  padding-right: 30px;

  padding-left: 85px;
  font-weight: 500;

  /* :focus {
    border-color: ${(props) => props.theme.primary};
  } */
`;

const InputSuffix = styled.div`
  position: absolute;
  top: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  line-height: 0;
  transform: translateY(-50%);

  right: 12px;

  background-color: transparent;

  // color: ${(props) => props.theme.secondary};
  height: 0;
`;

const TradeActionAllipsis = styled.div`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface IAntButton {
  side: "ask" | "bid";
}

const AntButton = styled.button<IAntButton>`
  background-color: ${(props) =>
    props.side === ORDER_SIDE.ASK
      ? props.theme.ask
      : (props) => props.theme.bid};
  border-color: ${(props) =>
    props.side === ORDER_SIDE.ASK
      ? props.theme.ask
      : (props) => props.theme.bid};
  height: 34px;

  margin: 0;

  overflow: visible;

  text-transform: none;

  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid transparent;

  outline: 0;

  color: #fff;

  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

  width: 100%;

  -webkit-appearance: button;
`;

const RegisteredInput: React.FC<InputProps> = ({ name, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      path: "value",
      ref: inputRef.current,
    });
  }, [fieldName, registerField]);
  return (
    <AntInput
      id={fieldName}
      ref={inputRef}
      defaultValue={defaultValue}
      {...rest}
    />
  );
};

const RangeWrapper = styled.div`
  input[type="range"]::-webkit-slider-runnable-track {
    background: ${(props) => props.theme.sliderColor};
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: ${(props) => props.theme.sliderColor};
  }
`;

const OrderForm: React.FC<{
  side: "ask" | "bid";
  base: string;
  quote: string;
}> = ({ side, base, quote }) => {
  const orderAudio = new Audio(order_sent);
  const balances = useBalances();
  const { user } = useAuth();

  const [total, setTotal] = useState(0);

  const ctx = useTrade();
  const { rules } = ctx;

  const amountStep = useMemo(
    () =>
      rules?.amountPrecision
        ? BN(1)
            .shiftedBy(rules.amountPrecision * -1)
            .toNumber()
        : 1,
    [rules]
  );

  // TODO move this to useTrade
  const [place] = useMutation(PLACE_ORDER_MUTATION, {
    onCompleted: () => {
      cogoToast.success("Order sent");
      orderAudio.play();
    },
    onError: (err) => cogoToast.error(err.message),
  });

  const formRef = useRef<FormHandles>(null);

  const handleGetTotal = () => {
    const { amount, price } = formRef.current?.getData() as {
      amount: number;
      price: number;
    };

    setTotal(amount * price);
  };

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    if (data.amount && data.price)
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          amount: Yup.number()
            .min(amountStep)
            .max(rules.maxTradableAmount || 0)
            .required(),
          price: Yup.number().required(),
          total: Yup.number().min(rules.minTotalAmount || 0),
        });

        const { price, amount } = await schema.validate(data, {
          abortEarly: false,
        });

        place({
          variables: {
            side,
            price,
            amount,
            base,
            quote,
          },
        });
      } catch (err) {
        // const validationErrors = {};

        if (err instanceof Yup.ValidationError) {
          // console.log(err);
          err.inner.forEach((error) => {
            cogoToast.error(error.message);
            // validationErrors[error.path] = error.message;
          });

          // formRef.current?.setErrors(validationErrors);
        }
      }
  };

  const placeOrder = () => {
    formRef.current?.submitForm();
    // cogoToast.success('Ordem enviada.')
  };

  useEffect(() => {
    handleGetTotal();
  }, [ctx.price]);

  const balanceAvailable = balances.bySymbol(
    side === ORDER_SIDE.ASK ? base : quote
  )?.available;

  return (
    <TradeActionPart>
      <TradeActionBalance>
        <div
          style={{
            display: "flex",
          }}
        >
          <TradeActionLabel>Available:</TradeActionLabel>
          <span>
            {balanceAvailable} {side === ORDER_SIDE.ASK ? base : quote}
          </span>
        </div>
        <Link
          to="/account/balances"
          style={{
            fontSize: "14px",
          }}
        >
          Deposit
        </Link>
      </TradeActionBalance>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <TradeActionItemWrapper>
          <InputAffixWrapper>
            <InputPrefix>Price</InputPrefix>
            <RegisteredInput
              name="price"
              type="number"
              onChange={(e) => ctx.setPrice(e.target.value)}
              required
              value={ctx.price || ""}
              step={1 / (1 * 10 ** (ctx?.rules?.pricePrecision || 1))}
              min={1 / (1 * 10 ** (ctx?.rules?.pricePrecision || 1))}
            />
            <InputSuffix>{quote}</InputSuffix>
          </InputAffixWrapper>
        </TradeActionItemWrapper>
        <TradeActionItemWrapper>
          <InputAffixWrapper>
            <InputPrefix>Amount</InputPrefix>
            <RegisteredInput
              name="amount"
              type="number"
              onChange={handleGetTotal}
              required
              step={amountStep}
              min={amountStep}
              max={amountStep}
            />
            <InputSuffix>{base}</InputSuffix>
          </InputAffixWrapper>
        </TradeActionItemWrapper>
      </Form>
      <RangeWrapper>
        <input
          type="range"
          className="form-range"
          min="0"
          max="100"
          step="0.5"
          id="customRange3"
          style={{
            marginBottom: "16px",
          }}
          disabled={side !== ORDER_SIDE.ASK}
          onChange={({ target }) =>
            formRef.current.setFieldValue(
              "amount",
              (balanceAvailable * target.valueAsNumber) / 100
            )
          }
        />
      </RangeWrapper>

      <TradeActionItemWrapper>
        <TradeActionAllipsis
          style={{
            display: "flex",
          }}
        >
          <TradeActionLabel>Total:</TradeActionLabel>
          <span
            style={{
              marginRight: "8px",
            }}
          >
            {total.toFixed(ctx.rules.pricePrecision || 8)} {quote}
          </span>
        </TradeActionAllipsis>
      </TradeActionItemWrapper>
      <AntButton side={side} onClick={placeOrder} disabled={!user}>
        <span>
          {!user ? "Login to trade" : side !== ORDER_SIDE.ASK ? "Buy" : "Sell"}{" "}
          {base}
        </span>
      </AntButton>
    </TradeActionPart>
  );
};

export default OrderForm;
