import React from "react";
import Summary from "../../components/Home/Summary";
import styled from "styled-components";

import Hero from "../../components/Home/Hero";
import Navbar from "../../components/Common/Navbar";
import { Container } from "reactstrap";
import StatisticsSection from "../../components/Home/StatisticsSection";
import FeaturesSection from "../../components/Home/FeaturesSection";
import Footer from "../../components/Common/Footer";

const LandingWrapper = styled.div`
  background-color: ${(props) => props.theme.body};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Home: React.FC = () => {
  return (
    <LandingWrapper>
      <Navbar />
      <Hero />
      <Container>
        <Summary />
      </Container>
      <StatisticsSection />
      <FeaturesSection />
      <Footer />
    </LandingWrapper>
  );
};

export default Home;
