import cogoToast from "cogo-toast";
import React from "react";
import { IoCopyOutline } from "react-icons/io5";
import styled from "styled-components";
import sendToClipboard from "../../utils/clipboard";

const StringCopyWrapper = styled.div`
  color: #151617;
  width: 100%;
  padding: 8px 12px;
  line-height: 1.6;
  background-color: #f7f8f9;
  border-radius: 2px;
  display: -moz-box;
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  -moz-box-align: center;
  align-items: center;
  margin: 4px 0;

  span {
    font-size: 12px;
    word-break: break-all;
  }
`;

const StringCopy: React.FC<{ toCopy: string }> = ({ toCopy }) => {
  const handleClick = () => {
    sendToClipboard(toCopy);
    cogoToast.success(`Copiado ${toCopy}`);
  };

  return (
    <StringCopyWrapper>
      <span>{toCopy}</span>
      <IoCopyOutline
        size={20}
        style={{
          cursor: "pointer",
        }}
        id="tooltipcopy"
        onClick={handleClick}
      />
    </StringCopyWrapper>
  );
};

export default StringCopy;
