import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import useSummary from "../../hooks/useSummary";
import { AiOutlineArrowRight } from "react-icons/ai";

export interface ITicker {
  base: string;
  quote: string;
  vol: number;
  last: number;
  change: number;
  high: number;
  low: number;
}

const SummaryBox = styled.div`
  margin-bottom: 70px;
  margin-top: 70px;
  /* position: relative; */
  /* padding-top: 10px; */
  padding-bottom: 10px;
  box-shadow: 0 31px 30px 0 rgb(111 114 175 / 8%);

  table {
    width: 100%;
  }

  table tbody tr {
    cursor: pointer;
    height: 70px;
    transition: all 0.3s;

    :hover {
      z-index: 1;
      box-shadow: 0 8px 12px 0 rgb(49 103 180 / 10%);

      transform: scale(1.06);
      border-radius: 4px;
    }
  }

  table tr th {
    height: 41px;
    background-color: ${(props) => props.theme.backgroundColor};
    color: #7e7e7e;
    text-align: left;
    font-size: 14px;
  }

  table tr th:first-child {
    padding-left: 10px;
    @media (min-width: 768px) {
      padding-left: 69px;
    }
  }

  table tr td {
    background-color: #fff;
    border-bottom: 1px solid #f5f5f5;
  }

  table tr td img {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    margin-right: 22px;
    margin-top: -10px;
  }

  table tr td .weight-txt {
    font-size: 18px;
    font-weight: 700;
    color: #333;
    margin-right: 5px;
  }

  table tr td .jc {
    font-size: 16px;
    color: #cfcfcf;
  }

  table tr td .change {
    font-size: 16px;
    display: block;
    width: 76px;
    padding: 6px 0;
    font-size: 14px;
    line-height: 20px;
    border-radius: 3px;
    text-align: center;
    background-color: #eaf6f3;

    &.up {
      color: ${(props) => props.theme.bid};
    }

    &.down {
      color: #df384e;
    }
  }

  table tr td .red {
    font-size: 16px;
    font-weight: 700;
  }

  table tr td:first-child {
    padding-left: 10px;
    @media (min-width: 768px) {
      padding-left: 69px;
    }
  }

  table tr:hover td {
    background-color: #f6faf9;
  }
`;

const ViewMoreWrapper = styled.div`
  padding-top: 12px;
  text-align: center;
  transition-duration: 0.2s;

  :hover {
    svg {
      transition: all 0.3s;
      transform: translateX(3px);
    }
  }

  a {
    text-decoration: none;
  }

  svg {
    transition-duration: 0.2s;
  }
`;

const Summary: React.FC = () => {
  const { summary } = useSummary();

  const history = useHistory();

  // copycat from hotbit
  return (
    <SummaryBox>
      <table>
        <colgroup>
          <col width="35%"></col>
          <col width="26%"></col>
          <col width="17%"></col>
        </colgroup>
        {/* <col width="22%"></col> */}
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Change</th>
          </tr>
        </thead>
        <tbody>
          {(summary.summary || []).map((t) => (
            <tr
              key={`${t.base}-${t.quote}`}
              onClick={() => history.push(`/trade/${t.base}-${t.quote}`)}
            >
              <td>
                {/* <img src="https://upload-hotbit-io.oss-ap-southeast-1.aliyuncs.com/files/asset_logos/BTC_LOGO.png" /> */}
                <span className="weight-txt">{t.base}</span>
                <span className="jc">
                  {t.base}/{t.quote}
                </span>
              </td>
              <td>
                <span className="weight-txt">{t.last}</span>
              </td>
              <td>
                <span className={`change ${t.change > 0 ? "up" : "down"}`}>
                  {t.change.toFixed(2)}%
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ViewMoreWrapper>
        <Link to="/trade/BNB-CBRL">
          View all markets <AiOutlineArrowRight />
        </Link>
      </ViewMoreWrapper>

      {/* <ListGroup className="rouded-0 border-0">
        <SummaryRow>
          <p
            style={{
              width: "55px",
            }}
          >
            Name
          </p>
          <p>Last</p>
          <p>Volume</p>
        </SummaryRow>
        <hr className="m-0" />
        {(summary.summary || []).map((t) => (
          <>
            <SummaryRow
              onClick={() => history.push(`/trade/${t.base}-${t.quote}`)}
            >
              <p>
                {t.base}/{t.quote}
              </p>
              <p>
                {t.last} {t.quote}
              </p>
              <p>
                {t.vol} {t.base}
              </p>
            </SummaryRow>
            <hr className="m-0" />
          </>
        ))}
        <p
          className="m-3 mt-3 mb-0"
          style={{
            cursor: "pointer",
          }}
          onClick={() => history.push(`/trade/BNB-CBRL`)}
        >
          See all markets
        </p>
      </ListGroup> */}
    </SummaryBox>
  );
};

export default Summary;
