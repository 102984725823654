import gql from "graphql-tag";

export const CREATE_TOKEN_EVENT_MUTATION = gql`
  mutation CreateTokenEventMutation(
    $network: String!
    $name: String!
    $when: DateTime!
    $token: String!
  ) {
    createTokenEvent(network: $network, name: $name, when: $when, token: $token)
  }
`;

export const GET_MY_TOKEN_EVENTS = gql`
  query {
    getMyEvents {
      _id
      userId
      name
      when
      network
      token
    }
  }
`;

export const GET_ALL_TOKEN_EVENTS = gql`
  query {
    getAllEvents {
      _id
      userId
      name
      when
      network
      token
    }
  }
`;

export const GENERATE_TOKEN_EVENT_VERIFICATION_MUTATION = gql`
  mutation ($eventId: String!) {
    generateTokenEventVerification(eventId: $eventId)
  }
`;

export const GET_CODE_IS_VERIFIED = gql`
  query ($code: String!) {
    codeIsVerified(code: $code)
  }
`;

export const SIGN_TOKEN_EVENT_VERIFICATION_MUTATION = gql`
  mutation SignTokenEventVerificationMutation(
    $code: String!
    $signature: String!
    $account: String!
  ) {
    signTokenEventVerification(
      code: $code
      signature: $signature
      account: $account
    )
  }
`;

export const GET_TOKEN_EVENT_DETAILS = gql`
  query ($eventId: String!) {
    getEventDetails(eventId: $eventId) {
      name
    }
  }
`;

export const GET_EVENT_PARTICIPANTS = gql`
  query ($eventId: String!) {
    getEventParticipants(eventId: $eventId) {
      _id
      eventId
      account
      createdAt
    }
  }
`;
