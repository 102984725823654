import { useMutation } from "@apollo/client";
import cogoToast from "cogo-toast";
import React, { useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
import { ADD_PAIR_MUTATION, EDIT_PAIR } from "../../graphql/admin";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import Input from "../Form/Input";
import * as Yup from "yup";
import { IEnginePair } from "../../pages/admin/Pairs";
import { addPairSchema, editPairSchema } from "../../schemas/admin";
import { AiFillSetting } from "react-icons/ai";
import CustomSelect from "../Form/CustomSelect";
import { useApp } from "../../hooks/appHooks";
import { useInfoModal } from "../Common/InfoModal";

const PairModal: React.FC<{
  refetch: CallableFunction;
  pair?: IEnginePair;
}> = ({ refetch, pair }) => {
  const formRef = useRef<FormHandles>(null);
  const app = useApp();
  const { isOpen, toggle } = useInfoModal();
  const [isEdit] = useState(!!pair);

  const [add] = useMutation(isEdit ? EDIT_PAIR : ADD_PAIR_MUTATION, {
    onCompleted: () => {
      cogoToast.success("success");
      refetch();
    },
    onError: (err) => cogoToast.error(err.message),
  });

  const handleSubmit: SubmitHandler<IEnginePair> = async (data) => {
    formRef.current?.setErrors({});

    try {
      const formData = await (isEdit ? editPairSchema : addPairSchema).validate(
        data,
        {
          abortEarly: false,
        }
      );

      add({
        variables: formData,
      });

      toggle();
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        console.log(err);
        err.inner.forEach((error) => {
          console.error(error);
          cogoToast.error(error.message);
          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      }
    }
  };

  // useEffect(() => {
  //   if (pair && isEdit && isOpen) {
  //     alert("mudando valor dos selects");
  //     formRef.current?.setFieldValue("base", {
  //       label: pair.base,
  //       value: pair.base,
  //     });
  //     formRef.current?.setFieldValue("quote", {
  //       label: pair.quote,
  //       value: pair.quote,
  //     });
  //   }
  // }, [pair, isEdit, isOpen]);

  return (
    <>
      {isEdit ? (
        <AiFillSetting onClick={() => toggle()} />
      ) : (
        <Button color="primary" block onClick={() => toggle()}>
          Add
        </Button>
      )}
      <Modal isOpen={isOpen} toggle={() => toggle()}>
        <ModalHeader toggle={() => toggle()}>
          {isEdit ? "Edit Pair" : "New Pair"}
        </ModalHeader>
        <ModalBody>
          <Form ref={formRef} onSubmit={handleSubmit} initialData={pair}>
            {isEdit && (
              <FormGroup>
                <Label for="id">Id</Label>
                <Input type="text" id="id" name="id" disabled />
              </FormGroup>
            )}
            <FormGroup>
              <Label for="base">Base</Label>
              <CustomSelect
                options={app.currencies.currencies.map((c) => ({
                  label: c.symbol,
                  value: c.symbol,
                }))}
                id="base"
                name="base"
                placeholder="Ex.: BNB"
                disabled={isEdit}
                isMulti={false}
                defaultValue={pair && { label: pair.base, value: pair.base }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="quote">Quote</Label>
              <CustomSelect
                options={app.currencies.currencies.map((c) => ({
                  label: c.symbol,
                  value: c.symbol,
                }))}
                id="quote"
                name="quote"
                placeholder="Ex.: CBRL"
                disabled={isEdit}
                isMulti={false}
                defaultValue={pair && { label: pair.quote, value: pair.quote }}
              />
            </FormGroup>

            <FormGroup>
              <Label for="maxTradableAmount">Max. Order Trad. Amount</Label>
              <Input
                type="number"
                id="maxTradableAmount"
                name="maxTradableAmount"
                placeholder="Ex.: 10000"
              />
            </FormGroup>
            <FormGroup>
              <Label for="pricePrecision">Price Precision</Label>
              <Input
                type="number"
                id="pricePrecision"
                name="pricePrecision"
                placeholder="Ex.: 1"
                step={1}
                min={0}
                max={8}
              />
            </FormGroup>
            <FormGroup>
              <Label for="amountPrecision">Amount Precision</Label>
              <Input
                type="number"
                id="amountPrecision"
                name="amountPrecision"
                placeholder="Ex.: 8"
                step={1}
                min={0}
                max={8}
              />
            </FormGroup>
            <FormGroup>
              <Label for="minTotalAmount">Min. Order Total Price</Label>
              <Input
                type="number"
                id="minTotalAmount"
                name="minTotalAmount"
                placeholder="Ex.: 10"
                step="any"
              />
            </FormGroup>
            <FormGroup>
              <Label for="fee">Fee</Label>
              <Input
                type="number"
                id="fee"
                name="fee"
                placeholder="Ex.: 0.1"
                step={0.01}
                min={0}
                max={0.5}
              />
            </FormGroup>
            <Button type="submit" color="success" block>
              Save
            </Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PairModal;
