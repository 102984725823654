export interface ISession {
  _id: string;
  ip: string;
  agent: string;
  when: Date;
  sessionId: string;
  lastRefresh: Date;
}

export interface ITelegramChat {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
}

export enum ROLES {
  USER = "user",
  ADMIN = "admin",
  MARKET_MAKER = "marketMaker",
  EVENT_MANAGER = "eventManager",
}
